import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

//director
import CaptainContenedorCrono from "../../../resources/images/Captain-Contenedor-crono.png";

import Genial from "../../../resources/images/good-hand-captain.png";
import BadgeTargetIcon from "../../../resources/images/badge-target.png";
import ZonaAprendizajeCaptain from "../../../resources/images/Zona-aprendizaje-captain.png";


import Info from "../../../resources/images/Info.png";
import LearningZone from "../../../resources/images/Sinc-AspiranteValidador.png";
import GoodHandDirector from "../../../resources/images/good-hand-captain.png";

import CalendarIcon from "../../../resources/images/calendarTwo.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

import { CardCopyKey } from "../../molecules/card-copy-key";
import CalendarDirectorIcon from "../../../resources/images/Calendario_director.png";
import CalendarCaptain from "../../../resources/images/calendar-icon.svg";

import { Quote } from "../../molecules/quote";
import "./options.scss";
import MenuUserInfo from "../../molecules/menu-user-info";
import Card from "../../atoms/card";
import TextField from "../../atoms/text-field";
import { TextFieldList } from "../../molecules/text-field-list";

const CaptainRulesDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);
  const [hostActive, setHostActive] = useState([]);
  const [infoIndex, setInfoIndex] = useState(0);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx">
                  <b>
                  ARTE Formación de líderes que forman líderes.
                  </b>
                  <br />
                  <br />
                  
        

“El arte de un Formador de Líderes que<br/>
 forman a otros líderes es entender que<br/>
los resultados vendrán de la<br/>
perseverancia y la sucesión de la visión.<br/>
Un líder de quinto nivel se puede<br/>
identificar por la magnitud de lo que va<br/>
dejando tras de sí con su caminar, así<br/>
con una visión más amplia del proceso<br/>
que has vivido en ACDC te darás cuenta<br/>
que: Los Aspirantes solo son tan buenos<br/>
como lo que aprenden. Los Anfitriones<br/>
solo son tan buenos como su buen<br/>
desempeño. Los Coordinadores solo son<br/>
tan buenos como el buen desempeño de<br/>
su equipo. Los Directores solo son<br/>
tan buenos como el buen desempeño <br/>
de sus Coordinadores. Los Capitanes,<br/>
los líderes del quinto nivel, son<br/>
 tan buenos como su perseverancia y la sucesión de la visión”.
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>
              ¡Asegura la sincronización del equipo!
              </b>
            </Paragraph>
            <img src={LearningZone} alt="async"  width="182" height="182"/>
            <Paragraph
              align="center"
              size="sx"
           
            >
              La perseverancia y la sucesión de la visión en<br/>
              ACDC se asegura teniendo el apoyo tecnológico<br/>
              de TWOBOT, ese es el pensamiento que debes<br/>
              tener en mente todos los días durante este rol<br/>
              pues el desempeño y el aprendizaje de los<br/>
              miembros de tus equipos depende de ello.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
       
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>
              Confirmación Diaria
              </b>
            </Paragraph>
            <img src={CalendarCaptain} alt="calendar"  width="182" height="182"/>
            <Paragraph
              align="center"
              size="sx"
            >
              Para empezar cada Día debes confirmar si tu<br/>
              Capitán(a) tiene activo a TWOBOT como<br/>
              respaldo en caso que tú tengas algún<br/>
              inconveniente con el internet durante el día y<br/>
              revisa constantemente que la aplicación de<br/>
              TWOBOT esté funcionando correctamente,<br/>
              especialmente en el <b>Día de Reglas</b> que es clave<br/>
              para lograr la <b>Meta 1</b> de todo el equipo<br/>
              aplicando el Plan B.
             </Paragraph>

       
     
      
          
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
            </Paragraph>
            <Paragraph size="s" align="center" style={{ maxWidth: "364px" }}>
              Te queda poco para completar el <b>Desafío A.</b>
            </Paragraph>
            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              widh="226"
              height="226"
            />
            <CardCopyKey
              phrase="Pensamientos Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
          <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx">
                  <b>
                  CIENCIA  Formación de líderes que forman líderes.
                  </b>
                  <br />
                  <br />
                  
                  “Este es un gran entrenamiento para las<br/>
                   personas que piensan que los más<br/>
                  ocupados son los más productivos ya<br/>
                  que, de hecho, los más productivos son<br/>
                  os menos ocupados, pues ocupan su<br/>
                  tiempo en cosas realmente cruciales y <br/>
                  fundamentales para que su<br/>
                  organización o proyecto ande. Lo que<br/>
                  causa el impacto es quién es el líder y<br/>
                  qué es lo que defiende, no lo que hace.<br/>
                  Esta es una de las razones por las que los<br/>
                  líderes del quinto nivel son tan escasos.<br/>
                  No malinterpretes esto, los líderes del <br/>
                  quinto nivel han estado HACIENDO durante <br/>
                  todo su proceso, su ejemplo ha resonado y <br/>
                  ha creado un seguimiento de líderes del más<br/>
                  alto calibre. Se han sacrificado muchas veces<br/>
                  para acrecentar la visión. Los líderes del<br/>
                  quinto nivel deben desaparecer para que <br/>
                  la causa se agrande. Dicho esto, al líder<br/>
                  olo le queda una acción principal: atraer <br/>
                   y formar a los líderes de más alto calibre<br/>
                  disponibles a favor de la causa. Los líderes<br/>
                  del quinto nivel se rodean de líderes<br/>
                  que tienen el potencial de eclipsar su<br/>
                   propia gloria personal.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
    
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
<br/>
<br/>
            <img 
              src={BadgeTargetIcon} 
              alt="badge-target.png"
              width="202"
              height="202"
              
              />
            <Paragraph align="center" size="sx">
            Verifica en la pestaña de <b>Mi Desempeño</b> si tu<br/>
            Director(a) ya logró la <b>Meta 1.</b>
            </Paragraph>
            <Paragraph align="center" size="sx">
            Recuerda que los Anfitriones de los<br/>
            Coordinadores están aplicando el ABC<br/>
            Convocatoria Plan B.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <b>Actividad Lista de espera convocatoria</b>
            </Paragraph>

            <Paragraph align="center" size="sx" style={{
              color:"green",
              marginTop:"70px",
              marginBottom:"70px",
            }}>
              icono lista de espera
            </Paragraph>
            <Paragraph align="center" size="sx">
            Capitán(a), si tu Director(a) no ha completado la<br/>
            Meta 1 después de activar el protocolo de Lista<br/>
            de espera de los Coordinadores y de tu<br/>
            Director(a),<b> podrás apoyar a tu equipo con tu
            <br/> Lista de espera.</b>
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <b>Anfitrión # 1</b>
              <br />
              de <br />
              🔴 Luis Carlos Sarmiento Angulo:
            </Paragraph>
            <Card
              type="quinary"
              outline
              className="rules-day-container-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
              }}
            >
              <MenuUserInfo
                name={toLowercaseAndCapitalize("Flabio Hinestroza")}
                role="host"
                nameRole="Anfitrión(a)"
                range={7}
                orientation="row"
                size="small"
              />
              <Paragraph align="center" size="sx">
                Requiere de <strong style={{ color: "#4DFFDD" }}>2</strong>{" "}
                personas de Lista de Espera.
              </Paragraph>
            </Card>
            <TextFieldList>
              <TextField
                disabled={true}
                placeholder="Selecciona un  posible Aspirante."
                value=""
                rightIcon={{
                  icon: "icon-add",
                  size: 24,
                  color: "#0063DD",
                  onClick: () => {
                    setOpen(true);
                  },
                }}
              />
            </TextFieldList>
            <Paragraph align="center" size="sx">
              A continuación, elige las posibles personas que
              <br />
              usará tu Coordinador(a) de tu Lista de Espera
              <br />
              para ayudar a su Anfitrión(a):
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            className="list-modal"
          >
            <span
              className="close icon-close"
              onClick={() => setOpen(false)}
              onKeyDown={handleKeyDown}
            ></span>

            <>
              <span className="title">🔵 Tu lista de espera </span>
              <ul className="list-modal--list">
                <li className={`list-modal--list--item`}>
                  Martin Olivas Sempere.
                </li>
                <li className={`list-modal--list--item`}>
                  Julián Verde Bustos.
                </li>
                <li className={`list-modal--list--item`}>
                  Alejandro Contreras Sandoval.
                </li>
                <li className={`list-modal--list--item`}>
                  Martin Olivas Sempere.
                </li>
                <li className={`list-modal--list--item`}>
                  Gisella Romero Bolaños.
                </li>
                <li className={`list-modal--list--item`}>
                  Luciana Patricia Gomez Gomez.
                </li>
              </ul>
            </>
          </Modal>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Vas súper!</b>
              
              <br />
              <br />
              Te queda poco para completar el  <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="good-hand-captain"
              style={{
                marginTop: "39px",
              }}
              width="226"
              height="226"
            />
            <CardCopyKey
              phrase="Asegura Meta 1 Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "90px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={Info}
              alt="info"
              width="32"
              height="32"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="sx" align="center">
            Eres el(la) Capitán(a) de 4 EPAs, 2 por cada<br/>
            Director, es decir que tienes 4 cronogramas por<br/>
            completar, uno por cada EPA. Tu función será<br/>
            completar los desafíos de cada cronograma, lo<br/>
            que implica que debes mantenerte atento(a) a<br/>
            las fechas. Por ejemplo, deberás completar<br/>
            cuatro Días 1, uno por cada cronograma. 
            </Paragraph>
            <Paragraph size="sx" align="center">
            En este desafío te vas a dirigir a <b>Mi progreso</b><br/> 
            para visualizar los 4 Cronogramas, 2 por cada<br/>
            Director para que identifiques cuándo es el Día 1<br/>
            de cada equipo y puedas estar ubicado(a) en el<br/>
            cronograma respectivo para los demás días. 

            </Paragraph>
            <Paragraph size="sx" align="center">
            
En cada día estarás escuchando las <b>Notas de<br/> 
voz</b> de tus Directores entregándoles las<br/>
 medallas correspondientes.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br/>
            <Paragraph align="center" size="sx">
            Actividad confirmación fechas misiones diarias<br/>
            del Día 1 para los EPAs de los Cordinadores de<br/>
            tus Directores.
              <br />
          
            </Paragraph>
            <Paragraph align="center" size="sx">
            ¿Cuál es la fecha del Día 1 del Coordinador<br/>
           <b> Luis Carlos Sarmiento Angulo</b>?
        
            </Paragraph>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 1
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 2
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <div
              className="drag-item__content"
              style={{
                border: "none",
                margin: "10px",
              }}
              onClick={() => setInfoIndex(1)}
            >
              <h3
                className="drag-item__title"
                style={{
                  margin: "10px",
                }}
              >
                Día 3
              </h3>
              <div
                className="drag-item__info"
                style={{
                  maxWidth: "280px",
                  height: "39px",
                  opacity: " 0.5",
                }}
              >
                <img src={CalendarIcon} alt="" />
                <input
                  type="date"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    border: "0",
                    color: "white",
                    margin: " 7px",
                  }}
                />
              </div>
            </div>
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              styles={{
                position: "relative",
                marginTop: "75px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
             <b>¡Excelente Capitán(a)!</b>
              <br/>
              Ingresaste las fechas correctas del cronograma.
            </Paragraph>

            <img
              src={CaptainContenedorCrono}
              alt="group-873"
              width="289"
              height="229"
            />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
            ”La credibilidad del líder surge de su capacidad<br/>
             de ayudar a otros líderes a obtener resultados.”
            </Paragraph>
            <Quote>
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
       Hoy es el plazo máximo para que tu Director(a)<br/>

       <b>🟡 Luciana Sandoval Paz</b> complete la Meta 1 del<br/>
      EPA del Coordinador(a) Luis Carlos Sarmiento<br/>
       que está agendado para:
            </Paragraph>
<Paragraph withBackground={true} align="center" style={{
  maxWidth:" 360px",
  width: "338px",
  border: "1px solid #73859f",
  backgroundColor: "#0f1b2b",
}}>
  <b>Lunes 4 de Noviembre del 2023</b>
  <br />Hora: 09:00 am
</Paragraph>
<br/>

          <Paragraph align="center" size="s"  style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}>
          Tu Director(a) deberá completar la Meta 1 de<br/>
          este EPA hoy a más tardar a las 9:30 pm, de lo<br/>
          contrario el EPA deberá de reagendarse. 
          </Paragraph>
          <Paragraph align="center" size="s"  style={{
               
                maxWidth: "364px",
              }}>
          <b>Si después de aplicar el Plan B y/o Lista de<br/>
           espera convocatoria no se ha logrado la Meta <br/>1,</b>
           tu Director(a) deberá reagendar el EPA.<br/>
          ¡Capitán(a)! Tendrás que enviar el reporte de<br/>
          reagendamiento del EPA a todo tu equipo.
          </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
             <b>Zona de Aprendizaje</b>
             <br/>
             (Reporte Agendamiento)
              <br />
            </Paragraph>
            <Paragraph align="center" size="sx">
            A continuación te explicamos cómo enviar el<br/>
            reporte de Reagendamiento del EPA en caso de<br/>
             ser necesario.
            </Paragraph>
            <img
              src={ZonaAprendizajeCaptain}
              alt="Zona-aprendizaje-captain"
              widh="250"
              height="250"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step+1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
          <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Vas súper!</b>
              
              <br />
              <br />
              Te queda poco para completar el  <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="good-hand-captain"
              style={{
                marginTop: "19px",
              }}
              width="226"
              height="226"
            />
            <CardCopyKey
              phrase="Preselección Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "90px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
          
        </>
      )}
    </>
  );
};

export default CaptainRulesDay;
