import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import WarningIcon from "../../../resources/images/warning.png";
import Medalla from "../../../resources/images/Medalla-Cap.png";
import Info from "../../../resources/images/Info.png";

import NewDirector from "../../../resources/images/director-avatar.png";
import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Genial from "../../../resources/images/clap-captain.png";

import GoodHandCaptain from "../../../resources/images/good-hand-captain.png";

import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import { CardCopyKey } from "../../molecules/card-copy-key";

import "./circle.scss";
import Card from "../../atoms/card";

import { UserDirectorCardInfo } from "../../molecules/user-director-card-info";
import { ReactComponent as PdlBadge } from "../../../resources/images/pdl-badge.svg";
const CaptainFilterDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Modal
            isOpen={step === 1}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Medalla} alt="medalla" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b>Justo a tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "39px",
                color: "green",
              }}
            >
              <b>icono smartphone</b>
            </Paragraph>
            <Paragraph
              align={"center"}
              size={"s"}
              style={{
                maxWidth: "364px",
              }}
            >
              ¡Capitán(a)!
              <br />
              <br />
              Debes estar enfocado(a) en mantener siempre
              <br />
              el celular cargado y con acceso a internet.
              <br />
              Recuerda que en el Día de Filtro, los 12
              <br />
              Aspirantes están validando sus PDLs para así
              <br />
              ganar el ticket de entrada al EPA. <br />
              Por esto, hoy habrán más consultas a TWOBOT
              <br />
              que en los días anteriores.
              <br />
              <br />
              Revisa constantemente que la aplicación de
              <br />
              TWOBOT esté funcionando correctamente.
              <br />
              ¡Mantente atento a tu celular!
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              <b>Espacios de sincronización. </b>
            </Paragraph>
            <Paragraph
              align="center"
              size="sx"
              style={{
                maxWidth: "340px",
                width: "340px",
              }}
            >
              Hoy los 2 Anfitriones están sincronizándose con
              <br />
              los Aspirantes que están atrasados en el
              <br />
              Cronograma para que completen los desafíos
              <br />
              de cada día y logren llegar al Día de Filtro.
              <br />
              <br />
              A continuación podrás visualizar el
              <br />
              agendamiento de sincronización de los
              <br />
              Anfitriones del(la) Coordinador(a):
            </Paragraph>
            <div
              className="director-card-asyn"
              style={{
                display: "flex",
                marginLeft: "189px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <div
                className="card-asyn-one"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                Paula I. Panesso
              </div>
              <div
                className="card-asyn-two"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                Juan D. Monsalve
              </div>
            </div>
            <div className="director-card-asyn">
              <div className="card-text-asyn">
                Aspirantes atrasados en el cronograma.
              </div>
              <div className="card-asyn-one">2</div>
              <div className="card-asyn-two">5</div>
            </div>
            <br />

            <div className="director-card-asyn">
              <div className="card-text-asyn">
                Aspirantes agendados para la sincronización.
              </div>
              <div
                className="card-asyn-one"
                style={{
                  border: " solid 1px #2fe984",
                }}
              >
                2
              </div>
              <div
                className="card-asyn-two"
                style={{
                  border: " solid 1px #ff3434",
                }}
              >
                4
              </div>
            </div>
            <Paragraph
              align="center"
              size="sx"
              style={{
                maxWidth: "340px",
                width: "340px",
                marginTop: "25px",
              }}
            >
              Si los Anfitriones de tu Coordinador(a) no ha
              <br />
              realizado el Desafío A, no podrás ver la
              <br />
              información completa.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="182"
              height="182"
            />

            <CardCopyKey
              phrase="Sync Filtro Capitán"
              nameChallenge="Desafio A"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "10px",
              }}
              onClick={() => finishChallenge("")}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <br />
            <Paragraph align="center" size="sx">
              <img src={Info} alt="info" />
              <br />
              <br />
              Te encuentras en tu Desafío B, en el Día de Filtro
              <br />
              los Anfitriones del(la) Coordinador(a) están
              <br />
              realizando el Entrenamiento ABC Filtro con sus
              <br />
              Aspirantes haciéndose pasar por Validador 0.
              <br />
              Mantén tu enfoque en la Formación de líderes
              <br />
              que forman líderes, siempre atento(a) en cómo
              <br />
              puedes apoyar al Coordinador(a) para que sus
              <br />
              Anfitriones entrenen a la máxima cantidad de
              <br />
              Aspirantes posibles (mínimo 3, máximos 6 por
              <br />
              Anfitrión) y revisa constantemente a los
              <br />
              Aspirantes que terminaron el entrenamiento,
              <br />
              este resultado es clave para que los Anfitriones
              <br />
              logren su Meta 2.
            </Paragraph>

            <Paragraph align="center" size="sx">
              ¡Supervisa junto con tu Director(a) el avance
              <br />
              para lograr el objetivo!
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={WarningIcon} alt="WarningIcon" />
              <>
                <Paragraph align="center" size="s">
                  ¡Podrás iniciar tu <b>Desafío C</b> cuando al
                  <br />
                  menos uno de los Anfitriones haya realizado
                  <br />
                  l entrenamiento ABC Filtro, con al menos 3<br />
                  de sus Aspirantes!
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <br />
            <div
              className="card-host card-coordinator-new"
              style={{
                margin: "0",
                marginBottom: "14px",

                width: "220px",
                borderRadius: "8px",
              }}
            >
              <div
                className="card-host-header"
                style={{
                  height: "98px",
                  marginTop: "8px",
                  marginLeft: "22px",
                }}
              >
                <img
                  src={NewDirector}
                  alt="director-avatar"
                  width={80}
                  height={80}
                  style={{
                    marginTop: "8px",
                  }}
                />
                <div className="coordinator-info">
                  <p className="coordinator-name text-lower-and-capitalize">
                    <b>
                      {" "}
                      Luciana
                      <br /> Sandoval Paz
                    </b>
                  </p>
                  <span
                    className="status nuevo"
                    style={{
                      paddingLeft: "7px",
                    }}
                  >
                    Director(a)
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card-host card-coordinator-new"
              style={{
                margin: "0",
                marginBottom: "14px",

                width: "275px",
                borderRadius: "8px",
              }}
            >
              <div
                className="card-host-header"
                style={{
                  height: "98px",
                  marginTop: "8px",
                  marginLeft: "32px",
                }}
              >
                <img
                  src={CoordinatorDirector}
                  alt="director-avatar"
                  width={80}
                  height={80}
                  style={{
                    marginTop: "8px",
                  }}
                />
                <div className="coordinator-info">
                  <p className="coordinator-name text-lower-and-capitalize">
                    <b>
                      {" "}
                      Luciana
                      <br /> Sandoval Paz
                    </b>
                  </p>
                  <span
                    className="status nuevo"
                    style={{
                      paddingLeft: "7px",
                    }}
                  >
                    Coordinador(a)
                  </span>
                </div>
              </div>
            </div>
            <UserDirectorCardInfo
              user={{
                name: (
                  <>
                    Paula Inés
                    <br />
                    Panesso Umbarila
                  </>
                ),
                role: "host",
                nameRole: "Anfitrión(a)",
                range: "7",

                trainingList: [
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                ],
              }}
              style={{
                width: "343px",
              }}
            />
            <UserDirectorCardInfo
              user={{
                name: (
                  <>
                    Juan David
                    <br />
                    Panesso Umbarila
                  </>
                ),
                role: "host",
                nameRole: "Anfitrión(a)",
                range: "7",

                trainingList: [
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                ],
              }}
              style={{
                width: "343px",
              }}
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío B.</b>
            </Paragraph>
            <img
              src={GoodHandCaptain}
              alt="good-hand-captain"
              widh="343"
              height="209"
            />
            <Card type="secondary">
              <Paragraph size={"s"} align={"center"}>
                <b>¡Recuerda!</b>
                <br />
                <br />
                Entre más Aspirantes entrenados en el ABC
                <br />
                Filtro, más Aspirantes con buen
                <br />
                Desempeño tendrán los Anfitriones para
                <br />
                preseleccionar a los 3 de su <b>Meta 2</b> y así
                <br />
                aportar a la lista de espera de tu equipo.
                <br />
                <br />
                Ya puedes iniciar con el <b>Desafío C.</b>
                <br />
                <br />
              </Paragraph>
            </Card>
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "80px",
                maxWidth: "364px",
              }}
            >
              <br />
              <br />
              <span style={{ color: "green" }}>
                <b>icono</b>
              </span>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              Capitán(a), a continuación te mostramos el Top
              <br />
              3 de los Aspirantes preseleccionados de los
              <br />
              Anfitriones del(la) Coordinador(a) de tu
              <br />
              Director(a).
              <br />
              <br />
              <br />
              Ten en cuenta que los Aspirantes aún están
              <br />
              realizando validaciones, este Top 3 puede
              <br />
              cambiar hasta las 9:30 pm que es el cierre del
              <br />
              día.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              withBackground={true}
              align="center"
              size="sx"
              style={{
                marginTop: "29px",
                width: "306px",
                maxWidth: "374px",
                fontSize: "old",
              }}
            >
              <b> 🟡 Luciana Sandoval Paz</b>
              <br />
              Director(a)
            </Paragraph>
            <Paragraph
              withBackground={true}
              align="center"
              size="sx"
              style={{
                marginTop: "29px",
                width: "374px",
                maxWidth: "374px",
                fontSize: "old",
              }}
            >
              <b>🔴 Luis Carlos Sarmiento Angulo.</b>
              <br />
              Coordinador(a)
            </Paragraph>
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "2px",
                width: "374px",
                maxWidth: "374px",
                fontSize: "old",
              }}
            >
              <b> Top 3 Anfitriones:</b>
            </Paragraph>

            <div
              className="containr-director-by-host"
              style={{
                display: "flex",
                width: "410px",
                maxWidth: "410px",
                gap: "10px",
              }}
            >
              <div
                className="box-director-by-host-one"
                style={{
                  borderRight: "solid 4px #35435e",
                }}
              >
                <div className="container-pdl-host">
                <Paragraph align={"center"} size={"s"}>
                ⚪ Paula Inés
                Panesso Umbarila
                </Paragraph>
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      1.
                    </span>
                    <span className="text-name-one">
                      Juan Camilo Ramírez López
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
                <div className="container-pdl-host">
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      2.
                    </span>
                    <span className="text-name-one">
                      Armando
                      <br /> Contreras C.
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
                <div className="container-pdl-host">
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      3.
                    </span>
                    <span className="text-name-one">
                      Daniel
                      <br />
                      Mejía Correa
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
              </div>
              <div className="box-director-by-host-one" style={{}}>
                <div className="container-pdl-host">
                <Paragraph align={"center"} size={"s"}>
                ⚪  Juan David
                Monsalve Arroave
                </Paragraph>
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      1.
                    </span>
                    <span className="text-name-one">
                      Juan Camilo Ramírez López
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
                <div className="container-pdl-host">
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      2.
                    </span>
                    <span className="text-name-one">
                      Armando
                      <br /> Contreras C.
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
                <div className="container-pdl-host">
                  <div
                    className="container-host"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      className="text-number-one"
                      style={{
                        fontSize: "xx-large",
                        fontWeight: " bold",
                      }}
                    >
                      3.
                    </span>
                    <span className="text-name-one">
                      Daniel
                      <br />
                      Mejía Correa
                    </span>
                  </div>
                  <div className="pdl-host">
                    <PdlBadge />
                  </div>
                </div>
              </div>
            </div>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={WarningIcon} alt="WarningIcon" />
              <>
                <Paragraph align="center" size="s">
                En <b>Mi Desempeño</b> podrás observar el<br/>
                cumplimiento de tu <b>Meta 2.</b>
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
              <a href="#" style={{
                  color:"blue",    
                  textDecoration: "blink",
                  paddingTop: "20px"
                }}> Ir a Mi Desempeño.</a>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandCaptain}
              alt="good-hand-captain"
              widh="343"
              height="209"
            />
            <CardCopyKey
              phrase="Cierre Filtro Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CaptainFilterDay;
