import Button from "../../atoms/button";
import Paragraph from "../../atoms/paragraph";
import ClapHand from "../../../resources/images/clap-candidate.png";
import GreetHand from "../../../resources/images/greet.png";
import TimeManagement from "../../../resources/images/time-management-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import Recognition from "../../../resources/images/recognition-medal.svg";
import Comodin from "../../../resources/images/comodin-medal.svg";
import Book from "../../../resources/images/book.png";
import WarningIcon from "../../../resources/images/warning.png";
import KeyH2 from "../../../resources/images/key-h2.png";
import Target from "../../../resources/images/target.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Modal from "../../atoms/modal";
import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import TextArea from "../../atoms/text-area";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import TextField from "../../atoms/text-field";
import CopyField from "../../atoms/copy-field";

const SecondDay = ({ finishChallenge, step, setStep }) => {

    const navigate = useNavigate();
    const { user, medalModal, setMedalModal } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();
    const [target, setTarget] = useState('');
    const [nameRef1, setNameRef1] = useState('');
    const [nameRef2, setNameRef2] = useState('');
    const [currentTime, setCurrentTime] = useState(null);
    const [justInTimeModal, setJustInTimeModal] = useState(false);

    const [canFinish, setCanFinish] = useState(false);

    const MEDALS = {
        recognition: {
            img: Recognition,
            text: 'Reconocimiento'
        },
        timeManagement: {
            img: TimeManagement,
            text: 'Manejo del tiempo'
        },
        justInTime: {
            img: JustInTime,
            text: 'Justo a tiempo'
        },
        wildcard: {
            img: Comodin,
            text: 'Comodin'
        }
    };

    const saveResponse = () => {
        const data = {
            responses: [
                {
                    question: 0,
                    response: target,
                },
            ]
        }


        axios.put(`${API.SAVE_RESPONSE.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const validateDate = () => {
        if (currentTime?.hours < 21) {
            if (currentTime?.minutes > 0 && currentTime?.minutes < 59) {
                return true;
            }
        } else if (currentTime?.hours === 21) {
            if (currentTime?.minutes <= 30) {
                return true;
            }
        }

        return false;
    };

    const assignMedal = () => {

        const data = {
            typeMedals: 'justInTime',
            medal: validateDate(),
        };

        axios.put(`${API.REGISTER_MEDAL.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setJustInTimeModal(true);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveResponseChallengeC = () => {
        const data = {
            references: [
                {
                    name: nameRef1,
                },
                {
                    name: nameRef2,
                }
            ]
        };


        axios.put(`${API.SAVE_PERSONAL_REFERENCES.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (medalModal !== '' && medalModal?.typeMedals === 'justInTime') {
            const finishTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Bogota', hour12: false });
            setCurrentTime({
                hours: finishTime.split(':')[0],
                minutes: finishTime.split(':')[1],
                seconds: finishTime.split(':')[2]
            });
            assignMedal();
        }
    }, [medalModal]);

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hola!</span>
                        <img src={GreetHand} alt="greet-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío se realiza por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />     
                                1. Mira  el video del Día 1 que te dará<br/>
                                  TWOBOT con  la clave que está en la<br/>
                                  siguiente pantalla.
                                <br/>
                                2. Escribe en TWOBOT la clave que<br/>
                                   aparece al final del video.
                                <br/>
                                3. Responde la pregunta que TWOBOT<br/>
                                  te hará enviando una nota de voz a tu<br/>
                                   Anfitrión(a).
                            </Paragraph>
                        
                        </Card>
                        <Button onClick={() => setStep(step+1)}>Continuar</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                   
                      
                        <Paragraph align="center" size="s">
                            <b>¡Vas súper!</b>
                            <br/>
                            <br/>
                        Si aún no has visto el video, miralo ahora<br/>
                         para iniciar tu <b>Desafío A.</b>
                        </Paragraph>
                        <img src={GoodHand} alt="good-hand" />
                      
                                   <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                            Día 2
                            </div>
                            <CopyField value="Día 2" setStep={() => {
                               
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                        <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                    </div>
                </Step>
                {/* <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío inicia por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                Cuando tu Anfitrión(a) reciba y confirme que le enviaste la nota de voz correspondiente a la <b>Zona de Entrenamiento de TWOBOT</b>, podrás continuar con tu <b>Desafío A.</b>
                            </Paragraph>
                        </Card>
                        <Button onClick={() => navigate('/missions')}>¡Vale!</Button>
                    </div>
                </Step>
                <Modal isOpen={step === 2} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={ClapHand} alt="Clap Candidate" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Genial!
                            <br />
                            Has completado el <b>Desafío A.</b>
                            <br />
                            <br />
                            Ya puedes continuar con el <b>Desafío B</b> del Día 2.
                            <br />
                            <br />
                            <b>👋🏻  Ya nos vemos.</b>
                        </Paragraph>
                        <Button size="small" onClick={() => finishChallenge(null)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Modal isOpen={step === 3} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={TimeManagement} alt="Time Management" />
                        <Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>Manejo del Tiempo!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                        </Paragraph>
                        <Button size="small" onClick={() => navigate('/missions')}>¡Vale!</Button>
                    </div>
                </Modal> */}
            </>}
            {challengeName === 'Desafío B' && <>
                <Modal isOpen={step === 1} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={WarningIcon} alt="Warning" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Importante!
                            <br />
                            Si en el texto se hace referencia a un concepto o idea que no esté acorde a tu sistema de creencias, busca el equivalente de acuerdo a lo que tú creas o sientas ¡Que las palabras no nos separen!
                        </Paragraph>
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="second-day-container">
                    <Card type="secondary" outline className="second-day-container-card">
                        <span className="title-card-read">DESTINO</span>
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “El destino lleva al propósito más lejos, al comprender el componente espiritual. El destino dice que nosotros fuimos creados para un propósito dado, que las habilidades únicas y las oportunidades que encontremos en nuestras vidas fueron dadas por Dios. La persona que eres, en dónde vives, los talentos con los que naciste, las oportunidades que han venido y que vendrán en tu camino, son todas parte de una pintura más grande, una pintura en la cual te pintaron para completar la única parte que SOLO TÚ PUEDES REALIZAR”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del Libro
                            <br />
                            Lanzando una Revolución sobre el Liderazgo
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="second-day-container-button">Continuar</Button>
                </Step>
                <Modal isOpen={step === 3} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={WarningIcon} alt="Warning" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Hey!
                            <br />
                            En este Desafío está en juego una Medalla de Reconocimiento. Podrás ganarla si al escribir tu Destino, el párrafo tiene entre 180 y 280 caracteres.
                        </Paragraph>
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <img src={Target} alt="Destino" width={138} height={138} />
                    <Paragraph align="center" size="s" className="text-area-container">
                        Si vivieras tu vida, cumpliendo tu Propósito...
                        ¿Cuál sería tu Destino? Una forma práctica y sencilla de escribirlo sería completando la siguiente frase: “Mi vida estaría destinada a ...
                    </Paragraph>
                    <TextArea
                        name="target"
                        id="target"
                        placeholder="Clic aquí para comenzar a escribir."
                        minlength={180}
                        maxlength={280}
                        value={target}
                        onChange={(e) => setTarget(e.target.value)}
                    />
                    <div className="counter">
                        Caracteres: {target.length}
                    </div>
                    <Paragraph align="center" size="xs">
                        Entre 180 y 280 caracteres para obtener Medalla de Reconocimiento.
                    </Paragraph>
                    <Button disabled={target?.length < 180} onClick={saveResponse}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        <b>¡Genial!</b>
                        <br />
                        Has completado el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={KeyH2} alt="Key H2" />
                    <Card type="secondary" >
                        <Paragraph align="center" size="s">
                            <b>¡Conseguiste la Llave de hoy!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Esta te da el paso al <b>Día 3.</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Ahora solo falta que completes el Desafío C, ¡ya puedes iniciarlo!
                        </Paragraph>
                    </Card>
                    <Button onClick={() => setStep(step + 1)}>Finalizar</Button>
                </Step>
                <Modal isOpen={step === 6} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={Recognition} alt="Time Management" style={{ opacity: target?.length >= 180 && target?.length <= 280 ? '1' : '0.5' }} />
                        {target?.length >= 180 && target?.length <= 280 ? <><Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>Reconocimiento!</b>
                        </Paragraph>
                            <Paragraph align="center" size="s">
                                Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                            </Paragraph></> : <Paragraph align="center" size="s">
                            ¡Ups! No has ganado una Medalla de <b>Reconocimiento.</b>
                        </Paragraph>}
                        <Button size="small" onClick={() => navigate('/missions')}>¡Vale!</Button>
                    </div>
                </Modal>
            </>}
            {/*** Pendiente corregir Desafio por Desafío */}
            {challengeName === 'Desafío C' && <>
                <Modal isOpen={step === 1} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={WarningIcon} alt="Warning" />
                        <Paragraph align="center" size="s">
                            ¡Importante!
                            <br />
                            <br />
                            Escribe referencias personales diferentes a las que ya registraste anteriormente.
                        </Paragraph>
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        Piensa en las personas que han caminado contigo en la vida, formando parte de tu Destino.
                        ¿Con quiénes te gustaría cumplir tu Destino, mediante este gran proyecto?
                        ¿A quiénes te gustaría ayudar a cumplir su Destino, mediante este gran proyecto?
                    </Paragraph>
                    <Paragraph align="center" size="s">
                        Escribe a continuación sus nombres.
                    </Paragraph>
                    <Paragraph align="left" size="s" className="references">
                        <b>Referencia personal 1 (Destino)</b>
                    </Paragraph>
                    <TextField
                        name="nameRef1"
                        placeholder="Nombre completo"
                        onChange={(e) => setNameRef1(e.target.value)}
                        required />
                    <Paragraph align="left" size="s" className="references">
                        <b>Referencia personal 2 (Destino)</b>
                    </Paragraph>
                    <TextField
                        name="nameRef2"
                        placeholder="Nombre completo"
                        onChange={(e) => setNameRef2(e.target.value)}
                        required />
                    <Button disabled={nameRef1.length === 0 && nameRef2.length === 0} onClick={saveResponseChallengeC}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Referencias Destino
                            </div>
                            <CopyField value="Referencias Destino" setStep={() => {
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                </Step>
                <Modal isOpen={justInTimeModal} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={JustInTime} alt="Just in Time" style={{ opacity: validateDate() ? '1' : '0.5' }} />
                        {validateDate ? <><Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>Justo a Tiempo!</b>
                        </Paragraph>
                            <Paragraph align="center" size="s">
                                Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                            </Paragraph>
                        </> :
                            <Paragraph align="center" size="s">
                                ¡No has ganado una Medalla de <b>Justo a Tiempo!</b>
                            </Paragraph>
                        }
                        <Button size="small" onClick={() => setJustInTimeModal(false)}>¡Vale!</Button>
                    </div>
                </Modal>
            </>}
            <Modal isOpen={medalModal !== ''} className="confirmation-register-modal medal-modal">
                <div className="confirmation-register-container">
                    <img src={MEDALS[medalModal?.typeMedals]?.img} alt="Time Management" style={{ opacity: medalModal.medal ? '1' : '0.5' }} width={90} height={81} />
                    {medalModal.medal ? <>
                        <Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                        </Paragraph></> :
                        <Paragraph align="center" size="s">
                            ¡No has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                    }
                    <Button size="small" onClick={() => {
                        setCanFinish(true);
                        setMedalModal('');
                    }}>¡Vale!</Button>
                </div>
            </Modal>
        </>
    );
};

export default SecondDay;