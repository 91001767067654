import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import MessageIcon from '../../../resources/images/message-icon.png';
import { API, MESSAGES,  SKILLS } from "../../../middleware/utils/constants";
import axios from "axios";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Info from "../../../resources/images/Info.png";

import { CounterBadges } from "../../molecules/counter-badges";
import MenuUserInfo from "../../molecules/menu-user-info";

import CandidateBlack from "../../../resources/images/candidate_black.png";
import MenuReferenceInfo from "../../molecules/menu-reference-info";
import TextField from "../../atoms/text-field";
const MyCoordinatorValidatorsTab = ({
  idChallenge,
  activeChallenge,

  children,
}) => {
  const { user, setUser, setNewTitleReference } = useContext(UserContext);

  const navigate = useNavigate();

  const { index,update } = useParams();

  
  
  
  const [personalReferences, setPersonalReferences] = useState([]);

  const [hostData, setHostData] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const [tempStatus, setTempStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [reference, setReference] = useState('');
  const [validator, setValidator] = useState({});
  const [path, setPath] = useState('');
  const [experienceState, setExperienceState] = useState('');
//  const isShow = ROLES[user?.role]?.challenges[challenge?.day]?.[0]?.isShowValidatorForm;

useEffect(() => {
  setPath(window.location.pathname.split('/')[1]);
  if (index && user) {
      setValidator(user?.personalReferences[index - 1]);
  }
});


  const renderStatus = () => {

      if (reference.status || reference?.sent) {
        return <span className="status validated">Validado</span>;
    } else if (reference?.sent || tempStatus) {
        return <span className="status sent">Esperando respuesta</span>;
    } else {
        return <span className="status no-sent">No enviado</span>;
    }
  };

  const sendEmail = () => {
      if (email?.length > 0) {
          setLoader(true);
          axios.post(`${API.SEND_VALIDATION_EMAIL.url}/${user?._id}/${encodeURIComponent(email)}/${reference.token}/${idChallenge}`).then((response) => {
              if (response.status === 201) {
                  setLoader(false);
              }
          }).catch((error) => {
              console.warn(error);
              if (error.response.status === 400) {
                  alert(MESSAGES.ERROR[error.response.data.message]);
                  setTempStatus(true);
                  setLoader(false);
              }
          });
      }
  };




  useEffect(() => {
   
    axios
      .get(
        `${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`
      )
      .then((response) => {
        if (response.status === 200) {
         
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [update, location.state]);

  useEffect(() => {
    axios
      .get(`${API.GET_INFORMATION.url}`)
      .then((response) => {
        if (response?.status === 200) {
          const personalReferences = response.data.data?.personalReferences
            .sort((a, b) => {
              if (
                a.processStatus === "registered" &&
                b.processStatus !== "registered"
              ) {
                return -1;
              }
              if (
                a.processStatus !== "registered" &&
                b.processStatus === "registered"
              ) {
                return 1;
              }
              if (
                a.processStatus === "scheduled" &&
                b.processStatus !== "scheduled"
              ) {
                return -1;
              }
              if (
                a.processStatus !== "scheduled" &&
                b.processStatus === "scheduled"
              ) {
                return 1;
              }
              return 0;
            })
            .map((personalReference, index) => {
              return personalReference.training === "candidate"
                ? {
                    ...personalReference,
                    newIndex: index,
                  }
                : personalReference;
            });
          setPersonalReferences(personalReferences);
          const updateUser = {
            ...user,
            personalReferences: personalReferences,
          };
          setUser(updateUser);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [update, location.state]);
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelReference=(data)=>{
    
    setReference(data)
  }

  useEffect(() => {
    let experienceText = '';
    const keys = Object.keys(reference.experience || {});
    keys?.forEach((key, index) => {
        if (SKILLS[key]) {
            experienceText += `${SKILLS[key]}`;
        }
        if (SKILLS[key] && 0 <= index && index < keys?.length - 1) {
            experienceText += ', ';
        }
    });
    setExperienceState(experienceText);
}, []);
  return (
    <div className="my-validators-tab">
  {reference==='' &&(<>
      {user && user?.role === "coordinator" && (
        <>
          <Button
            onClick={() => {
              navigate(`/register-reference/new/${idChallenge}`);
              setNewTitleReference("Agregar Referencia");
            }}
            styles={{
              marginTop: "20px",
            }}
          >
            Agregar Referencia
          </Button>
        </>
      )}


      {hostData &&
        hostData?.map((host, index) => {
          const candidatesScheduled = host.candidates?.length || 0;
          const suggestedCandidates = Math.max(6 - candidatesScheduled, 0);

          return (
            <div>
              <div
                className={`validator-item `}
                style={{
                  width: "104%",
                }}
              >
                <div
                  className={`validator-item__border ${
                    host.role || "candidate"
                  }`}
                  style={{
                    opacity: user?.personalReferences?.[index].status ? 1 : 0.5,
                  }}
                ></div>
                <div className={`validator-item__content host}`}>
                  <span className="index">{index + 1}</span>
                  <MenuUserInfo
                    name={host.name}
                    role="host"
                    nameRole="Anfitrión(a)"
                    range={7}
                    icon={
                      user?.role === "host" && (
                        <span className="icon-recognition"></span>
                      )
                    }
                    headingAlign={"left"}
                  ></MenuUserInfo>
                  <br />
                  <Paragraph
                    size="s"
                    align="left"
                    style={{
                      marginTop: "-15px",
                    }}
                  >
                    Agendados al Epa:
                  </Paragraph>
                  <CounterBadges
                    items={Array(6)
                      .fill(0)
                      .map((_, index) => ({
                        isActive: index < candidatesScheduled,
                      }))}
                    style={{
                      marginBottom: "0px",
                    }}
                  />
                </div>
                <span
                  className={`icon-chevron-back icon right ${"host"}`}
                ></span>
                <br />
              </div>
            </div>
          );
        })}
      {personalReferences
        ?.map((personalReference, index) => {
          const personalReferenceArray = Array.isArray(personalReference)
            ? personalReference
            : Object.values(personalReference);
            console.log({personalReferenceArray})
          return (
            <>
              <div>
                <div
                  className={`validator-item `}
                  style={{
                    width: "382px",
                    marginLeft: "15px",
                  }}
                >
                  <div
                    className={`validator-item__border ${"candidate"}`}
                    style={{
                      opacity: personalReferenceArray?.some(
                        (item) => item === true
                      )
                        ? 1
                        : 0.5,
                    }}
                  ></div>
                  <div className={`validator-item__content candidate}`} >
                    <span className="index">{hostData?.length + index+1}</span>
                    <MenuReferenceInfo
                      name={personalReference.name}
                      role="candidate"
                      nameRole="Validar(a)"
                      range={7}
                      headingAlign={"left"}
                    >
                      <span
                        className={` icon-recognition  ${"candidate"}`}
                      ></span>
                    </MenuReferenceInfo>
                  </div>
                  <span
                    className={`icon-chevron-back icon right ${"candidate"}`}
                    onClick={() =>{
                        handelReference(personalReference)

                    }}
                  ></span>
                  <br />
                </div>
              </div>
            </>
          );
        })}

      {children}
      <br/>
<img src={Info} alt="info"/>
      <Paragraph size="s" align="center">
        Al dar clic en cada Anfitrión tendrás la <br />
        posibilidad de revisar el estado de los <br />
        Aspirantes de cada uno de estos, así como
        <br />
        los agendamientos y las validaciones.
      </Paragraph>
      </>
    )
      }
  {
    reference!=='' &&(<>
      <div className={`menu-user-info menu-user-info `} style={{
        marginTop:'24px',

        flexDirection:'column',
      }} >
            <div className={`menu-user-info-avatar progress-${7}-8 `}>
               <img src={CandidateBlack} alt='candidate' style={{
                   marginTop: "-10px",
                   marginLeft: "-12px",
                   width: "129px"
               }}/>
            </div>
            <br/>
            <div className="menu-user-info-title">
                <h4 className={`heading center`}>{reference.name}</h4>
                <span >Validador(a)</span>
            </div>
        </div>
        <div className="validator-info__form">
            <span className="title">Correo de validación</span>
            <TextField
                name="email"
                placeholder="Ingresa aquí su correo electrónico"
                leftIcon={{ icon: 'icon-mail' }}
                value={email}
                pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            {renderStatus()}
            <Paragraph size="xs" align="center" >
                Enviaremos la solicitud de validación al correo personal de tu referencia.
            </Paragraph>
             <Button size="small" disabled={reference.status || tempStatus} onClick={sendEmail}>Enviar</Button>

        </div>
        <span className="title with-image" style={{
                display: "flex",
                /* justify-content: space-between; */
                /* background: red; */
                borderRadius: "15px",
                width: "211px"
        }}>
                
            </span>
            {!reference.perceptionValidator ? <> <div className="validator-info__what-think-about-me">
               Aún no te han validado
            </div></> : <>
             <span className="what-title" style={{
                        display: "flex",
                        alignItems: "center"
                }}>
                            <img src={MessageIcon} alt="message-icon" />
                            ¿Qué dijo de mí?
                        </span>
                        <div className="validator-info__what-think-about-me">

                                <div className="validator-info__what-think-about-me__item">
                                    <span className="title">Experiencias:</span>
                                    <Paragraph size="s" align="left" >
                                        {experienceState}
                                    </Paragraph>
                                </div>
                                <div className="validator-info__what-think-about-me__item">
                                    <table>
                                        <tr>
                                            <th>Habilidades</th>
                                            <th>Puntaje</th>
                                        </tr>
                                         {Object.keys(reference.skills || {})?.map((skill, index) => <tr>
                                            <td>{SKILLS[skill]}</td>
                                            <td className="score">{reference.skills[skill]}</td>
                                        </tr>)}
                                    </table>
                                </div>
                                <div className="validator-info__what-think-about-me__item">
                                    <span className="title">Cualidades:</span>
                                    <Paragraph size="s" align="left" >

                                       { Object.keys(reference.featuredSkills || {})?.map((skill, index) => <>

                                            {reference.featuredSkills[skill]}
                                            </>
                                     )}
                                    </Paragraph>
                                </div>
                                <div className="validator-info__what-think-about-me__item">
                                    <span className="title">Motivo:</span>
                                    <Paragraph size="s" align="left" >

                                    { Object.keys(reference.perceptionValidator || {})?.map((skill, index) => <>

{reference.perceptionValidator[skill]}
</>
)}
                                    </Paragraph>
                                </div>
                               {reference.other && <div className="validator-info__what-think-about-me__item">
                                    <span className="title">Otro:</span>
                                    <Paragraph size="s" align="left" >
                                    { Object.keys(reference.other || {})?.map((skill, index) => <>
                                      {reference.other[skill]}
                                    </>
                                    )}
                                    </Paragraph>
                                </div>}

                        </div></>
                        
                                    }
           
    </>)
  }

    </div>

  );
};

export default MyCoordinatorValidatorsTab;
