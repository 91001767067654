import React, { useState, useEffect } from 'react';

//const YouTubePlayer = ({ videoId, videoSeen, setVideoSeen }) => {
//     const [player, setPlayer] = useState(null);
//     const [playerState, setPlayerState] = useState(null);
//     const [originalTime, setOriginalTime] = useState(0);

//     useEffect(() => {
//         const onYouTubeIframeAPIReady = () => {
//             const YT = window.YT;
//             setPlayer(new YT.Player('youtube-player', {
//                 height: '188',
//                 width: '343',
//                 videoId: videoId,
//                 playerVars: {
//                     origin: window.location.origin,
//                 },
//                 events: {
//                     'onReady': onPlayerReady,
//                     'onStateChange': onPlayerStateChange,
//                 },
//             }));
//         };

//         if (window.YT && typeof window.YT.Player === 'function') {
//             onYouTubeIframeAPIReady();
//         } else {
//             window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
//         }

//         return () => {
//             if (player) {
//                 player.stopVideo();
//             }
//         };
//     }, [videoId]);

//     useEffect(() => {
//         if (playerState === window.YT.PlayerState?.SEEKING || playerState === window.YT.PlayerState?.BUFFERING) {
//             player?.seekTo(originalTime, true);
//         }
//     }, [originalTime, player, playerState]);

//     const onPlayerReady = event => {
//         setPlayer(event?.target);
//     };

//     const onPlayerStateChange = event => {
//         const state = event.data;
//         if (state === window.YT.PlayerState.PLAYING) {
//             setOriginalTime(player?.getCurrentTime());
//         } else if (state === window.YT.PlayerState.ENDED) {
//             setVideoSeen(true);
//         }
//         setPlayerState(state);
//     };

//     return (
//         <div id="youtube-player" className="youtube-player"></div>
//     );
// };

const YouTubePlayer = ({ videoId, videoSeen, setVideoSeen }) => {
    const [player, setPlayer] = useState(null);
    const [playerState, setPlayerState] = useState(null);

    useEffect(() => {
        const onYouTubeIframeAPIReady = () => {
            const YT = window.YT;
            setPlayer(new YT.Player('youtube-player', {
                height: '188',
                width: '343',
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange,
                },
            }));
        };

        if (window.YT && typeof window.YT.Player === 'function') {
            onYouTubeIframeAPIReady();
        } else {
            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        }

        return () => {
            if (player) {
                player.stopVideo();
            }
        };
    }, [videoId]);

    const onPlayerReady = event => {
        setPlayer(event.target);
    };

    const onPlayerStateChange = event => {
        const state = event.data;

        if (state === window.YT.PlayerState.ENDED) {
            setVideoSeen(true);
        }

        setPlayerState(state);
    };

    return (
        <div id="youtube-player" className="youtube-player"></div>
    );
};
export default YouTubePlayer;
