import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import GreetHand from "../../../resources/images/greet.png";


import TimeManagement from "../../../resources/images/time-management-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import Recognition from "../../../resources/images/recognition-medal.svg";
import Comodin from "../../../resources/images/comodin-medal.svg";
import TargetIcon from "../../../resources/images/target-icon.svg";
import Book from "../../../resources/images/book.png";
import KeyH1 from "../../../resources/images/key-h1.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../atoms/modal";
import TextArea from "../../atoms/text-area";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import TextField from "../../atoms/text-field";
import CopyField from "../../atoms/copy-field";
import { CardCopyKey } from "../../molecules/card-copy-key";

const FirstDay = ({ finishChallenge, step, setStep }) => {
  
    const navigate = useNavigate();
    const { challengeName, idChallenge } = useParams();
    const { user, medalModal, setMedalModal, currentChallenge, setCurrentChallenge } = useContext(UserContext);

    const [purpose, setPurpose] = useState('');
    const [nameRef1, setNameRef1] = useState('');
    const [nameRef2, setNameRef2] = useState('');
    const [currentTime, setCurrentTime] = useState(null);
    const [justInTimeModal, setJustInTimeModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const [activeChallenge, setActiveChallenge] = useState(null);

    const [canFinish, setCanFinish] = useState(false);

    const MEDALS = {
        recognition: {
            img: Recognition,
            text: 'Reconocimiento'
        },
        timeManagement: {
            img: TimeManagement,
            text: 'Manejo del tiempo'
        },
        justInTime: {
            img: JustInTime,
            text: 'Justo a tiempo'
        },
        wildcard: {
            img: Comodin,
            text: 'Comodin'
        }
    };

    const saveResponse = () => {
        const data = {
            responses: [
                {
                    question: 0,
                    response: purpose,
                },
            ]
        }


        axios.put(`${API.SAVE_RESPONSE.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const validateDate = () => {
        if (currentTime?.hours < 21) {
            if (currentTime?.minutes > 0 && currentTime?.minutes < 59) {
                return true;
            }
        } else if (currentTime?.hours === 21) {
            if (currentTime?.minutes <= 30) {
                return true;
            }
        }

        return false;
    };

    const assignMedal = () => {

        const data = {
            typeMedals: 'justInTime',
            medal: validateDate(),
        };

        axios.put(`${API.REGISTER_MEDAL.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setJustInTimeModal(true);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveResponseChallengeC = () => {
        const data = {
            references: [
                {
                    name: nameRef1,
                },
                {
                    name: nameRef2,
                }
            ]
        };


        axios.put(`${API.SAVE_PERSONAL_REFERENCES.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        console.log('currentChallenge', currentChallenge);
        const data = {
            params: {
                day: 'day one',
            }
        };

        axios.get(`${API.CHALLENGES_WITH_MEDALS_BE_ASSIGNED.url}/${user?._id}?action=CHALLENGES_WITH_MEDALS_BE_ASSIGNED`, data).then((response) => {
            if (response.status === 200) {
                const challenge = response.data.data;
                setActiveChallenge(challenge[2]);
                if (challenge[2].timeManagement && challenge[2].status && !copied) {
                    setMedalModal('');
                } else if(copied && challenge[2].timeManagement > 0) {
                    setMedalModal({
                        typeMedals: 'timeManagement',
                        medal: challenge[2].timeManagement,
                    });
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [copied]);

    useEffect(() => {
        if (medalModal !== '' && medalModal?.typeMedals === 'justInTime') {
            const finishTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Bogota', hour12: false });
            setCurrentTime({
                hours: finishTime.split(':')[0],
                minutes: finishTime.split(':')[1],
                seconds: finishTime.split(':')[2]
            });
            assignMedal();
        }
    }, [medalModal]);

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hola!</span>
                        <img src={GreetHand} alt="greet-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío se realiza por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                1. Mira  el video del Día 1 que te dará<br/>
                                  TWOBOT con  la clave que está en la<br/>
                                  siguiente pantalla.
                                <br/>
                                2. Escribe en TWOBOT la clave que<br/>
                                   aparece al final del video.
                                <br/>
                                3. Responde la pregunta que TWOBOT<br/>
                                  te hará enviando una nota de voz a tu<br/>
                                   Anfitrión(a).
                            </Paragraph>
                          
                        </Card>
                        <Button onClick={() => setStep(step+1)}>Continuar</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                   
                      
                        <Paragraph align="center" size="s">
                            <b>¡Vas súper!</b>
                            <br/>
                            <br/>
                        Si aún no has visto el video, miralo ahora<br/>
                         para iniciar tu <b>Desafío A.</b>
                        </Paragraph>
                        <img src={GoodHand} alt="good-hand" />
                      
                                   <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                            Inicio Aspirante
                            </div>
                            <CopyField value="Inicio Aspirante" setStep={() => {
                                setCopied(false);
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                        <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                    </div>
                </Step>
                {/* <Modal isOpen={step === 2} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={ClapHand} alt="Clap Candidate" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Genial!
                            <br />
                            Has completado el <b>Desafío A.</b>
                            <br />
                            <br />
                            Ya puedes continuar con el <b>Desafío B</b> del Día 1.
                            <br />
                            <br />
                            <b>👋🏻  Ya nos vemos.</b>
                        </Paragraph>
                        <Button size="small" onClick={() => navigate('/missions')}>¡Vale!</Button>
                    </div>
                </Modal> */}
            </>}
            {challengeName === 'Desafío B' && <>
                <Modal isOpen={step === 1} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={WarningIcon} alt="Warning" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Importante!
                            <br />
                            Si en el texto se hace referencia a un concepto o idea que no esté acorde a tu sistema de creencias, busca el equivalente de acuerdo a lo que tú creas o sientas ¡Que las palabras no nos separen!
                        </Paragraph>
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal> 
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="first-day-container">
                    <Card type="secondary" outline className="first-day-container-card">
                        <span className="title-card-read">PROPÓSITO</span>
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “El propósito es el ‘Norte Verdadero’ de nuestra brújula mental y emocional. (…) El propósito involucra sacrificarse por algo mejor, una contribución para hacer una diferencia y energía dirigida hacia una visión a largo plazo. (…) Nosotros estamos aquí por una razón. (…) J. Douglas Holladay dijo “todos buscamos vivir una vida que tenga significado. La gran pregunta que nos atormenta es: ¿de qué manera puede nuestra vida realmente hacer una diferencia?”. Viktor Frankl dijo “nosotros no determinamos nuestro propósito, nosotros lo detectamos”. Un hombre aplicó este proceso de descubrimiento para desenterrar un artefacto arqueológico muy importante. Cuando el científico encuentra algo, remueve pequeñas capas de tierra con mucho cuidado. Conforme va progresando este proceso que consume mucho tiempo, el artefacto se vuelve más visible, cada vez se puede aprender más y más acerca de él, hasta que todo el objeto está completamente desenterrado y expuesto al mundo. De una manera individual nuestros propósitos en la vida se parecen mucho a esto. Nosotros podemos sentir por años como si lo estuviéramos desenterrando. (…) Conforme vamos explorando, nuestro propósito se vuelve más claro. Y con esperanza, si trabajamos de una manera diligente hacia las respuestas, todo el propósito es descubierto y envuelto de una manera entusiasta. Esto es vitalmente importante. Así como decía el general Douglas MacArthur “Todo hombre se debe sentir avergonzado de morir antes de lograr algo magnífico en este mundo”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="first-day-container-button">Continuar</Button>
                </Step>
                <Modal isOpen={step === 3} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <div className="light">
                            <img src={WarningIcon} alt="Warning" />
                        </div>
                        <Paragraph align="center" size="s">
                            ¡Hey!
                            <br />
                            En este Desafío está en juego una Medalla de Reconocimiento. Podrás ganarla si al escribir tu Propósito, el párrafo tiene entre 180 y 280 caracteres.
                        </Paragraph>
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="text-area-container">
                    <img src={TargetIcon} alt="Proposito" width={138} height={138} />
                    <Paragraph align="center" size="s">
                        ¿Cuál es el Propósito de vida que has ido descubriendo en tu camino? ¿Qué sería lo que, para ti, haría que tu vida valiera la pena? Si aún no lo has pensado: ¡No te afanes! Este es el momento.  Escribe aquí lo que te surja.
                    </Paragraph>
                    <TextArea
                        name="purpose"
                        id="prupose"
                        placeholder="Clic aquí para comenzar a escribir."
                        minlength={180}
                        maxlength={280}
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                    />
                    <div className="counter">
                        Caracteres: {purpose.length}
                    </div>
                    <Paragraph align="center" size="xs">
                        Entre 180 y 280 caracteres para obtener Medalla de Reconocimiento.
                    </Paragraph>
                    <Button disabled={purpose?.length < 180} onClick={saveResponse}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        <b>¡Genial!</b>
                        <br />
                        Has completado el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={KeyH1} alt="Key H1" />
                    <Card type="secondary" >
                        <Paragraph align="center" size="s">
                            <b>¡Conseguiste la Llave de hoy!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Esta te da el paso al <b>Día 2.</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Ahora solo falta que completes el Desafío C, ¡ya puedes iniciarlo!
                        </Paragraph>
                    </Card>
                    <Button onClick={() => setStep(step + 1)}>Finalizar</Button>
                </Step>
                <Modal isOpen={step === 6} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={Recognition} alt="Recognition" style={{ opacity: purpose.length >= 180 && purpose.length <= 280 ? '1' : '0.5' }} />
                        {purpose.length >= 180 && purpose.length <= 280 ? <><Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>Reconocimiento!</b>
                        </Paragraph>
                            <Paragraph align="center" size="s">
                                Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                            </Paragraph></> :
                            <Paragraph align="center" size="s">
                                ¡No has ganado una Medalla de <b>Reconocimiento!</b>
                            </Paragraph>
                        }
                        <Button size="small" onClick={() => navigate('/missions')}>¡Vale!</Button>
                    </div>
                </Modal>
            </>
            }
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        Recuerda momentos y personas con las que has trabajado en proyectos, empleos, estudios, deportes, etc. ¿Quiénes han influido en descubrir tu Propósito? En particular, ¿con quiénes te gustaría seguir trabajando o volver a colaborar para crear metas compartidas?
                    </Paragraph>
                    <Paragraph align="center" size="s">
                        Escribe a continuación sus nombres.
                    </Paragraph>
                    <Paragraph align="left" size="s" className="references">
                        <b>Referencia personal 1 (Propósito)</b>
                    </Paragraph>
                    <TextField
                        name="nameRef1"
                        placeholder="Nombre completo"
                        onChange={(e) => setNameRef1(e.target.value)}
                        required />
                    <Paragraph align="left" size="s" className="references">
                        <b>Referencia personal 2 (Propósito)</b>
                    </Paragraph>
                    <TextField
                        name="nameRef2"
                        placeholder="Nombre completo"
                        onChange={(e) => setNameRef2(e.target.value)}
                        required />
                    <Button disabled={nameRef1.length === 0 && nameRef2.length === 0} onClick={saveResponseChallengeC}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Referencias Propósito
                            </div>
                            <CopyField value="Referencias Propósito" setStep={() => {
                                setCopied(true);
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                </Step>
                <Modal isOpen={justInTimeModal} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        <img src={JustInTime} alt="Just in Time" style={{ opacity: validateDate() ? '1' : '0.5' }} />
                        {validateDate() ? <><Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>Justo a Tiempo!</b>
                        </Paragraph>
                            <Paragraph align="center" size="s">
                                Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                            </Paragraph>
                        </> :
                            <Paragraph align="center" size="s">
                                ¡No has ganado una Medalla de <b>Justo a Tiempo!</b>
                            </Paragraph>
                        }
                        <Button size="small" onClick={() => setJustInTimeModal(false)}>¡Vale!</Button>
                    </div>
                </Modal>
            </>}
            <Modal isOpen={medalModal !== ''} className="confirmation-register-modal medal-modal">
                <div className="confirmation-register-container">
                    <img src={MEDALS[medalModal?.typeMedals]?.img} alt="Time Management" style={{ opacity: medalModal.medal ? '1' : '0.5' }} width={90} height={81} />
                    {medalModal.medal ? <>
                        <Paragraph align="center" size="s">
                            ¡Has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                        </Paragraph></> :
                        <Paragraph align="center" size="s">
                            <b>¡Ups!</b>
                            ¡Lamentablemente hoy no has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                            <br />
                            <br />
                            ¡Mañana podrás ganar {MEDALS[medalModal?.typeMedals]?.text} si completas el Desafío C antes de las 9:30 de la noche!
                        </Paragraph>
                    }
                    <Button size="small" onClick={() => {
                        setCanFinish(true);
                        setMedalModal('');
                    }}>¡Vale!</Button>
                </div>
            </Modal>
        </>
    );
};

export default FirstDay;