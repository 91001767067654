import { ROLES } from "../../../middleware/utils/constants";

const InfoDirectorBox = ({ style,role, roleName, level, title, paragraph, day }) => {
    return (
        <div className="info-box" style={style}>
            <div className="info-box-director-icon"></div>
            <div className="info-box-content">
                <h4 className="title">{title}</h4>
                <div className="info-section">
                    <div className={`info-section--level`}>
                        <span className="icon icon-user">{ROLES[
                        role
                      ]?.circle}</span>
                        <span className="info-section--role--text"><b>{roleName}</b> </span>
                    </div>
                    <div className={`info-section--level`}>
                        <span className="icon icon-scale"></span>
                        <span className="info-section--role--text"> <b>{level}</b></span>
                    </div>
                    <div className={`info-section--level`}>
                        <span className="icon icon-controllers"><b>{day}</b></span>
                    </div>
                    <p className="info-section--paragraph">{paragraph}</p>
                </div>
            </div>
        </div>
    );
};

export default InfoDirectorBox;