import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atoms/button";
import Paragraph from "../../atoms/paragraph";
import Step from "../../molecules/step";
import ClapHand from "../../../resources/images/clap-candidate.png";
import GoodHand from "../../../resources/images/clap-hand-coordinator.png";
import SendMail from "../../../resources/images/send-mail.png";
import CheckHand from "../../../resources/images/check-hand.png";
import TutorialAddReference from "../../../resources/images/tutorial-add-reference.png";
import Modal from "../../atoms/modal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { TabsContext } from "../../../middleware/providers/tabs-context";
import TouchScreenCoordinator from "../../../resources/images/touch-screen-coordinator.png";
import TutorialMyHosts from "../../../resources/images/tutorial-my-hosts.png";
import TutorialItsCandidates from "../../../resources/images/tutorial-its-candidates.png";
import TutorialProgressTable from "../../../resources/images/tutorial-progress-table.png";
import TutorialViewSchedule from "../../../resources/images/tutorial-view-schedule.png";
import ClapHandCoordinator from "../../../resources/images/clap-hand-coordinator.png";
import { CardCopyKey } from "../../molecules/card-copy-key";
import { UserCardInfo } from "../../molecules/user-card-info";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import "./index.scss";
import HostListPdlBadge from "../../molecules/host-list-pdl-badge";

const CoordinatorFilterDay = ({ finishChallenge, step, setStep }) => {
  const { user } = useContext(UserContext);
  const { setActiveTab } = useContext(TabsContext);
  const { challengeName, idChallenge } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getListOfNewHosts(user?._id);
  }, []);
  const [dataHost, setDataHost] = useState();
  const getListOfNewHosts = async (idUser) => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setDataHost(response.data.data);
      })
      .catch((err) => console.error(err));
  };
  const handleChange = (day) => {
    axios
    .put(
      `${API.CHALLENGE_CHANGE.url}/${user?._id}/${user?.role}/${day}`)
    .then((response) => {
      if (response.status === 200) {
       
        finishChallenge(null)
        navigate("/mission")
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Zona de aprendizaje.</b>
              <br />
              <br />
              ¡Hola Coordinador(a)!
              <br />
              Has desbloqueado una nueva funcionalidad en la pestaña{" "}
              <strong>Mis Anfitriones.</strong>
              Desde allí, podrás visualizar el progreso en los días del
              cronograma y los espacios de sincronización con día y hora de tus
              Anfitriones y sus Aspirantes. A continuación, te explicaremos de
              forma breve como hacer uso de esta funcionalidad.
            </Paragraph>
            <img
              src={TouchScreenCoordinator}
              alt="touch image"
              style={{
                marginTop: "10px",
              }}
            />
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              Dirígete a la pestaña <strong>Mis Anfitriones</strong> en el menú
              que encuentras en la parte superior.
            </Paragraph>
            <img
              src={TutorialMyHosts}
              alt="tutorial my hosts"
              style={{
                marginTop: "10px",
              }}
            />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "72px",
              }}
            >
              Luego, haz clic en el botón <strong>Ver Aspirantes</strong> o{" "}
              <strong>Ver Agendamientos</strong>, según tu interés.
            </Paragraph>
            <img
              src={TutorialItsCandidates}
              alt={"tutorial its candidates"}
              style={{
                marginTop: "10px",
              }}
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              Si haces clic e ingresas en Ver Aspirantes:
              <br />
              Encontrarás el botón{" "}
              <strong style={{ color: "#0063DD" }}>
                Ver Tabla de Progreso
              </strong>
              , con el cual podrás visualizar cómo van los Aspirantes del
              Anfitrión seleccionado. Según sus llaves y el entrenamiento.
            </Paragraph>

            <img
              src={TutorialProgressTable}
              alt="tutorial progress table"
              style={{
                marginTop: "52px",
              }}
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "12px",
              }}
            >
              Si haces clic e ingresas en Ver Aspirantes:
              <br />
              Al elegir la opción <strong>Ver Agendamientos</strong>,
              visualizarás los horarios que tu Anfitrión(a) dispuso de su
              tiempo, para guiar a sus Aspirantes. También quiénes se agendaron
              en dichos horarios.
            </Paragraph>

            <img
              src={TutorialViewSchedule}
              alt="tutorial view schedule"
              style={{
                marginTop: "12px",
              }}
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "129px",
              }}
            >
              Tu objetivo en este Desafío, es llevar a tus dos Anfitriones al
              Día de Filtro.
              <br />
              <strong style={{ color: "#00FF19" }}>
                Confirmarás con ellos los espacios para la sincronización que
                deberán enviar a sus Aspirantes para quedar al día.
              </strong>
              <br />
              Todos deben lograr sincronizarse en el{" "}
              <strong>Día de Filtro</strong> y estar listos para el
              entrenamiento del <strong>ABC Filtro</strong>. Si tu Anfitrión(a)
              no ha realizado el <strong>Desafío A</strong>, no podrás ver la
              información completa.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "12px",
              }}
            >
              ¡Genial!
              <br />
              <br />
              Has completado el Desafío A
            </Paragraph>
            <img
              src={ClapHandCoordinator}
              alt="clap-hand-coordinator"
              style={{
                marginTop: "10px",
              }}
            />
            <CardCopyKey
              phrase="Sync Filtro Coordinador"
              nameChallenge="Desafío A"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "56px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <Paragraph size="sx" align="center">
              <b>Visualización de entrenamientos ABC Filtro</b>
              <br />
              <br />
              Te encuentras en tu Desafío B. Tus dos Anfitriones están
              realizando el Entrenamiento ABC Filtro, con sus Aspirantes.
              <br />
              <br />
              No te permitas perder tu enfoque 🎯 en el Liderazgo.
              <ul style={{ textAlign: "left", paddingLeft: "26px" }}>
                <li>
                  Mantente atento(a) a cómo puedes apoyar a tus Anfitriones.
                </li>
                <li>
                  Revisa constantemente la cantidad de Aspirantes que ya
                  terminaron el Entrenamiento. Este resultado es clave para que
                  tus Anfitriones logren su Meta 2.
                </li>
              </ul>
            </Paragraph>
            <Paragraph
              size="sx"
              align="center"
              withBackground={true}
              style={{
                padding: "20px 16px 22px",
              }}
            >
              ¡Podrás iniciar tu <strong>Desafío C</strong>, cuando al <br />
              menos uno de tus Anfitriones haya realizado
              <br />
              el <strong>Entrenamiento ABC Filtro</strong>, con al menos <br />{" "}
              <strong>3 de sus Aspirantes</strong>!
              <br /> ¡Apoya a tus Anfitriones a lograr el objetivo!
            </Paragraph>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />
            {dataHost &&
              dataHost?.map((host) => (
                <UserCardInfo
                  user={{
                    name: toLowercaseAndCapitalize(host.name),
                    role: "host",
                    nameRole: "Anfitrión(a)",
                    range: "7",

                    trainingList: [
                      {
                        active: host?.goals.filter((item) => item?.goal === 1),
                      },
                      {
                        active: host?.goals.filter((item) => item?.goal === 2),
                      },
                      {
                        active: host?.goals.filter((item) => item?.goal === 3),
                      },
                    ],
                  }}
                />
              ))}

            <Paragraph size="s" align="center">
              Entre más Aspirantes entrenados en el <strong>ABC Filtro</strong>,
              más Aspirantes con buen Desempeño tendrán tus Anfitriones para
              lograr los 3 preseleccionados de su Meta 2. 🎯
            </Paragraph>

            <Paragraph
              size="sx"
              align="center"
              style={{
                width: "300px!important",
                maxWidth: "300px!important",
              }}
            >
              Recuerda que puedes chequear qué Aspirantes
              <br /> ya realizaron el Entrenamiento, con este ícono{" "}
              <span
                style={{
                  color: "#36FFDB",
                  border: "1px solid #36FFDB",
                  borderRadius: " 5px",
                  fontSize: " 12px",
                }}
              >
                Ent.{" "}
                <span
                  className="icon-grade"
                  style={{ color: "#36FFDB", fontSize: "16px" }}
                ></span>
              </span>{" "}
              desde <strong>Mis Anfitriones.</strong>
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "120px",
              }}
              icon={{
                icon: "icon-exit-side-btn",
                position: "right",
              }}
              onClick={() => setStep(step + 1)}
            >
              Ir a Mis Anfitriones
            </Button>
          </Step>

          <Modal
            isOpen={step === 3}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              marginTop: "41px",
              height: "500px",
            }}
          >
            <img src={GoodHand} alt="Good Hand" className="good-hand" />
            <span className="title">
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío B.</b>
            </span>
            <Paragraph align="center" size="sx">
              Ya puedes continuar con el <b>Desafío C</b> del <br />
              <b>Día del Filtro</b>
              <br />
              <br />
              👋🏻 Ya nos vemos.
            </Paragraph>

            <Button size="small" onClick={() => finishChallenge(null)}>
              ¡Vale!
            </Button>
          </Modal>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <Paragraph
              withBackground={true}
              align="center"
              size="sx"
              style={{
                marginTop: "29px",
                width: "374px",
                maxWidth: "374px",
                fontSize: "old",
              }}
            >
              <b> Top 3 (Validaciones)</b>
            </Paragraph>
            <HostListPdlBadge hosts={dataHost} />
       
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}    
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <Paragraph size="s" align="center">
              Para completar este Desafío debes enviar 6 correos de validación,
              si después de aplicar el ABC Filtro con las referencias personales
              que ingresaste en la Plataforma en los Día 1, 2 y 3, aún no has
              logrado este Desafío, ¡te tenemos una buena noticia! Podrás
              agregar nuevas <b>Referencias Personales.</b>
            </Paragraph>
            <img src={SendMail} alt="Send Mail" />
            <Paragraph size="xs" align="center" className="spacing-paragraph">
              A continuación, conoce brevemente como puedes agregar nuevas
              referencias.
            </Paragraph>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <Paragraph size="s" align="center">
              Presiona el botón <b>Agregar Referencia</b> que se encuentra en
              Mis Validadores. Ingresa los nombres y apellidos de tu nueva
              referencia y finaliza presionando el botón <b>Agregar.</b>
            </Paragraph>
            <img src={TutorialAddReference} alt="Tutorial Add Reference" />
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <span className="title">¡Excelente!</span>
            <img src={CheckHand} alt="epa-schedule-hand" />
            <Paragraph size="s" align="center">
              Ahora que ya sabes como agregar nuevas referencias, es momento de
              ponerte manos a la obra.
            </Paragraph>
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            {user?.personalReferences?.filter(
              (personalReference) => personalReference.sent
            ).length < 6 ? (
              <>
                <Paragraph size="s" align="center">
                  <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                  Te queda poco para completar el <b>Desafío C.</b>
                </Paragraph>
                <img src={GoodHand} alt="Good Hand" className="good-hand" />
                <Paragraph
                  size="s"
                  align="center"
                  className="filter-day-first-paragraph"
                >
                  Recuerda que para completar al 100% tu Desafío C debes enviar
                  al menos 6 correos de validación desde Mis Validadores.
                  <br />
                  <br />
                  Si tienes alguna duda o requieres apoyo con algo, cuentas
                  siempre con tu Anfitrión(a).
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph size="s" align="center">
                  <b>¡Genial!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                  Has completado el <b>Desafío C.</b>
                </Paragraph>
                <img src={ClapHand} alt="Good Hand" className="good-hand" />
                <Paragraph
                  size="s"
                  align="center"
                  className="filter-day-first-paragraph"
                >
                  ¡Conseguiste enviar 6 correos!
                  <br />
                  <br />
                  Esto te da el paso al Día de Alineamiento.
                  <br />
                  <br />
                  👋🏻 Nos vemos.
                </Paragraph>
              </>
            )}
            <Button
              onClick={() => {
                if (
                  user?.personalReferences?.filter(
                    (personalReference) => personalReference.sent
                  ).length >= 6
                ) {
                  handleChange("alignment day");
                } else {
                  setActiveTab(1);
                  navigate("/missions");
                }
              }}
            >
              {user?.personalReferences?.filter(
                (personalReference) => personalReference.sent
              ).length === 6
                ? "Finalizar"
                : "Ir a Mis Validadores"}
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorFilterDay;
