import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

import WarningIcon from "../../../resources/images/warning.png";
import { ReactComponent as AvatarMenu } from "../../../resources/images/circular-progress.svg";

//director
import Medalla from "../../../resources/images/Medalla-Cap.png";
import Info from "../../../resources/images/Info.png";
import PropositoCaptain from "../../../resources/images/PropositoCaptain.png";
import Helpe from "../../../resources/images/Help.png";

import DirectorMediun from "../../../resources/images/Medals-Cap-2.png";

import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Candidate from "../../../resources/images/candidate.png";
import NewHost from "../../../resources/images/new-host.png";

import Genial from "../../../resources/images/clap-captain.png";
import BackProvider from "../../../resources/images/Back2.png";
import Back from "../../../resources/images/Back.png";

//end of director

import Captain from "../../../resources/images/Captain.png";
import GoodHandCaptain from "../../../resources/images/good-hand-captain.png";

import KeyH1 from "../../../resources/images/key-h1.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { CardCopyKey } from "../../molecules/card-copy-key";

import { Quote } from "../../molecules/quote";
import "./circle.scss";
import MenuUserInfo from "../../molecules/menu-user-info";
import Card from "../../atoms/card";
import MenuUserInfoOn from "../../molecules/menu-user-info-on";
const CapitanFirstDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "39px",
              }}
            >
              <b>¡Hey!</b>
            </Paragraph>
            <img
              src={WarningIcon}
              alt="sync-aspirante-director"
              style={{
                marginTop: "10px",
              }}
            />

            <Paragraph withBackground={true} align="center" size="s">
              <b>
                Este desafío se realiza por fuera de la
                <br />
                Plataforma ACDC.{" "}
              </b>
              <br />
              <br />
              Cuando tu Capitán(a) reciba y confirme
              <br />
              que le enviaste la nota de voz
              <br />
              correspondiente al <b>Desafío A</b> podrás
              <br />
              continuar con tus desafíos diarios.
            </Paragraph>
            <br />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              ¡Vale!
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Medalla} alt="medalla" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b>Justo a tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={DirectorMediun} alt="DirectorMediun" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b> Manejo del tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="182"
              height="182"
            />
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <Paragraph size="s" align="center">
              Ya puedes continuar con el <b>Desafío B</b> del
              <br /> Día 1.
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              ¡Vale!
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>HAMBRE  Formación de líderes que<br/>
                   forman líderes..</b>
                  <br />
                  <br />
                  
                  “Se alimenta el Hambre con los tres<br/>
                  niveles de motivación:
                  <br />
                  <br />
                  Primer nivel: éxito material. 
                  <br />
                  <br />
                  Segundo nivel: reconocimiento y<br/> respeto. 
                  <br />
                  <br />
                  Tercer nivel: Propósito, Destino y<br/>
                   Legado (PDL).”
                
                   </Paragraph>
              </div>
            </div>
            <Quote>
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br/>
            <img  src={Captain} alt="captain" width="133" height={133}/>
            <Paragraph align="center" size="sx">
            Cuando iniciaste este camino con ACDC,<br/>
             escribiste tu Propósito.
             <br/>
             <br/>
             ¿Lo recuerdas?
            </Paragraph>
         

            <Paragraph
              align="left"
              size="sx"
              style={{
                marginTop: "12px",
              }}
            >
             Mi propósito es construir un mundo donde las<br/>
            personas tengan acceso al desarrollo<br/>
            individual y colectivo de sus habilidades,<br/>
            despertando sus pasiones y explotando sus<br/>
            talentos, algo totalmente ajeno al sistema que<br/>
            nos obliga a producir lo que necesita una parte<br/>
            de la sociedad.
            </Paragraph>
         
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Info} alt="info" />
              <>
                <Paragraph align="center" size="s">
                  <b>Ejemplos de Propósitos:</b>
                </Paragraph>
                <Paragraph align="center" size="s">
                👉🏻 Dedicarme a hacer lo que me gusta.<br/>
👉🏻 Poder ayudar a mi familia.<br/>
👉🏻 Escribir un libro.<br/>
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
          <img src={Helpe} alt="helpe" width={32} height={32} style={{
                marginLeft:" 341px",
                marginTop: "19px", 
                position: "absolute",
          }}/>
            <img src={PropositoCaptain} alt="PropósitoCaptain" width={138} height={138}/>
            
            <Paragraph align="center" size="sx">
            Ahora que has aprendido que la tecnología<br/>
            aplicada con propósito nos permite llevar a<br/>
            otro nivel la visión colectiva y los ingresos es<br/>
             momento de editar y/o  reescribir el<br/>
           <b> Propósito</b> de vida que escribiste cuando eras<br/>
             un Aspirante.
            </Paragraph>
            <div className="card-captain" style={{
                  border: "solid 1px #0063dd",
                  borderRadius: "10px",
                  background: "#0d233b",
                  marginTop: "10px",
            }}>

            
<Paragraph align="left" size="sx">
Mi propósito es construir un mundo donde las<br/>
personas tengan acceso al desarrollo<br/>
individual y colectivo de sus habilidades,<br/>
despertando sus pasiones y explotando sus<br/>
talentos, algo totalmente ajeno al sistema que<br/>
nos obliga a producir lo que necesita una parte<br/>
 de la sociedad. 
</Paragraph>
</div>
<div className="radiu-btn-text" style={{
                  borderRadius: "60px",
                  background: "#0d233b",
                  marginTop: "20px",
                  padding: "3px",
            }}>
<Paragraph align="center" size="sx" >
Caracteres: 277
</Paragraph>
</div>
<Paragraph align="center" size="xs">
Entre 180 y 280 caracteres para obtener <br/>
Medalla de Reconocimiento.
</Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
              style={{
                marginTop: "19px",
              }}
            >
              ¡Genial!
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={KeyH1}
              alt="KeyH1"
              style={{
                marginTop: "10px",
              }}
              width="246"
              height="246"
            />
            <Card type="secondary">
              <Paragraph align="center" size="s">
              ¡Conseguiste la Llave de hoy!
              </Paragraph>
              <Paragraph align="center" size="s">
              Esta te da el paso al <b>Día 2.</b>
              </Paragraph>
              <Paragraph align="center" size="s">
              Ya puedes iniciar con el <b>Desafío C</b> donde<br/>
               aprenderás más sobre esta Llave.
              </Paragraph>
            </Card>
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>HAMBRE  Formación de líderes que forman líderes.</b>
                  <br />
                  <br />
                  "La influencia de un líder de quinto nivel<br/>
                   puede observarse en aquellas raras<br/>
                   ocasiones en las que el líder no<br/>
                   solamente tiene éxito como líder por sí<br/>
                  mismo y no solamente tiene éxito en la<br/>
                  formación de otros líderes, sino que<br/>
                   consigue formar líderes que tienen la<br/>
                  habilidad de formar otros líderes<br/>
                   quiénes de ahí en adelante continuarán<br/>
                    con el Legado. En la historia de los<br/>
                    líderes de quinto nivel han definido<br/>
                    movimientos e ideas que se han<br/>
                    escuchado a través de las generaciones<br/>
                    teniendo un impacto profundo en el <br/>mundo
                     en aquellas raras ocasiones.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandCaptain}
              alt="good-hand-captain"
              widh="343"
              height="209"
            />
            <CardCopyKey
              phrase="Desafío C Día 1 Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CapitanFirstDay;
