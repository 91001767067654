import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

import WarningIcon from "../../../resources/images/warning.png";

import hostScreenTools from "../../../resources/images/Entrenamiento.png";

import ZonaAprendizaje from "../../../resources/images/Zona_aprendizaje.png";
import ExcelenteDirector from "../../../resources/images/ExcelenteDirector.png";

import ContenedorTutorialDirector from "../../../resources/images/ContenedorTutorialDirector.png";
import ContenedorTutorialDirectorTwo from "../../../resources/images/ContenedorTutorialDirectorTwo.png";
import ContenedorTutorialDirectorThree from "../../../resources/images/ContenedorTutorialThere.png";
import megaphone from "../../../resources/images/megaphone.png";

//director
import Medalla from "../../../resources/images/Medalla.png";
import DirectorMediun from "../../../resources/images/DirectorMediun.png";

import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Candidate from "../../../resources/images/candidate.png";
import NewHost from "../../../resources/images/new-host.png";

import Genial from "../../../resources/images/Genial.png";
import BackProvider from "../../../resources/images/Back2.png";
import Back from "../../../resources/images/Back.png";

//end of director

import GoodHandDirector from "../../../resources/images/good-hand-director.png";

import KeyH1 from "../../../resources/images/KeyH1.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { CardCopyKey } from "../../molecules/card-copy-key";

import { Quote } from "../../molecules/quote";
import "./circle.scss";
import Card from "../../atoms/card";
import { TextFieldPoints } from "../../molecules/textfield-points";
import TextArea from "../../atoms/text-area";
import { UserCardInfo } from "../../molecules/user-card-info";
import { UserDirectorCardInfo } from "../../molecules/user-director-card-info";
import { ReactComponent as PdlBadge } from '../../../resources/images/pdl-badge.svg';
const DirectorFilterDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);
  const navigate = useNavigate();
  const handleChange = (day) => {
    axios
    .put(
      `${API.CHALLENGE_CHANGE.url}/${user?._id}/${user?.role}/${day}`)
    .then((response) => {
      if (response.status === 200) {
       
        finishChallenge(null)
        navigate("/mission")
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "15px",
              }}
            >
              <b>Zona de Aprendizaje.</b>
              <br />
              <br />
              ¡Hola Director(a)! Has desbloqueado una nueva
              <br />
              funcionalidad en la pestaña <b>Mis Coordinadores.</b>
              <br />
              <br />
              Estás en el <b> Día de Filtro</b> y debes respaldar a tu
              <br />
              equipo para alcanzar la <b>Meta 2.</b> El(La)
              <br /> Coordinador(a) y los Anfitriones están
              <br /> concentrados en el avance de los Aspirantes
              <br />
              hacia el Día de Filtro y están coordinando para
              <br />
              poner al día a los que están atrasados en el
              <br />
              cronograma. Mantente al tanto del progreso y<br />
              la programación en la pestaña{" "}
              <b>
                Mis
                <br /> Coordinadores.
              </b>
              <br />
              <br />
              A continuación, aprenderás a hacer uso de esta
              <br /> funcionalidad.
            </Paragraph>
            <img
              src={ZonaAprendizaje}
              alt="Zona aprendizaje"
              width="136"
              height="136"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              Navegando en la Plataforma hasta un
              <br />
              Anfitrión(a), iniciando desde la pestaña
              <br />
              Coordinadores, encontrarás la siguiente
              <br />
              interfaz, donde a partir de ahora tendrás
              <br />
              activado el botón{" "}
              <span
                style={{
                  color: "blue",
                }}
              >
                Agendamientos.
              </span>
            </Paragraph>
            <br />
            <img
              src={ContenedorTutorialDirector}
              alt="ContenedorTutorialDirector"
              width="280"
              height="240"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              Al elegir la opción <b>Agendamientos,</b> visualizarás
              <br />
              los horarios que tu Anfitrión(a) dispuso de su
              <br />
              tiempo, para guiar a sus Aspirantes. También
              <br />
              quiénes se agendaron en dichos horarios.
            </Paragraph>
            <img
              src={ContenedorTutorialDirectorTwo}
              alt="ContenedorTutorialDirectorTwo"
              width="300"
              height="306"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              También tendrás disponible el botón{" "}
              <b
                style={{
                  color: "blue",
                }}
              >
                Ver Tabla de Progreso
              </b>
              , con el cuál podrás visualizar cómo
              <br />
              van los Aspirantes del Anfitrión seleccionado.
              <br />
              Según sus llaves y el entrenamiento.
            </Paragraph>
            <img
              src={ContenedorTutorialDirectorThree}
              alt="ContenedorTutorialDirectorThree"
              width="300"
              height="306"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              <b>Espacios de sincronización. </b>
            </Paragraph>
            <Paragraph
              align="center"
              size="sx"
              style={{
                maxWidth: "340px",
                width: "340px",
              }}
            >
              A continuación podrás visualizar el agendamiento
              <br />
              de sincronización de los Anfitriones de tu
              <br />
              Coordinador(a):
            </Paragraph>
            <div className="director-card-asyn">
              <div className="card-text-asyn">
                Aspirantes atrasados en el cronograma.
              </div>
              <div className="card-asyn-one">2</div>
              <div className="card-asyn-two">5</div>
            </div>
            <br />
            <div className="director-card-asyn">
              <div className="card-text-asyn">
                Aspirantes atrasados en el cronograma.
              </div>
              <div
                className="card-asyn-one"
                style={{
                  border: " solid 1px #2fe984",
                }}
              >
                2
              </div>
              <div
                className="card-asyn-two"
                style={{
                  border: " solid 1px #ff3434",
                }}
              >
                4
              </div>
            </div>
            <Paragraph
              align="center"
              size="sx"
              style={{
                maxWidth: "340px",
                width: "340px",
                marginTop: "25px",
              }}
            >
              Si los Anfitriones de tu Coordinador(a) no ha
              <br />
              realizado el Desafío A, no podrás ver la
              <br />
              información completa.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph
              align="center"
              size="sx"
              style={{
                marginTop: "25px",
              }}
            >
              <b>Actividad recomienda a tu Coordinador(a).</b>
              <br />
              <br />
              Da a tu Coordinador(a) recomendaciones para
              <br />
              lograr que los Aspirantes de sus Anfitriones
              <br />
              avancen en el cronograma hasta el Día de Filtro.
            </Paragraph>
            <TextArea
              name="purpose"
              id="prupose"
              placeholder="Clic aquí para comenzar a escribir."
              minlength={180}
              maxlength={280}
              value=""
            />

            <Paragraph align="center" size="xs" style={{}}>
              <div
                className="counter"
                style={{
                  margin: "34px",
                }}
              >
                Caracteres: 0
              </div>
              <br />
              Usa entre entre 180 y 280 caracteres.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={7} visibility={step === 7 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="182"
              height="182"
            />

            <CardCopyKey
              phrase="Sync Filtro Director"
              nameChallenge="Desafío A"
            />

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              ¡Vale!
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <br />
            <img
              src={hostScreenTools}
              width="224"
              height="224"
              alt="screen-tools-host-mission"
            />
            <Paragraph
              align="center"
              size="sx"
              style={{
                width: "364px",
                maxWidth: "365px",
              }}
            >
              Seguimiento del progreso del entrenamiento ABC
              <br />
              Filtro del equipo liderado por tu Coordinador(a)
              <br />
              <b>🔴 Luis Carlos Sarmiento Angulo.</b>
            </Paragraph>
            <Paragraph
              align="center"
              size="sx"
              style={{
                width: "364px",
                maxWidth: "365px",
              }}
            >
              Te encuentras en tu Desafío B, los Anfitriones de
              <br />
              tu Coordinador(a) están realizando el
              <br />
              Entrenamiento ABC Filtro, es decir, siendo
              <br />
              Validador(a) de prueba de sus Aspirantes.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />
            <img src={megaphone} width="224" height="224" alt="megaphone" />

            <Paragraph
              align="center"
              size="sx"
              style={{
                width: "364px",
                maxWidth: "365px",
              }}
            >
              Mantén tu enfoque en la Formación de líderes,
              <br />
              siempre atento(a) en cómo puedes apoyar a tu
              <br />
              Coordinador(a) para que sus Anfitriones
              <br />
              entrenen al mayor número posible de
              <br />
              Aspirantes (mínimo 3, máximos 6 por Anfitrión)
              <br />
              y revisa constantemente a los Aspirantes que
              <br />
              terminaron el Entrenamiento, este resultado es
              <br />
              clave para que los Anfitriones y tu
              <br />
              Coordinador(a) logren su Meta 2.
              <br />
              <br />
              ¡Apoya a tu Coordinador(a) a lograr la Meta 2!
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={WarningIcon} alt="DirectorMediun" />
              <>
                <Paragraph align="center" size="s">
                  ¡Podrás iniciar tu Desafío C cuando al
                  <br />
                  menos uno de los Anfitriones de tu
                  <br />
                  Coordinador(a) haya realizado el
                  <br />
                  entrenamiento ABC Filtro, con al menos 3<br />
                  de sus Aspirantes!
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph align="center" size="sx">
              <img
                src={CoordinatorDirector}
                alt="CoordinatorDirector"
                width="145"
                height="145"
              />
              <br />
              <b>
                Luis Carlos
                <br />
                Sarmiento Angulo
              </b>
              <br />
              Coordinador(a)
              <br />
            </Paragraph>
            <UserDirectorCardInfo
              user={{
                name: (
                  <>
                    Paula Inés
                    <br />
                    Panesso Umbarila
                  </>
                ),
                role: "host",
                nameRole: "Anfitrión(a)",
                range: "7",

                trainingList: [
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                  {
                    active: true,
                  },
                ],
              }}
              style={{
                width: "343px",
              }}
            />
            <UserDirectorCardInfo
              user={{
                name: (
                  <>
                    Juan David
                    <br />
                    Panesso Umbarila
                  </>
                ),
                role: "host",
                nameRole: "Anfitrión(a)",
                range: "7",

                trainingList: [
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                  {
                    active: false,
                  },
                  {
                    active: true,
                  },
                ],
              }}
              style={{
                width: "343px",
              }}
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal isOpen={step === 5} className="confirmation-register-modal">
            <div className="confirmation-register-container">
              <img src={WarningIcon} alt="DirectorMediun" />
              <>
                <Paragraph align="center" size="sx">
                  Recuerda que en <b>Mis Coordinadores</b>
                  <br />
                  seleccionando a tu Coordinador(a) y luego
                  <br />a uno de sus Anfitriones, en{" "}
                  <b>
                    Mis
                    <br />
                    Aspirantes
                  </b>{" "}
                  podrás visualizar a los que ya
                  <br />
                  completaron el Entrenamiento ABC Filtro
                  <br />{" "}
                  <span
                    style={{
                      color: "#36FFDB",
                      borderRadius: " 5px",
                      fontSize: " 12px",
                    }}
                  >
                    <span
                      className="icon-grade"
                      style={{ color: "#36FFDB", fontSize: "26px" }}
                    ></span>
                  </span>{" "}
                  y los que aún están pendientes.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
              style={{
                marginTop: "19px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              width="226"
              height="226"
              style={{
                marginTop: "10px",
              }}
            />

            <Paragraph withBackground={true} align="center" size="s">
              <b> ¡Recuerda!</b>
              <br />
              <br />
              Entre más Aspirantes entrenados en el{" "}
              <b>
                ABC
                <br />
                Filtro,
              </b>{" "}
              más Aspirantes con buen
              <br />
              Desempeño tendrán los Anfitriones para
              <br />
              preseleccionar a los 3 de su Meta 2 y así
              <br />
              aportar a la lista de espera de tu equipo.
              <br />
              <br />
              Ya puedes iniciar con el <b>Desafío C.</b>
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div
              className="host-img-dicretor"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginTop: "30px",
              }}
            >
              <div className="host-by-director">
                <img src={NewHost} alt="host" width="174" height="174" />
              </div>
              <div
                className="candiate-by-director"
                style={{
                  display: "grid",
                }}
              >
                <img src={Candidate} alt="candidate" />
                <img src={Candidate} alt="candidate" />
                <img src={Candidate} alt="candidate" />
              </div>
            </div>
            <Paragraph align="center" size="sx" style={{}}>
              Director(a),
              <br />a continuación te mostramos el <b>Top 3</b> de los
              <br />
              Aspirantes preseleccionados de los
              <br />
              Anfitriones de tu Coordinador(a).
              <br />
              <br />
              Ten en cuenta que los Aspirantes aún están
              <br />
              realizando validaciones, este <b>Top 3</b> puede
              <br />
              cambiar hasta las 9:30 pm que es el cierre del
              <br />
              día.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
          <Paragraph align="center" size="sx" style={{
            marginTop: "29px",
          }}>
             <b>🔴 Luis Carlos Sarmiento Angulo.</b>
            </Paragraph>
            <div className="containr-director-by-host" style={{
              display: "flex",
              width: "410px",
              maxWidth: "410px",
              gap: "10px",
            }}>
             
                 <div className="box-director-by-host-one" style={{
                  borderRight: "solid 4px #35435e"
                 }}>
                  <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >1.</span>
                  <span className="text-name-one">Juan Camilo Ramírez López</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >2.</span>
                  <span className="text-name-one">Armando<br/> Contreras C.</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >3.</span>
                  <span className="text-name-one">Daniel<br/> 
                  Mejía  Correa</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             </div>
             <div className="box-director-by-host-one" style={{
                 
                 }}>
                  <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >1.</span>
                  <span className="text-name-one">Juan Camilo Ramírez López</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >2.</span>
                  <span className="text-name-one">Armando<br/> Contreras C.</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             <div className="container-pdl-host">
                  <div className="container-host" style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems: "center",
                    gap: "10px",

                  }}>
                  <span className="text-number-one"
                  style={{
                    fontSize: "xx-large",
                    fontWeight:" bold"
                  }}
                  >3.</span>
                  <span className="text-name-one">Daniel<br/> 
                  Mejía  Correa</span>
           
             </div>
             <div className="pdl-host">
             <PdlBadge />
             </div>
             
             </div>
             </div>
             
            </div>
        
         
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              widh="343"
              height="209"
            />
            <CardCopyKey
              phrase="Cierre Filtro Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => handleChange("alignment day")}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default DirectorFilterDay;
