import { useState, useRef, useEffect } from "react";

const DragItem = ({ id, children, onDragStart, onDragEnd, onDrop }) => {
    const [isDragging, setIsDragging] = useState(false);
    const touchStartPos = useRef({ x: 0, y: 0 });
    const touchMoveRef = useRef(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    }, []);

    // --- Manejadores para Mouse (Drag & Drop) ---
    const handleDragStart = (e) => {
        if (isTouchDevice) return; // Evita conflictos en móviles
        e.dataTransfer.setData("id", id.toString());
        setIsDragging(true);
        onDragStart(id);
    };

    const handleDragEnd = () => {
        if (isTouchDevice) return;
        setIsDragging(false);
        onDragEnd();
    };

    const handleDrop = (e) => {
        if (isTouchDevice) return;
        e.preventDefault();
        const droppedId = parseInt(e.dataTransfer.getData("id"), 10);
        onDrop(droppedId);
    };

    // --- Manejadores para Touch (Móvil) ---
    const handleTouchStart = (e) => {
        if (!isTouchDevice) return;
        const touch = e.touches[0];
        touchStartPos.current = { x: touch.clientX, y: touch.clientY };
        touchMoveRef.current = e.target;
        document.body.style.overflow = "hidden"; // Bloquea el scroll
        setIsDragging(true);
        onDragStart(id);
    };

    const handleTouchMove = (e) => {
        if (!isTouchDevice) return;
        const touch = e.touches[0];
        const moveX = touch.clientX - touchStartPos.current.x;
        const moveY = touch.clientY - touchStartPos.current.y;

        if (touchMoveRef.current) {
            touchMoveRef.current.style.transform = `translate(${moveX}px, ${moveY}px)`;
        }
    };

    const handleTouchEnd = () => {
        if (!isTouchDevice) return;
        if (touchMoveRef.current) {
            touchMoveRef.current.style.transform = "none";
            touchMoveRef.current = null;
        }
        document.body.style.overflow = ""; // Reactiva el scroll
        setIsDragging(false);
        onDragEnd();
        onDrop(id);
    };

    return (
        <div
            className={`drag-item ${isDragging ? "dragging" : ""}`}
            draggable={!isTouchDevice} // Desactiva drag en móviles
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()} // Permite soltar
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>
    );
};

export default DragItem;
