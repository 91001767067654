import React, { useState, useRef } from "react";
import Paragraph from "../../atoms/paragraph";

const DragAndDrop = () => {
  const [items, setItems] = useState([
    { id: 1, text: "Guía de TWOBOT cuando le envías las palabras claves." },
    { id: 2, text: "Videos explicativos para las misiones diarias." },
    { id: 3, text: "Tutoriales del uso de la Plataforma." },
    { id: 4, text: "Comunicación con tu Anfitrión(a)." },
    { id: 5, text: "Lecturas en la Plataforma." },
  ]);

  const draggedItem = useRef(null);

  const handleDragStart = (index) => {
    draggedItem.current = index;
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedItem.current === index) return;
    const updatedItems = [...items];
    const item = updatedItems.splice(draggedItem.current, 1)[0];
    updatedItems.splice(index, 0, item);
    draggedItem.current = index;
    setItems(updatedItems);
  };

  const handleTouchStart = (index) => {
    draggedItem.current = index;
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const element = document.elementFromPoint(touch.clientX, touch.clientY);
    if (!element) return;
    const parent = element.closest(".drag-item");
    if (!parent) return;
    const newIndex = parseInt(parent.dataset.index, 10);
    handleDragOver(e, newIndex);
  };

  return (
    <div className="drag-select">
      {items.map((item, index) => (
        <div
          key={item.id}
          className="drag-item"
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onTouchStart={() => handleTouchStart(index)}
          onTouchMove={handleTouchMove}
          data-index={index}
        >
          <div className="drag-item__content">
            <h1 className="drag-item__title">{index + 1}.</h1>
            <div className="drag-item__info">
              <Paragraph>{item.text}</Paragraph>
              <div className="icon-container">
                <span className="icon icon-chevron-back up"></span>
                <span className="icon icon-chevron-back down"></span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DragAndDrop;
