import { LandingFooter } from "../../organisms/landing-footer";
import { LandingHeader } from "../../organisms/landing-header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { LandingHero } from "../../organisms/landing-hero";

import InnovationImage from '../../../resources/images/innovation-image.png';
import LandingHeroCandidate from '../../../resources/images/landing-hero-candidate.png';
import LandingHeroCoordinator from '../../../resources/images/landing-hero-coordinator.png';
import PreviewPlatform from '../../../resources/images/preview-platform.png';
import PlayStore from '../../../resources/images/play-store.png';
import AppStore from '../../../resources/images/app-store.png';
import ListBox from '../../../resources/images/list-box-136-148.svg';
import ScreenTouchHand from '../../../resources/images/screen-touch-hand.png';
import VictoryHand from '../../../resources/images/victory-hand-136-148.png';
import AboutUsImage from '../../../resources/images/about-us.png';
import AvatarCarlosPenaloza from '../../../resources/images/avatar-carlos-penaloza.png';
import FounderOne from '../../../resources/images/founder-1.png';
import FounderTwo from '../../../resources/images/founder-2.png';
import Triangle from '../../../resources/images/triangle.png';
import TestimonialImage from '../../../resources/images/testimonial-image.png';
import Button from "../../atoms/button";
import Card from "../../atoms/card";
import { useState } from "react";

export const LandingPage = () => {

    const [activeRealIndex, setActiveRealIndex] = useState(0);

    const founders = [
        {
            image: FounderOne,
            name: 'Camilo Aragón',
            role: 'Ingeniero Mecánico',
        },
        {
            image: FounderTwo,
            name: 'Luz Karime Castañeda',
            role: 'Ingeniera Industrial',
        }
    ];

    const isMobile = window.matchMedia("only screen and (max-width: 500px)").matches;
    const sizeSlideImage = isMobile ? { width: '195px', height: '280px' } : {};
    const styleImagePlatform = { position: 'relative' };
    const sizeImagePlatform = !isMobile ? { ...styleImagePlatform, top: '-255px', marginTop: '104px', zIndex: '2' } : { ...styleImagePlatform, width: '100%' };

    return (
        <div className="landing-page">
            <LandingHeader />
            <main className="landing-page-main">
                <section className="landing-page-carousel">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        slidesPerView={1}
                        pagination={{ clickable: true, renderBullet: (index, className) => `<span class="${className}" style="margin-bottom: 31px;"></span>` }}
                        navigation={!isMobile}
                        autoplay={{ delay: 5000 }}
                        onSlideChange={(swiper) => {
                            setActiveRealIndex(swiper.realIndex);
                        }}
                        loop
                    >
                        <SwiperSlide>
                            <LandingHero
                                title={<p><span style={{ color: '#0063DD' }}>Innovación</span> para Generar Ingresos Online</p>}
                                description="En ACDC, utilizamos tecnologías disponibles desde el celular y estrategias de educación paso a paso con contenido audiovisual para alcanzar una efectividad del 96% en la adquisición y retención de clientes en la industria del Network Marketing, sin ser nosotros mismos una empresa de Network Marketing."
                                action={<Button styles={{ marginBottom: '80px' }}>¡Quiero hacer parte de este proyecto!</Button>}
                                image={InnovationImage}
                                imageStyle={sizeSlideImage} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <LandingHero
                                title={<p><span style={{ color: '#4DFFDD' }}>Gamificación</span> y Logros Interactivos</p>}
                                description="Nuestra plataforma digital ofrece una experiencia gamificada donde los usuarios cumplen misiones y ganan medallas a medida que avanzan entre niveles. Este enfoque asegura una interacción entretenida y motivadora."
                                action={<Button >¡Quiero hacer parte de este proyecto!</Button>}
                                image={LandingHeroCandidate}
                                imageStyle={sizeSlideImage} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <LandingHero
                                title={<p><span style={{ color: '#FF3434' }}>Tecnología</span> e inmersión Audiovisual</p>}
                                description="ACDC combina tecnología de punta y contenido audiovisual inmersivo, con videos en 3D alineados con el progreso en la experiencia del usuario. Este enfoque asegura una experiencia única y altamente efectiva."
                                action={<Button >¡Quiero hacer parte de este proyecto!</Button>}
                                image={LandingHeroCoordinator}
                                imageStyle={sizeSlideImage} />
                        </SwiperSlide>
                    </Swiper>
                </section>
                <section className="landing-page-our-platform">
                    <LandingHero
                        title={<p style={{ fontSize: '64px', marginTop: '104px' }}>Nuestra Plataforma Refleja Nuestro Compromiso</p>}
                        description={`Nuestra plataforma digital es la culminación de nuestro esfuerzo y dedicación. Combina tecnología avanzada y contenido audiovisual inmersivo para ofrecer una experiencia única, dinámica y efectiva. <br /><br /> ${!isMobile ? 'Próximamente...' : ''}`}
                        image={PreviewPlatform}
                        imageStyle={sizeImagePlatform}
                        action={!isMobile && <div>
                            <a href="#google-play" target="_blank" rel="noopener noreferrer"><img src={PlayStore} alt="play store" /></a>
                            <a href="#app-store" target="_blank" rel="noopener noreferrer"><img src={AppStore} alt="app store" style={{ marginLeft: '16px' }} /></a>
                        </div>}
                        extraContent={
                            <div className="landing-page-our-platform__extra-content">
                                <img src={AvatarCarlosPenaloza} alt="Carlos Peñaloza" className="landing-page-our-platform__extra-content--image" />
                                <div className="landing-page-our-platform__extra-content--text">
                                    <img src={Triangle} alt="Triangle" className="landing-page-our-platform__extra-content--text--triangle" />
                                    <img src={TestimonialImage} alt="Testimonial" className="landing-page-our-platform__extra-content--text--image" />
                                    <span className="landing-page-our-platform__extra-content--text--name">Carlos Peñaloza</span>
                                    <span className="landing-page-our-platform__extra-content--text--role">Lider de Gestión ACDC</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="17" viewBox="0 0 150 17" fill="none" className="landing-page-our-platform__extra-content--text--stars">
                                        <path d="M8.55696 13.6975L12.707 16.2075C13.467 16.6675 14.397 15.9875 14.197 15.1275L13.097 10.4075L16.767 7.2275C17.437 6.6475 17.077 5.5475 16.197 5.4775L11.367 5.0675L9.47696 0.6075C9.13696 -0.2025 7.97696 -0.2025 7.63696 0.6075L5.74696 5.0575L0.916957 5.4675C0.0369575 5.5375 -0.323043 6.6375 0.346957 7.2175L4.01696 10.3975L2.91696 15.1175C2.71696 15.9775 3.64696 16.6575 4.40696 16.1975L8.55696 13.6975Z" fill="#0063DD" />
                                        <path d="M41.557 13.6975L45.707 16.2075C46.467 16.6675 47.397 15.9875 47.197 15.1275L46.097 10.4075L49.767 7.2275C50.437 6.6475 50.077 5.5475 49.197 5.4775L44.367 5.0675L42.477 0.6075C42.137 -0.2025 40.977 -0.2025 40.637 0.6075L38.747 5.0575L33.917 5.4675C33.037 5.5375 32.677 6.6375 33.347 7.2175L37.017 10.3975L35.917 15.1175C35.717 15.9775 36.647 16.6575 37.407 16.1975L41.557 13.6975Z" fill="#0063DD" />
                                        <path d="M74.557 13.6975L78.707 16.2075C79.467 16.6675 80.397 15.9875 80.197 15.1275L79.097 10.4075L82.767 7.2275C83.437 6.6475 83.077 5.5475 82.197 5.4775L77.367 5.0675L75.477 0.6075C75.137 -0.2025 73.977 -0.2025 73.637 0.6075L71.747 5.0575L66.917 5.4675C66.037 5.5375 65.677 6.6375 66.347 7.2175L70.017 10.3975L68.917 15.1175C68.717 15.9775 69.647 16.6575 70.407 16.1975L74.557 13.6975Z" fill="#0063DD" />
                                        <path d="M107.557 13.6975L111.707 16.2075C112.467 16.6675 113.397 15.9875 113.197 15.1275L112.097 10.4075L115.767 7.2275C116.437 6.6475 116.077 5.5475 115.197 5.4775L110.367 5.0675L108.477 0.6075C108.137 -0.2025 106.977 -0.2025 106.637 0.6075L104.747 5.0575L99.917 5.4675C99.037 5.5375 98.677 6.6375 99.347 7.2175L103.017 10.3975L101.917 15.1175C101.717 15.9775 102.647 16.6575 103.407 16.1975L107.557 13.6975Z" fill="#0063DD" />
                                        <path d="M140.557 13.6975L144.707 16.2075C145.467 16.6675 146.397 15.9875 146.197 15.1275L145.097 10.4075L148.767 7.2275C149.437 6.6475 149.077 5.5475 148.197 5.4775L143.367 5.0675L141.477 0.6075C141.137 -0.2025 139.977 -0.2025 139.637 0.6075L137.747 5.0575L132.917 5.4675C132.037 5.5375 131.677 6.6375 132.347 7.2175L136.017 10.3975L134.917 15.1175C134.717 15.9775 135.647 16.6575 136.407 16.1975L140.557 13.6975Z" fill="#0063DD" />
                                    </svg>
                                    <p className="landing-page-our-platform__extra-content--text--description">“La aplicación cuenta con un sistema de gamificación asombrosa, te atrapa desde el primer momento.”</p>
                                </div>
                            </div>
                        }
                        inverted />
                </section>
                <section className="landing-page-benefits">
                    <h1 className="landing-page-benefits__title">Beneficios de nuestro modelo
                        de negocio</h1>
                    <div className="landing-page-benefits__cards">
                        <Card type="secondary" className="landing-page-benefits__cards--card">
                            <div className={`circle primary`}></div>
                            <img src={ListBox} alt="Alta tasa de adquisición y retención" width={156} height={156} style={{ zIndex: 3 }} />
                            <span className="landing-page-benefits__cards--card-title">Alta tasa de adquisición y retención</span>
                            <p>Embudo de preselección y selección (6_3_2) con 96% de efectividad para la selección de 2 nuevos distribuidores.</p>
                        </Card>
                        <Card type="secondary" className="landing-page-benefits__cards--card">
                            <div className={`circle primary`}></div>
                            <img src={ScreenTouchHand} alt="Curva de aprendizaje rápida retención" width={156} height={156} style={{ zIndex: 3 }} />
                            <span className="landing-page-benefits__cards--card-title">Curva de aprendizaje rápida retención</span>
                            <p>Requiere inversión en tiempo y dinero en 4 niveles de juego virtuales desde el móvil (de 1  a2 semanas por nivel).</p>
                        </Card>
                        <Card type="secondary" className="landing-page-benefits__cards--card">
                            <div className={`circle primary`}></div>
                            <img src={VictoryHand} alt="Innovación estratégica disruptiva" width={156} height={156} style={{ zIndex: 3 }} />
                            <span className="landing-page-benefits__cards--card-title">Innovación estratégica disruptiva</span>
                            <p>Invertimos la estrategia de conversión y retención, solo pagan el paquete de productos si YA TIENEN 2 conectados.</p>
                        </Card>

                    </div>
                </section>
                <section className="about-us" id="about-us">
                    <LandingHero
                        title={<p style={{ fontSize: '64px', marginTop: '72px' }}>¿Quiénes somos?</p>}
                        background="background"
                        description="En ACDC, somos una empresa dedicada a la innovación tecnológica y a la creación de experiencias digitales únicas. Nuestro viaje comenzó con un grupo de jóvenes soñadores de la ciudad de Cali, apasionados por transformar su entorno y aprovechar las oportunidades de la Revolución Industrial 4.0. Desde nuestros inicios, hemos estado comprometidos con el desarrollo de soluciones que revolucionen la interacción digital, enfocándonos en estrategias de gamificación y contenido audiovisual inmersivo para captar y retener clientes de manera efectiva.
<br />
<br />
Nuestra pasión por la tecnología y el entretenimiento nos ha llevado a construir una plataforma avanzada que refleja nuestro compromiso y esfuerzo. Para llevar a cabo nuestra misión con éxito, contamos con diferentes equipos de trabajo que nos permiten innovar continuamente y ofrecer experiencias excepcionales:

<ul>
<li style='margin-bottom: 14px;'>Célula de producción audivisual</li>
<li style='margin-bottom: 14px;'>Célula de desarrollo de software</li>
<li style='margin-bottom: 14px;'>Célula de procesos estratégicos y de apoyo</li>
</ul>"
                        image={AboutUsImage}
                        imageStyles={{
                            width: '100%',
                            height: !isMobile ? 'auto' : '206px',
                            objectFit: 'contain'
                        }}
                    />
                </section>
                <section className="landing-page-founders" id="founders">
                    <h1 className="landing-page-founders__title">Fundadores</h1>
                    <div className="landing-page-founders__cards">
                        {founders.map((founder, index) => (
                            <Card className="landing-page-founders__cards--card" key={index} type="tertiary">
                                <div className="landing-page-founders__cards--card--image">
                                    <img src={founder.image} alt={founder.name} />
                                </div>
                                <div className="landing-page-founders__cards--card--content">
                                    <h2 className="landing-page-founders__cards--card--content--name">{founder.name}</h2>
                                    <span className="landing-page-founders__cards--card--content--role">{founder.role}</span>
                                    <ul className="landing-page-founders__cards--card--content--social-media--list--icons">
                                        <li className="landing-page-founders__cards--card--content--social-media--list--icons--item">
                                            <a href="#" target="_blank" rel="noopener noreferrer" className="icon-linkedin"></a>
                                        </li>
                                        <li className="landing-page-founders__cards--card--content--social-media--list--icons--item">
                                            <a href="#" target="_blank" rel="noopener noreferrer" className="icon-instagram"></a>
                                        </li>
                                    </ul>
                                </div>
                            </Card>
                        ))}
                    </div>
                </section>
            </main>
            <LandingFooter />
        </div>
    )
}