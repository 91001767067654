import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHandCoordinator from "../../../resources/images/clap-hand-coordinator.png";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useContext, useEffect, useState } from "react";
import { API } from "../../../middleware/utils/constants";
import axios from "axios";
import { UserContext } from "../../../middleware/providers/user-context";
import { ReadingSection } from "../../molecules/reading-section";
import { Quote } from "../../molecules/quote";
import Checkbox from "../../atoms/checkbox";
import Modal from "../../atoms/modal";
import { CardCopyKey } from "../../molecules/card-copy-key";
import KeyHandCoordinator from "../../../resources/images/CoordinatorH3Key.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
const CoordinatorThirddDay = ({
  finishChallenge,
  step,
  setStep,
  challenge,
}) => {
  const { challengeName, idChallenge } = useParams();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  useEffect(() => {
    getCandidatAplicatProgressPoint(user?._id, "day three", 1);
  }, []);
  const [dataQuestionOptions, setDataQuestionOptions] = useState([]);
  
  const [selectedZero, setSelectedZero] = useState(null);
  const saveResponse = () => {
    const data = {
      questions: dataQuestionOptions,
      action: "SAVE_SITUATIONS_TO_CHANGE",
    };

    axios
      .post(
        `${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`,
        data
      )
      .then((response) => {
      
        if (response.status === 201) {
          finishChallenge(null);
          navigate("/missions")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCandidatAplicatProgressPoint = async (idUser, day, opt) => {
    const params = day ? { day } : undefined;
    try {
      const response = await axios.get(
        `${API.PROGRESS_POINT.url}/${idUser}/?action=PROGRESS_POINT`,
        {
          params,
        }
      );
      if (response.status === 200) {
        setDataQuestionOptions(response.data.data[1].questions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (day) => {
    axios
    .put(
      `${API.CHALLENGE_CHANGE.url}/${user?._id}/${user?.role}/${day}`)
    .then((response) => {
      if (response.status === 200) {
       
        finishChallenge(null)
        navigate("/mission")
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <span className="greet">¡Hey!</span>
              <img src={WarningIcon} alt="warning-icon" />
              <Card className="card-warning" type="secondary">
                <Paragraph
                  className="card-warning__text"
                  align="center"
                  size="sx"
                >
                  <b>
                    Este desafío se realiza por fuera de la Plataforma ACDC.
                  </b>
                  <br />
                  <br />
                  Cuando tu Director(a) reciba y confirme <br /> que le enviaste
                  la nota de voz <br /> correspondiente al <b>Desafío A</b>{" "}
                  podrás <br />
                  continuar con tus desafíos diarios.
                </Paragraph>
              </Card>
              <Button onClick={() => finishChallenge(step + 1)}>¡Vale!</Button>
            </div>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el <b>Desafío A.</b>
            </Paragraph>
            <img
              src={ClapHandCoordinator}
              alt="clap-hand-coordinator'"
              className="good-hand"
            />
            <Paragraph
              withBackground={true}
              size="s"
              align="center"
              type="secondary"
              style={{
                marginTop: "40px",
                padding: "35px 27px 21px",
                maxWidth: "289px",
              }}
            >
              Ya puedes continuar con el <b>Desafío B</b> del <br />{" "}
              <b>Día 3.</b>
              <br />
              <br />
              👋🏻 Ya nos vemos.
            </Paragraph>
            <Button onClick={() => navigate("/missions")}>¡Vale!</Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <ReadingSection>
              <Paragraph size="s" align="center">
                <b>Honor</b>
              </Paragraph>
              <Paragraph size="sx" align="left">
                “La palabra honor es raramente usada en nuestros tiempos, parece
                un poco anticuada. Sin embargo, vivir una vida de integridad y
                carácter no tiene épocas y para un líder es absolutamente
                necesario. Se trata de alternativas, las elecciones que toma una
                persona en su vida lo acompañan a la tumba.” “Las palabras de un
                líder y los pasos que da deben coincidir. Como dice el dicho, -
                tu palabra es tu valor y tu valor está en tu palabra -. El
                carácter se desarrolla en los valles y en las cimas de la vida,
                permitiéndole a la persona superar ”
              </Paragraph>
            </ReadingSection>
            <Quote>
              <Paragraph size="s" align="left" style={{ margin: "0" }}>
                Tomado del libro:
              </Paragraph>
              <Paragraph
                size="s"
                align="left"
                style={{
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                Lanzando una Revolución sobre el Liderazgo.
              </Paragraph>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "255px",
              }}
            >
              <b>Actividad selección del paquete y uso de la calculadora.</b>
            </Paragraph>
            <Paragraph align="center" size="sx">
              Si bien en toda la estrategia se ha mostrado los
              <br />
              resultados simulando que todos van a comprar
              <br />
              el paquete de inicio más pequeño ESP 1 de 100 <br />
              puntos que cuesta $200 dólares, les <br /> recomendaremos desde la
              llave de Humildad,
              <br /> que si tienen los recursos, es mejor iniciar con el <br />{" "}
              ESP 2 ó ESP 3, paquetes de inicio que cuestan <br />{" "}
              respectivamente $500 ó 1000 dólares los <br /> cuales dan 250 o
              500 Puntos de Volumen <br /> Comisionalble (CV) dependiendo el que
              ustad
              <br /> compre mas otros beneficios adicionales que
              <br />
              podrás ver en la siguiente pantalla.
            </Paragraph>

            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 3}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              marginTop: "241px",
              height: "450px",
            }}
          >
            <Paragraph align="center" size="sx">
              En la siguiente actividad podrás seleccionar <br />
              los paquetes disponibles del proveedor.
              <br />
              Algunos de estos ya tienen los productos
              <br />
              predefinidos.
            </Paragraph>
            <Paragraph align="center" size="sx">
              Si deseas ver qué contiene cada uno de los
              <br />
              paquetes debes hacer clic en el botón{" "}
              <span style={{ color: "blue" }}>
                {" "}
                Ver <br />
                paquetes.
              </span>
            </Paragraph>
            <Paragraph align="center" size="sx">
              En caso de quieras crear un paquete <br />
              personalizado, selecciona el{" "}
              <b>
                Paquete <br /> Inteligente.
              </b>
              Al darle <b>Continuar</b> tendrás la
              <br />
              posibilidad de calcular el valor exacto para <br />
              conocer cuántos puntos son en total y el <br />
              precio final.
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{ maxWidth: "323px", marginTop: "20px" }}
            >
              ¿Cuáles de los paquetes disponibles del proveedor seleccionado te
              interesa y cuentas con la capacidad para hacer la compra?
            </Paragraph>
            <Button
              style="ghost"
              size="small"
              styles={{
                position: "relative",
                marginTop: "10px",
                bottom: 0,
              }}
            >
              Ver paquetes
            </Button>
            <Paragraph
              size="xs"
              align="center"
              style={{
                marginTop: "20px",
              }}
            >
              Selecciona solo 1 paquete.
            </Paragraph>
            {dataQuestionOptions?.[0]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={[
                  option.package,
                  option.pts,
                  `<span style='text-align: right;font-size: 14px;'>${option.money} <span style='font-size: 14px; opacity: 0.7;'></span></span>`,
                ]}
                checked={option.status=selectedZero===index?true:false}
                labelBackground={true}
                dynamic={true}
                style={{
                  marginTop: "12px",
                  fontSize: "12px",
                }}
                onChange={() => {
                  setSelectedZero(selectedZero === index ? null : index); 
                }}
              />
            ))}
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              disabled={selectedZero!==null?false:true}
            >
              Continuar
            </Button>
          </Step>
         
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>
            <br />
            <img
              src={KeyHandCoordinator}
              alt="good-hand-coordinator"
              style={{ marginTop: "10px" }}
            />
            <CardCopyKey
              phrase="Desafío B Día 3 Coordinador"
              nameChallenge="Desafío B"
            />

            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 6}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              marginTop: "241px",
            }}
          >
            <span className="title">
              <b>¡Conseguiste la Llave de hoy!</b>
            </span>
            <Paragraph align="center" size="sx">
              Esta te da el paso al <b>Día de Filtro.</b>
              <br />
              <br /> Solo te falta completar el <b>Desafío C</b> que <br />
              ya se encuentra habilitado.
            </Paragraph>

            <Button size="small" onClick={() =>saveResponse()}>
              Continuar
            </Button>
          </Modal>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <ReadingSection>
              <Paragraph size="sx" align="left">
              “Los líderes más afectivos a lo largo de la historia han dirigido con sus corazones, en confianza y con honor.

Si un líder se va por los atajos, trata a las personas indebidamente, o no interpreta correctamente la verdad, se enciende una bomba de tiempo. Algún día, en algún lugar, la bomba estallará.”
              </Paragraph>
            </ReadingSection>
            <Quote>
              <Paragraph size="s" align="left" style={{ margin: "0" }}>
                Tomado del libro:
              </Paragraph>
              <Paragraph
                size="s"
                align="left"
                style={{
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                Lanzando una Revolución sobre el Liderazgo.
              </Paragraph>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Vas súper!</b>
              <br />
              <br />
              Te queda poco para completar el Desafío C.
            </Paragraph>
            <img
              src={GoodHandCoordinator}
              alt="good-hand-coordinator"
              width="226"
              height="226"
              style={{
                marginTop: "10px",
              }}
            />
            <CardCopyKey
              phrase="Cierre Día 3 Coordinador"
              nameChallenge="Desafío C"
            />
            <Button
              onClick={() =>  handleChange("filter day")}
              styles={{
                position: "relative",
                marginTop: "26px",
              }}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorThirddDay;
