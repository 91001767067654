import { useState, useEffect, useContext } from "react";
import Paragraph from "../../atoms/paragraph";
import TouchScreen from "../../../resources/images/screen-touch-hand.png"
import Button from "../../atoms/button";
import Step from "../../molecules/step";
//import PortalBridgeTutorial from "../../../resources/images/portal-bridge-tutorial.png";
import ChallengesTutorial from "../../../resources/images/challenges-tutorial.png";
import InfoChallengeTutorial from "../../../resources/images/info-challenge-tutorial.png";
import InfoChallengeTutorialVideoOne from "../../../resources/videos/Video03.mp4";
import GoodHand from "../../../resources/images/good-hand.png";
import InductionDayBBridge from "../../../resources/images/induction-day-b-bridge.png";
import ChallengesTutorialVideoOne from "../../../resources/videos/frhpj_Video02.mp4";
import InductionDayBBridgeVideoOne from "../../../resources/videos/Video01.mp4";
import CopyField from "../../atoms/copy-field";
import Card from "../../atoms/card";

import { getSchedule } from "../../../middleware/services/common";
import { UserContext } from '../../../middleware/providers/user-context';

const InductionDayChallengeB = ({ role, step, setStep, finishChallenge }) => {
    const { user } = useContext(UserContext);

    const [schedule, setSchedule] = useState([]);
    useEffect(() => {
        if (user?._id) {
            fetchData(user?._id)
        }
    }, [user])
    const fetchData = async (userId) => {
        const schedule = await getSchedule(userId);
        setSchedule(schedule);
    };


    //http://localhost:3000/induction-day/b/660f66edd0139945cae36138
    const [finishButton, setFinishButton] = useState(false);

    return (
        <div className="induction-day-challenge-b">
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>Zona de Aprendizaje</b>
                    <br />
                    <br />
                    A continuación, te explicaremos cómo funciona la pestaña de Mi Progreso. En esta pestaña encontrarás diariamente los desafíos que debes realizar.
                </Paragraph>
                <img src={TouchScreen} alt="Touch Screen" />
                <Paragraph size="xs" align="center">
                    Haz clic en <b>Continuar</b>
                    <br />
                    para seguir completando el <b>Desafío B</b>
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    Para iniciar debes familializarte con el Cronograma de las misiones diarias.
                </Paragraph>
              <video
               src={InductionDayBBridgeVideoOne}
               controls
               muted={true}
               loop={true}
               autoPlay
               className="induction-day-b-bridge video-b-bridge"
              >

              </video>
                <Paragraph size="s" align="center" color="">
                    El cronograma está en la  Pestaña Mi Progreso donde encontrarás los Días y las Fechas de cada Misión Diaria las cuales  se establecen con base en la Fecha del Espacio Privado Agendado  (EPA).
                    <br />
                    <br />
                    En la  Pestaña Mi Progreso podrás ver todas las fechas desplazandote de izquierda a derecha en la pantalla hasta llegar al  Día del EPA
                </Paragraph>


                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    Cada día contiene 3 desafíos que deberás ir completando durante el transcurso de estos
                </Paragraph>
                <video
               src={ChallengesTutorialVideoOne}
               controls
               muted={true}
               loop={true}
               autoPlay
               className="challenge-tutorial video-tutorial"
              >
                </video>
                <Paragraph size="xs" align="center">
                    Selecciona el desafío que vas a ralizar y haz clic en el botón Iniciar para comenzar las actividades
                </Paragraph>
                <Paragraph size="s" align="center">
                    Recuerda: Algunos desafíos solo estarán disponibles, cuando completes el 100% de sus actividades.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>Información de Desafíos</b>
                </Paragraph>
                <video
               src={InfoChallengeTutorialVideoOne}
               controls
               muted={true}
               loop={true}
               autoPlay
               className="info-challenge-tutorial video-tutorial"
              >
                </video>
                <Paragraph size="s" align="center">
                    En el botón <span className="icon-info"></span> información que se encuentra frente a cada desafío, podrás ver de forma rápida en qué consiste cada uno.
                </Paragraph>
                <Paragraph size="s" align="center">
                    Además,  información sobre las medallas y claves del desafío.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Te queda poco para completar el <b>Desafío B.</b>
                </Paragraph>
                <img src={GoodHand} alt="Good Hand" className="good-hand" />
                <Card type="secondary" outline>
                    <div className="epa-schedule-card" >
                        <Paragraph align="center" size="s">
                            Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                        </Paragraph>
                        <div className="epa-schedule-card__key">
                            Cierre Desafío B Día de Inducción
                        </div>
                        <CopyField value="Cierre Desafío B Día de Inducción" setStep={() => {
                            setFinishButton(true);
                        }} />
                        <Paragraph align="center" size="xs" padding={16}>
                            Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                            <b>Mi Progreso</b>
                        </Paragraph>
                    </div>
                </Card>
                {finishButton && <Button onClick={() => finishChallenge(null)}>Finalizar</Button>}
            </Step>
        </div>
    );
};

export default InductionDayChallengeB;