import React from "react";
import { ReactComponent as PdlBadge } from "../../../resources/images/pdl-badge.svg";
import { ReactComponent as PdlBageTwo }  from "../../../resources/images/filtro-coordinator-validate-two.svg";
import { ReactComponent as PdlBageThree }  from "../../../resources/images/filtro-coordinator-tree.svg";
import { ReactComponent as PdlBageFive }  from "../../../resources/images/filtro-coordinator-validador-five.svg";

import "./index.scss";
const HostListPdlBadge = ({ hosts }) => {

  return (
    <div className="containr-director-by-host">
      {hosts &&
        hosts.map((host, index) => (
          <div
            key={host._id}
            className="box-director-by-host-one"
            style={{
              borderRight:
                index < hosts.length - 1 ? "solid 4px #35435e" : "none",
            }}
          >
            <div className="container-pdl-host">
              <h3 className="host-by-coordinator-filter-day">{host.name}</h3>
              {host.candidates.map((candidate, idx) => (
                <div key={candidate._id} className="container-host">
                  <div className="text-number-candidate">
                    <span className="text-number-one">{idx + 1}.</span>
                    <span className="text-name-one">{candidate.name}</span>
                  </div>
                  {
                     candidate.personalReferences?.filter((item)=>item.status===true)?.length<=2?(<>
                    <div className="pdl-host">
                    <PdlBageTwo style={{
                    height:'111px',
                    width:'192px'
                  }}/>
                    
                  </div>
                     </>): candidate.personalReferences?.filter((item)=>item.status===true)?.length===3?   <div className="pdl-host">
                    <PdlBageThree style={{
                    height:'111px',
                    width:'192px'
                  }}/>
                    
                  </div>: candidate.personalReferences?.filter((item)=>item.status===true)?.length>=3 && candidate.personalReferences?.filter((item)=>item.status===true)?.length<=5?   <div className="pdl-host">
                    <PdlBageFive style={{
                    height:'111px',
                    width:'192px'
                  }}/>
                    
                  </div>:(<>    <div className="pdl-host"><PdlBadge/></div> </>)
                  }
               
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default HostListPdlBadge;
