import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

import MegaphoneIcon from "../../../resources/images/megaphone.png";
import Info from "../../../resources/images/Info.png";

//director
import SyncDirector from "../../../resources/images/Sinc-AspiranteValidador.png";
import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Genial from "../../../resources/images/clap-captain.png";
import ScreenTools from "../../../resources/images/screen-tools.png";
import DirectorAvatar from "../../../resources/images/Group-director269.png";

//end of director

import Group873 from "../../../resources/images/Contenedor-Tutorial-captain.png";
import Group874 from "../../../resources/images/ContenedorTutorial-Captain2.png";

import LearningZone from "../../../resources/images/Zona-aprendizaje-captain.png";
import GoodHandDirector from "../../../resources/images/good-hand-captain.png";

import CalendarIcon from "../../../resources/images/calendar-icon.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";

import GiftSwitch from "../../molecules/gift-switch";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import { GridGiftSwitch } from "../../molecules/grid-gift-switch";

import { CardCopyKey } from "../../molecules/card-copy-key";

import { Toggle } from "../../atoms/switch";
import { getCaptainAndHostWithAspirantsByDirector } from "../../../middleware/services/useQueryListOfNewHosts";

const CaptainInductionDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);
  const [hostActive, setHostActive] = useState([]);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const putAddToWhatSapp = (idHost, status, e) => {
    let dataUpdate = {
      status: status,
      property: "addedToWhatsappGroup",
    };

    axios
      .put(
        `${API.ADD_TO_WHATSAPP_GROUP.url}/${idHost}?action=update-property-host`,
        dataUpdate
      )
      .then((response) => {
        if (response.status === 200) {
          setHostActive((prev) => {
            if (status) {
              if (!prev.includes(idHost)) {
                return [...prev, idHost];
              }
            } else {
              return prev.filter((host) => host !== idHost);
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [dataDirector, setDataDirector] = useState(null);
  useEffect(() => {
    fetchDataCaptain();
  }, []);

  const fetchDataCaptain = async () => {
    const listDirector = await getCaptainAndHostWithAspirantsByDirector(
      user?._id
    );

    setDataDirector(listDirector);
  };
console.log("challengeName")
console.log(challengeName)
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              ¡Asegura la sincronización del equipo!
            </Paragraph>
            <img src={SyncDirector} alt="sync-aspirante-director" />
            <Paragraph align="center" size="sx">
              Hoy en tu Inducción como Capitán(a), tu primer
              <br />
              desafío es asegurar que los dos equipos de tu
              <br />
              Director(a) hoy estén sincronizados en el Día de
              <b /> Inducción.
            </Paragraph>
            <Paragraph align="center" size="sx">
              La Plataforma validará en automático la
              <br />
              sincronización de los equipos, sin embargo de
              <br />
              ser el caso y aún no esté, comunícate con tu
              <br />
              Director(a) y asegurarte que en el Día de
              <br />
              Inducción sea una prioridad para estos equipos.
            </Paragraph>
            <br />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br/>
            <div className="user-container-hungry">
              <div className="user-card-hungry">
                <div className="user-header-hungry">
                  <img
                    src={DirectorAvatar}
                    alt="Paula Inés"
                    width={80}
                    height={80}
                    className="avatar-hambre"
                  />
                  <div className="user-details-hungry">
                    <span className="user-name-hungry">Flabio</span>
                    <p className="user-role-hungry">Director(a)</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="user-card"
              style={{
                maxWidth: "365px",
                width: "313px",
                height: "61px",
                borderRadius: "15px",
              }}
            >
              <div className="user-info">
                Se encuentra sincronizado(a) con
                <br /> su coordinador(a).
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={false}
                  className="switch-input"
                  //onClick={() => hadlerToggle(true)}
                />
              </div>
            </div>
            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "14px",
                opacity: "0.7",
                marginBottom: "18px",
              }}
            >
              Este switch se activa automáticamente cuando el(la)
              <br />
              Director(a) termine su Desafío A.
            </span>
            <div className="user-container-hungry">
              <div className="user-card-hungry">
                <div className="user-header-hungry">
                  <img
                    src={CoordinatorDirector}
                    alt="Paula Inés"
                    width={80}
                    height={80}
                    className="avatar-hambre"
                  />
                  <div className="user-details-hungry">
                    <span className="user-name-hungry">Diego</span>
                    <p className="user-role-hungry">Coordinador(a)</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="user-card"
              style={{
                maxWidth: "365px",
                width: "313px",
                height: "61px",
                borderRadius: "15px",
              }}
            >
              <div className="user-info">
                Se encuentra sincronizado con
                <br /> sus Anfitriones.
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  //onClick={() => hadlerToggle(true)}
                />
              </div>
            </div>
            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "14px",
                opacity: "0.7",
                marginBottom: "18px",
              }}
            >
              Este switch se activa automáticamente cuando el(la)
              <br />
              Coordinador(a) termine su Desafío A.
            </span>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="226"
              height="226"
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ahora que ya realizaste las actividades, <br />
              continúa con el siguiente ▶️ VIDEO, para <br />
              seguir con tu <b>Inducción</b>.
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Ver video
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={ScreenTools}
              alt="tools"
              width="136"
              height="136"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="sx" align="center">
              Hoy los Coordinadores de los dos equipos de tu
              <br />
              Director(a) están realizando el entrenamiento
              <br />
              ABC Convocatoria con sus respectivos
              <br />
              Anfitriones.
              <br />
              <br />
              A continuación, la Plataforma en automático te
              <br />
              confirmará los Coordinadores que han
              <br />
              completado el Entrenamiento.
            </Paragraph>
            <Paragraph withBackground={true} size="sx" align="center">
              <img src={Info} alt="tools" width="32" height="32" />
              <br />
              Tu desafío es confirmar que tanto los Coordinadores como los
              Anfitriones hayan consultado las tres claves completas a TWOTBOT.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />

            <div className="user-container-hungry">
              <div className="user-card-hungry">
                <div className="user-header-hungry">
                  <img
                    src={CoordinatorDirector}
                    alt="Paula Inés"
                    width={63}
                    height={63}
                    className="avatar-hambre"
                  />
                  <div className="user-details-hungry">
                    <span className="user-name-hungry">
                      Luis Carlos Sarmiento Angulo
                    </span>
                    <p className="user-role-hungry">Coordinador(a)</p>
                  </div>
                  <div
                    className="gift-switch-captain"
                    style={{
                      width: "96px",
                      background: "#0f1b2b",
                      display: "flex",
                      height: "37px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "17px",
                    }}
                  >
                    <div
                      className={`content-buy ${"wait"}`}
                      style={{
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <img
                        src={CalendarIcon}
                        alt={"dd"}
                        style={{ width: "20px" || "30px" }}
                      />
                      <span
                        className={`${"icon-state-selected"} icon`}
                        style={{
                          color: "#09e609",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Paragraph align="center" size="sx">
              Tu Coordinador(a)
              <span
                style={{
                  color: "#09e609",
                }}
              >
                {" "}
                ya se encuentra agendado
              </span>
              <br />
              como parte del Entrenamiento ABC
              <br />
              Convocatoria de sus Anfitriones.
            </Paragraph>
            <div className="user-container-hungry">
              <div className="user-card-hungry">
                <div className="user-header-hungry">
                  <img
                    src={DirectorAvatar}
                    alt="Paula Inés"
                    width={85}
                    height={85}
                    className="avatar-hambre"
                  />
                  <div className="user-details-hungry">
                    <span className="user-name-hungry">
                      Flabio Hinestroza Caicedo
                    </span>
                    <p className="user-role-hungry">Director(a)</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="user-card"
              style={{
                maxWidth: "365px",
                width: "313px",
                height: "61px",
                borderRadius: "15px",
              }}
            >
              <div className="user-info">
                Se encuentra sincronizado con
                <br /> su Coordinador(a).
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  //onClick={() => hadlerToggle(true)}
                />
              </div>
            </div>

            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "12px",
                opacity: "0.7",
                marginTop: "0px",
              }}
            >
              Este switch se activa automáticamente cuando el(la)
              <br /> Director(a) termine su Desafío B.
            </span>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ya puedes continuar con el<b> Desafío C</b> del Día de{" "}
              <b>Inducción.</b>
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img src={MegaphoneIcon} alt="megaphone" width="204" height="204" />
            <Paragraph size="sx" align="center">
              ¡Capitán(a)!
              <br />
              <br />
              Recuerda que hoy los Directores están atentos a<br />
              la aplicación del Plan B que consiste en que los Anfitriones
              apliquen el ABC Filtro, lo que permitirá a los Anfitriones de los
              dos Coordinadores tener nuevos Validadores en la Plataforma hoy
              mismo.
            </Paragraph>
            <Paragraph size="sx" align="center">
              Estos nuevos validadores podrán ser
              <br />
              convocados y agendados al EPA hasta el Día de Reglas del Juego.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Zona de Aprendizaje.</b>
              <br />
              (Mis Directores)
            </Paragraph>
            <Paragraph align="center" size="sx">
              A continuación te recordamos cómo puedes
              <br /> acceder a la información y desempeño de todo
              <br /> tu equipo: Directores, Coordinadores,
              <br /> Anfitriones y Aspirantes.
            </Paragraph>
            <img
              src={LearningZone}
              alt="learning zone"
              width="250"
              height="250"
            />
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              styles={{
                position: "relative",
                marginTop: "75px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Dirígete a la pestaña <b>Mis Directores</b> en el<br/>
              menú que encuentras en la parte superior.
            </Paragraph>

            <img src={Group873} alt="group-873" widh="343" height="124" />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Luego encontrarás a tus Directores, dando clic<br/>
              en alguno de ellos podrás acceder a sus<br/>
               Coordinadores, también a sus Anfitriones y a los Aspirantes de dichos Anfitriones.
            </Paragraph>
            <img src={Group874} alt="group-874" widh="343" height="215" />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b> 
            </Paragraph>
            <Paragraph size="s" align="center" style={{ maxWidth: "364px" }}>
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandDirector}
              alt="good-hand-director"
              widh="226"
              height="226"
            />
            <CardCopyKey
              phrase="Desafío C Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CaptainInductionDay;
