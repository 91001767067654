import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Book from "../../../resources/images/book.png";
import IconTools from "../../../resources/images/icon-tools.png";
import IconLens from "../../../resources/images/icon-lens.png";
import IconHeart from "../../../resources/images/icon-heart.png";
import IconBulb from "../../../resources/images/icon-bulb.png";
import IconMicrophone from "../../../resources/images/icon-microphone.png";
import IconShield from "../../../resources/images/icon-shield.png";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import KeyH2 from "../../../resources/images/key-h2-host.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import ImageButton from "../../atoms/image-button";
import { useContext, useState } from "react";
import TextArea from "../../atoms/text-area";
import CopyField from "../../atoms/copy-field";
import { API, IMAGE_OPTIONS } from "../../../middleware/utils/constants";
import axios from "axios";
import { UserContext } from "../../../middleware/providers/user-context";

const HostSecondDay = ({ finishChallenge, step, setStep, challenge }) => {
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const [selectedOption, setSelectedOption] = useState(null);
    const [challengeState, setChallengeState] = useState(challenge);

    const saveResponse = () => {
        const data = {
            questions: challengeState.questions,
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        };
        
        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                finishChallenge(null);
                 navigate('/missions')
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío inicia por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                Cuando tu Coordinador(a) reciba y confirme que le enviaste la nota de voz correspondiente al <b>Desafío A</b> podrás continuar con tus desafíos diarios.
                            </Paragraph>
                        </Card>
                        <Button onClick={() => finishChallenge(step + 1)}>¡Vale!</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío A.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ya puedes continuar con el <b>Desafío B</b> del <b>Día 2.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => navigate('/missions')}>¡Vale!</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-second-day-container">
                    <Paragraph size="s" align="center">
                        Cuando estás <b>aprendiendo</b> algo nuevo, te consideras <b>MÁS</b> una persona:
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        (Selecciona 1 de las opciones, la que más aplique).
                    </Paragraph>
                    <div className="options-image">
                        {challengeState?.questions?.[1]?.options?.map((option) => {
                            return (
                                <ImageButton
                                    src={IMAGE_OPTIONS[option.image]?.image}
                                    alt={IMAGE_OPTIONS[option.image]?.alt}
                                    style="gradient"
                                    label={IMAGE_OPTIONS[option.image]?.label}
                                    opacity={option.status ? 1 : 0.5}
                                    onClick={(e) => {
                                        const newChallengeState = { ...challengeState };
                                        newChallengeState.questions[1].options.forEach((o) => {
                                            o.status = false;
                                        });
                                        option.status = true;
                                        setChallengeState(newChallengeState);
                                        setSelectedOption(option.image);
                                    }} />
                            );
                        })}
                    </div>
                    <Button onClick={() => setStep(step + 1)} disabled={!selectedOption}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-second-day-container">
                    <Card type="secondary" outline className="host-second-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                ✒️ "El crecimiento personal es interno, está localizado en lo más profundo del ser.
                                <br />
                                Convertirse en líder requiere de esfuerzo y paciencia que resulta de una mejoría que el mundo externo todavía no puede ver. Las ganancias son internas, se encuentran dentro del corazón ❤️”.
                                <br />
                                <br />
                                "El liderazgo es la manera en que las personas productivas extienden su capacidad a través del esfuerzo de otros para mejorar a todos los involucrados.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-second-day-container-button">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-second-day-container">
                    <Card type="secondary" outline className="host-second-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                <h3>Humildad</h3>
                                “Para un líder, la educación no tiene final. Necesitamos vivir como si fuéramos a morir mañana y aprender como si fuéramos a vivir para siempre. Cuando un líder se sigue permitiendo aprender, su potencial no tiene límites.
                                Lo opuesto de arrogancia es humildad. ¿Con quién debe ser humilde el líder? La respuesta: con cualquiera que tenga algo que enseñarle.
                                Más importante, esto significa ser humilde con los mentores. Segundo, significa ser humilde con los compañeros y finalmente significa actuar de manera humilde hacia los subordinados.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-second-day-container-button">Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-second-day-container">
                    <Paragraph size="s" align="center">
                        {challengeState?.questions?.[0]?.question}
                    </Paragraph>
                    <TextArea
                        placeholder="Escribe aquí"
                        maxLength={280}
                        onChange={(e) => {
                            const newChallengeState = { ...challengeState };
                            newChallengeState.questions[0].text = e.target.value;
                            setChallengeState(newChallengeState);
                        }}
                    />
                    <Paragraph size="xs" align="center">
                        Escribe entre 180 y 280 caracteres.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={challengeState?.questions[0].text?.length < 180 || challengeState?.questions[0].text?.length > 280}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="host-second-day-container">
                    <Paragraph align="center" size="s">
                        <b>¡Genial!</b>
                        <br />
                        Has completado el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={KeyH2} alt="Key H2" />
                    <Card type="secondary" >
                        <Paragraph align="center" size="s">
                            <b>¡Conseguiste la Llave de hoy!</b>
                            <h3>Llave H2 (Humildad)</h3>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Esta te da el paso al <b>Día 3.</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Ahora solo falta que completes el Desafío C, ¡ya puedes iniciarlo!
                        </Paragraph>
                    </Card>
                    <Button onClick={saveResponse}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Cierre Día 2 Anfitrión
                            </div>
                            <CopyField value="Cierre Día 2 Anfitrión" setStep={() => {
                                console.log('setStep');
                                finishChallenge(null);
                                navigate('/missions')
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                </Step>
            </>}
        </>
    );
}

export default HostSecondDay;