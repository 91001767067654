import Card from "../../atoms/card";
import MenuUserInfo from "../menu-user-info";

export const UserDirectorCardInfo = ({ user }) => {
    const isActive = user?.trainingList?.filter((training) => training.active).length >= 3;
    const TrainingContent = ({ items }) => {
        return (
            <div className="training-content">
                <span className="training-content__title"></span>
                {items.map((item) => (
                    <span className={`training-content__item icon-grade ${item.active ? 'active' : 'inactive'}`}></span>
                ))}
            </div>
        )
    };
    return (
        <Card className={`user-card-info `} type="quinary"
        style={{
            width: "280px"
        }}
        >
            
            <MenuUserInfo
                name={user?.name}
                role={user?.role}
                nameRole={user?.nameRole}
                range={user?.range}
                orientation="row"
                size="small"
                style={{
                    gap:"23px",
                }}
             
            >
                <TrainingContent items={user?.trainingList} />
            </MenuUserInfo>
        </Card>
    )
};