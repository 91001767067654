import { useState } from "react";
import { LandingHeaderLayout } from "../../templates/landing-header-layout";
import LandingLogo from "../../../resources/images/landing-logo.png";

export const LandingHeader = () => {

    const [activeLink, setActiveLink] = useState('');

    const handleNavClick = (e) => {
        e.preventDefault();
        const target = e.target.getAttribute('href');
        const targetElement = document.querySelector(target);
        targetElement?.scrollIntoView({ behavior: 'smooth' });
        setActiveLink(target);
    };

    return (
        <LandingHeaderLayout>
            <div className="landing-header-logo">
                <img src={LandingLogo} alt="Logo" />
            </div>
            <nav className="landing-header-nav">
                <ul className="landing-header-nav--list">
                    <li className={`landing-header-nav--list--item ${activeLink === '#about-us' ? 'active' : ''}`}>
                        <a href="#about-us" className="landing-header-nav--list--item--link" aria-current="page" onClick={handleNavClick}>¿Quiénes somos?</a>
                    </li>
                    <li className={`landing-header-nav--list--item ${activeLink === '#contact-us' ? 'active' : ''}`}>
                        <a href="#contact-us" className="landing-header-nav--list--item--link" aria-current="page" onClick={handleNavClick}>Contáctanos</a>
                    </li>
                </ul>
            </nav>
        </LandingHeaderLayout >
    )
};