import { useLocation, useNavigate } from 'react-router-dom';
import { API, indexes, ROLES } from '../../../middleware/utils/constants';
import Button from '../../atoms/button';
import PortalBridge from '../../atoms/portal-bridge';
import Challenge from '../../molecules/challenge';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../middleware/providers/user-context';
import axios from 'axios';
import ChallengeModal from '../../molecules/challenge-modal';
import Card from '../../atoms/card';
import Stations from '../../../resources/images/stations.png';
import EpaStation from '../../../resources/images/epa-station.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import Paragraph from '../../atoms/paragraph';
import Modal from '../../atoms/modal';
import { getProgressPoint, getSchedule } from '../../../middleware/services/common';
import { formatDateLong } from '../../../middleware/utils/formatDate';

const MyProgress = ({ selector, day, refresh, role }) => {
    const { user, login } = useContext(UserContext);
    const navigate = useNavigate();

    const location = useLocation();

    const [moveSelector, setMoveSelector] = useState(selector);
    const [dayName, setDayName] = useState('');
    const [challenges, setChallenges] = useState([]);
    const [activeChallenge, setActiveChallenge] = useState(null);
    const [challengeOpen, setChallengeOpen] = useState(false);
    const [challengeModalContent, setChallengeModalContent] = useState({});
    const [countValidators, setCountValidators] = useState(0);
    const [epaStartFlag, setEpaStartFlag] = useState(false);
    const [stateRefresh, setStateRefresh] = useState(false);
    const [initEpaModal, setInitEpaModal] = useState(false);
    const [keyDay, setKeyDay] = useState('');

    const [schedule, setSchedule] = useState(null);

    const fetchData = async () => {
        const schedule = await getSchedule(user?._id);
        setSchedule(schedule);
        const progressPoint = await getProgressPoint(user?._id, keyDay, user?.role);
        setChallenges(progressPoint);
    };

    useEffect(() => {
        axios.get(`${API.PROGRESS_POINT.url}`).then((response) => {
            const data = response.data.data;
           
            if (data?.message !== 'This user has no challenges yet') {
                setChallenges(data);
                //setDayName(data[0]?.nameDay);
                setEpaStartFlag(data[0]?.epaStartFlag);
                const active = data?.find((challenge, index) => {
                    if (data[index - 1]?.status && !challenge?.status) {
                        return challenge;
                    }
                }) || data[0];

                setKeyDay(active?.day);

                if (active?.day === 'filter day' && user?.role === 'candidate' && active?.nameChallenge === 'Desafío C') {
                    setCountValidators(user?.personalReferences?.filter((reference) => reference?.sent)?.length);
                }

                setActiveChallenge(active);
            }
        }).catch((error) => {
            console.log(error);
            setChallenges([]);
        });
        if (location.state?.refresh) {
            login();
        }
    }, [refresh, location.state, stateRefresh]);

    useEffect(() => {
        const defaultObject = {
            ...ROLES[user?.role]?.challenges,
            [activeChallenge?.day]: [
                {
                    position: ROLES[user?.role]?.challenges[activeChallenge?.day]?.[0]?.position,
                    nameDay: day
                }
            ]
        };

        Object.entries(defaultObject).forEach(([key, value]) => {
            const position = value[0].position === moveSelector;
            if (position) {
                setDayName(value?.[0]?.nameDay);
                setKeyDay(key);
            }
        });

    }, [moveSelector]);

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user, keyDay, day]);


        const openChallengeModal = (challenge, index) => {
            const icon = ROLES[user?.role]?.challenges?.[activeChallenge?.day]?.[index]?.icon;
          
            const itemsMedal = challenge?.description.filter((item) => item?.medal);
            const challengeModal = {
                icon: icon,
                name: challenge?.nameChallenge,
                items: challenge?.description?.map((item, indexItem) => {
                    return item?.activity && {
                        icon: item?.icon,
                        text: item?.activity
                    }
                }),
                itemsMedal: itemsMedal.map((item, indexItem) => {
                    return item?.medal && {
                        icon: item?.icon,
                        text: item?.medal
                    };
                }),
            };

            setChallengeModalContent(challengeModal);
            setChallengeOpen(true);
        };

        const loadProgress = (challenge) => {
            if (challenge?.day === 'filter day' && user?.role === 'candidate' && challenge?.nameChallenge === 'Desafío C') {
                const percentage = Math.round(countValidators * 100 / 6);
                return percentage > 100 ? 100 : percentage;
            }

            return challenge?.status ? 100 : 0;
        };

        const finishChallenge = (step) => {
            axios.put(`${API.CHALLENGE_COMPLETED.url}/${user?._id}/${activeChallenge.idChallenges}`).then((response) => {
                if (response.status === 200) {
                    console.log('Challenge completed');
                }
            }).catch((error) => {
                console.log(error);
            });
        };


        return <>
            <div className="info-section-bridge">
                <h2>{day}</h2>
                {activeChallenge?.day !== 'end of epa' && !epaStartFlag && <PortalBridge id="day-rules" role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role} selector={selector} setSelector={setMoveSelector}>
                    {schedule?.map((day, index) => <span key={day.date} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                </PortalBridge>}
                {activeChallenge?.day === 'end of epa' && <>
                    <img src={Stations} alt="" />
                    <Paragraph className="eighth">{formatDateLong(schedule?.[schedule.length - 1]?.date)}</Paragraph>
                </>}
                {activeChallenge?.day === 'epa day' && epaStartFlag && <>
                    <img src={EpaStation} alt="" />
                    <Paragraph className="eighth">{formatDateLong(schedule?.[schedule.length - 1]?.date)}</Paragraph>
                </>}
            </div>
            {challenges?.map((challenge, index) => {
                return index < 3 && <Challenge
                    key={index}
                    name={challenge?.nameChallenge}
                    icon={ROLES[user?.role]?.challenges[activeChallenge?.day]?.[index]?.icon}
                    progress={loadProgress(challenge)}
                    role={role?.indexOf('new') > -1 ? role?.split(' ')?.[1] : role}
                    active={activeChallenge?.idChallenges === challenge?.idChallenges}
                    onClick={() => openChallengeModal(challenge, index)}
                    disabled={challenge?.day === 'epa day' && !epaStartFlag}
                />
            })}
            <Button role={role?.indexOf('new') > -1 ? role?.split(' ')?.[1] : role} id="init" onClick={() => {
                if (activeChallenge?.day === 'epa day' && !epaStartFlag) {
                    setInitEpaModal(true);
                } else if (user?.role !== 'candidate') {
                    navigate(`/challenge/${activeChallenge?.nameChallenge}/${activeChallenge?.idChallenges}`);
                } else if (activeChallenge?.day !== 'induction day' && user?.role === 'candidate') {
                    navigate(`/challenge/${activeChallenge?.nameChallenge}/${activeChallenge?.idChallenges}`);
                } else if (activeChallenge?.day === 'induction day' && user?.role === 'candidate' && challenges?.[0]?.status && !challenges?.[1]?.status) {
                    navigate(`/induction-day/b/${activeChallenge?.idChallenges}`);
                } else if (activeChallenge?.day === 'induction day' && user?.role === 'candidate' && challenges?.[1]?.status && !challenges?.[2]?.status) {
                    navigate(`/induction-day/c/${activeChallenge?.idChallenges}`);
                }
            }} disabled={keyDay !== activeChallenge?.day}>{activeChallenge?.nameChallenge !== 'Cambio de Rol' ? 'Iniciar' : 'Cambio de Rol'}</Button>
            <ChallengeModal
                icon={challengeModalContent?.icon}
                name={challengeModalContent?.name}
                isOpen={challengeOpen}
                onClose={() => setChallengeOpen(false)}
            >
                <Card type="quinary">
                    <div className="card-list">
                        {challengeModalContent?.items?.map((item, index) => item && <div className="item" key={item.icon+index}>
                            <span className={`icon ${item?.icon}`}></span>
                            <div className="item--text">{item?.text}</div>
                        </div>)}
                    </div>
                </Card>

                {challengeModalContent?.itemsMedal?.length > 0 && <Card type="quinary">
                    <div className="card-list medals">
                        {challengeModalContent?.itemsMedal?.map((item, index) => item?.icon && <div className="item" key={item.icon+index}>
                            <span className={`icon ${item?.icon}`}></span>
                            <div className="item--text">{item?.text}</div>
                        </div>)}
                    </div>
                </Card>}
            </ChallengeModal>
            <Modal isOpen={countValidators >= 6} className="confirmation-register-modal" style={{
                height:"500px"
            }}>
                <div className="confirmation-register-container">
                    <div className="light">
                        <img src={ClapHand} alt="Clap Candidate" />
                    </div>
                    <Paragraph align="center" size="s">
                        ¡Genial!
                        <br />
                        Has completado el <b>Desafío C.</b>
                        <br />
                        <br />
                        <b>¡Conseguiste enviar 6 correos!</b>
                        <br />
                        <br />
                        Esto te da el paso al <b>Día de Alineamiento</b>.
                        <br />
                        <br />
                        <b>👋🏻  Ya nos vemos.</b>
                    </Paragraph>
                    <Button size="small" onClick={() => {
                        setCountValidators(0);
                        finishChallenge(null);
                    }}>¡Vale!</Button>
                </div>
            </Modal>
            <Modal isOpen={initEpaModal} className="game-over-confirm small" >
                <div className="confirmation-register-container">
                    <span className="title">¿Estás seguro(a) que deseas iniciar el EPA?</span>
                    <Paragraph align="center" size="xs">
                        Una vez te teletransportes a la nave del EPA no podrás regresar al puente portal
                    </Paragraph>
                    <div className="buttons">
                        <Button size="small" onClick={() => {
                            axios.put(`${API.EPA_START_FLAG.url}/${user?._id}`).then((response) => {
                                if (response.status === 200) {
                                    setInitEpaModal(false);
                                    setStateRefresh(!stateRefresh);
                                }
                            }).catch((error) => {
                                console.log(error);
                            });
                        }}>Sí</Button>
                        <Button size="small" onClick={() => setInitEpaModal(false)}>No</Button>
                    </div>
                </div>
            </Modal>
        </>;
    };

    export default MyProgress;