import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import MessageIcon from "../../../resources/images/message-icon.png";
import { API, MESSAGES, ROLES } from "../../../middleware/utils/constants";
import axios from "axios";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarActive from "../../../resources/images/star-active.png";
import StarInactive from "../../../resources/images/star-inactive.png";
import { ReactComponent as EmptyBadge } from "../../../resources/images/empty-badge.svg";
import { CounterBadges } from "../../molecules/counter-badges";
import MenuUserInfo from "../../molecules/menu-user-info";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

import CandidateBlack from "../../../resources/images/candidate_black.png";
import MenuReferenceInfo from "../../molecules/menu-reference-info";
import TextField from "../../atoms/text-field";
import { QueriesUsersBelowBydId } from "../../../middleware/services/useQueryUsersBelowCoordinatot";
const MyDirectorValidatorsTab = ({
  idChallenge,
  activeChallenge,

  children,
}) => {
  const { user, setUser, setNewTitleReference } = useContext(UserContext);

  const navigate = useNavigate();

  const { index, update } = useParams();

  const [validatorsWithMedals, setValidatorsWithMedals] = useState([]);
  const [aspirantsWithTraining, setAspirantsWithTraining] = useState([]);
  const [counterValidations, setCounterValidations] = useState(0);
  const [personalReferences, setPersonalReferences] = useState([]);
  const [auxActiveChallenge, setAuxActiveChallenge] = useState({});
  const [hostData, setHostData] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const [tempStatus, setTempStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [reference, setReference] = useState("");
  const [validator, setValidator] = useState({});
  const [path, setPath] = useState("");
  const [coordinator, setCoordinatot] = useState([
    {
      id: "",
      name: "",
      role: "",
      host: [{ host_id: "", host_name: "", host_role: "" }],
    },
  ]);
  const { data } = QueriesUsersBelowBydId(user?._id);
  console.log("data for");
  console.log(data);
  //  const isShow = ROLES[user?.role]?.challenges[challenge?.day]?.[0]?.isShowValidatorForm;

  useEffect(() => {
    setPath(window.location.pathname.split("/")[1]);
    if (index && user) {
      setValidator(user?.personalReferences[index - 1]);
    }
  });

  const renderStatus = () => {
    if (user.personalReferences.status) {
      return <span className="status validated">Validado</span>;
    } else if (reference?.sent || tempStatus) {
      return <span className="status sent">Esperando respuesta</span>;
    } else {
      return <span className="status no-sent">No enviado</span>;
    }
  };

  const sendEmail = () => {
    if (email?.length > 0) {
      setLoader(true);
      axios
        .post(
          `${API.SEND_VALIDATION_EMAIL.url}/${user?._id}/${encodeURIComponent(
            email
          )}/${reference.token}/${idChallenge}`
        )
        .then((response) => {
          if (response.status === 201) {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.warn(error);
          if (error.response.status === 400) {
            alert(MESSAGES.ERROR[error.response.data.message]);
            setTempStatus(true);
            setLoader(false);
          }
        });
    }
  };

  const renderStarBadge = (personalReference, index, personalReferences) => {
    let counter = 0;
    personalReferences?.filter((p) => {
      if (p.status) {
        counter++;
      }
    });
    if (index < 5)
      return counter === 6 && personalReference.status ? (
        <img src={StarActive} alt="star active" />
      ) : (
        <img src={StarInactive} alt="star inactive" />
      );
  };

  const renderScheduled = (personalReference, index) => {
    if (index < 6)
      return personalReference.processStatus === "scheduled" ? (
        <span className="number-badge">
          <EmptyBadge opacity={1} />
          <span>{index + 1}</span>
        </span>
      ) : (
        <span className="number-badge" style={{ opacity: 0.5 }}>
          <EmptyBadge />
          <span>{index + 1}</span>
        </span>
      );
  };

  useEffect(() => {
    setCounterValidations(
      user?.personalReferences?.filter(
        (personalReference) =>
          personalReference.status && personalReference.role !== "host"
      ).length
    );
    axios
      .get(
        `${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`
      )
      .then((response) => {
        if (response.status === 200) {
          setAspirantsWithTraining(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [update, location.state]);

  useEffect(() => {
    axios
      .get(`${API.GET_INFORMATION.url}`)
      .then((response) => {
        if (response?.status === 200) {
          const personalReferences = response.data.data?.personalReferences
            .sort((a, b) => {
              if (
                a.processStatus === "registered" &&
                b.processStatus !== "registered"
              ) {
                return -1;
              }
              if (
                a.processStatus !== "registered" &&
                b.processStatus === "registered"
              ) {
                return 1;
              }
              if (
                a.processStatus === "scheduled" &&
                b.processStatus !== "scheduled"
              ) {
                return -1;
              }
              if (
                a.processStatus !== "scheduled" &&
                b.processStatus === "scheduled"
              ) {
                return 1;
              }
              return 0;
            })
            .map((personalReference, index) => {
              return personalReference.training === "candidate"
                ? {
                    ...personalReference,
                    newIndex: index,
                  }
                : personalReference;
            });
          setPersonalReferences(personalReferences);
          const updateUser = {
            ...user,
            personalReferences: personalReferences,
          };
          setUser(updateUser);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [update, location.state]);
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(
        `${API.LIST_OF_NEW_HOSTS.url}/66f41bb3153e348af6752de3?action=LIST_OF_NEW_HOSTS`
      )
      .then((response) => {
        console.log("response: flabio ");
        console.log(response.data.data);
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelReference = (data) => {
    console.log("Back clicked");
    console.log(data);
    setReference(data);
  };
console.log("aspirantsWithTraining")
console.log(aspirantsWithTraining)
  console.log("reference flabio");
  console.log(hostData);

  return (
    <div className="my-validators-tab">
      {reference === "" && (
        <>
          {user && user?.role === "director" && (
            <>
              <Button
                onClick={() => {
                  navigate(`/register-reference/new/${idChallenge}`);
                  setNewTitleReference("Agregar Referencia");
                }}
                styles={{
                  marginTop: "20px",
                }}
              >
                Agregar Referencia
              </Button>
            </>
          )}

          {data &&
            data
              ?.filter((r) => r.role === "coordinator")
              ?.map((host, index) => {
                const candidatesScheduled = host.candidates?.length || 0;
                const suggestedCandidates = Math.max(
                  6 - candidatesScheduled,
                  0
                );

                return (
                  <div>
                    <div
                      className={`validator-item `}
                      style={{
                        width: "104%",
                      }}
                    >
                      <div
                        className={`validator-item__border ${"coordinator"}`}
                        style={{
                          opacity: user?.personalReferences?.[index].status
                            ? 1
                            : 0.5,
                        }}
                      ></div>
                      <div className={`validator-item__content coordinator}`}>
                        {/* <span className="index">{index + 1}</span> */}
                        <span
                          className={`icon-chevron-back icon right ${"coordinator"}`}
                          style={{
                            marginTop: "45px",
                          }}
                        ></span>
                        <MenuUserInfo
                          name={host.name}
                          role="coordinator"
                          nameRole="Coordinador(a)"
                          range={7}
                          icon={
                            user?.role === "coordinator" && (
                              <span className="icon-recognition"></span>
                            )
                          }
                          headingAlign={"left"}
                        ></MenuUserInfo>

                        <br />
                        <br />
                        <Paragraph
                          size="s"
                          align="left"
                          style={{
                            marginTop: "-15px",
                          }}
                        >
                          ⚪ Alicia Martinez Morales
                        </Paragraph>
                        <CounterBadges
                          items={Array(6)
                            .fill(0)
                            .map((_, index) => ({
                              isActive: index < candidatesScheduled,
                            }))}
                          style={{
                            marginBottom: "0px",
                          }}
                        />
                        <br />
                        <br />
                        <Paragraph
                          size="s"
                          align="left"
                          style={{
                            marginTop: "-15px",
                          }}
                        >
                          ⚪ Juan Pablo García Sotomayor
                        </Paragraph>
                        <CounterBadges
                          items={Array(6)
                            .fill(0)
                            .map((_, index) => ({
                              isActive: index < 4,
                            }))}
                          style={{
                            marginBottom: "0px",
                          }}
                        />
                      </div>
                      <br />
                    </div>
                  </div>
                );
              })}
          <div
            style={{
              marginRight: " 0px",
              width: "370px",
            }}
          >
            <div
              className={`validator-item `}
              style={{
                width: "104%",
              }}
            >
              <div
                className={`validator-item__border ${"captain"}`}
                style={{
                  opacity: 1,
                }}
              ></div>
              <div className={`validator-item__content coordinator}`}>
                <MenuUserInfo
                  name="Flabio Hinestroza"
                  role="captain"
                  nameRole="Capitan(a)"
                  range={7}
                  headingAlign={"left"}
                ></MenuUserInfo>
                <br />
                <Paragraph
                  size="s"
                  align="left"
                  style={{
                    marginTop: "-15px",
                  }}
                ></Paragraph>
              </div>
              <span
                className={`icon-chevron-back icon right ${"captain"}`}
              ></span>
              <br />
            </div>
          </div>
          {personalReferences
            ?.filter((candidate) => candidate.processStatus === "earring")
            ?.map((personalReference, index) => {
              const personalReferenceArray = Array.isArray(personalReference)
                ? personalReference
                : Object.values(personalReference);
              return (
                <>
                  <div>
                    <div
                      className={`validator-item `}
                      style={{
                        width: "382px",
                        marginLeft: "15px",
                      }}
                    >
                      <div
                        className={`validator-item__border ${"candidate"}`}
                        style={{
                          opacity: personalReferenceArray?.some(
                            (item) => item.status === true
                          )
                            ? 1
                            : 0.5,
                        }}
                      ></div>
                      <div className={`validator-item__content candidate}`}>
                        <span className="index">
                          {hostData?.length + index + 1}
                        </span>
                        <MenuReferenceInfo
                          name={personalReference.name}
                          role="candidate"
                          nameRole="Validar(a)"
                          range={7}
                          headingAlign={"left"}
                        >
                          <span
                            className={` icon-recognition  ${"candidate"}`}
                          ></span>
                        </MenuReferenceInfo>
                      </div>
                      <span
                        className={`icon-chevron-back icon right ${"candidate"}`}
                        onClick={() => {
                          handelReference(personalReference);
                        }}
                      ></span>
                      <br />
                    </div>
                  </div>
                </>
              );
            })}

          {children}
          <span
            className="icon-info"
            style={{
              display: "flex",
              alignItems: " center",
              justifyContent: "center",
              backgroundColor: "$blue-01",
              borderRadius: "50%",
              fontSize: "32px",
              width: "32px",
              height: "32px",
              marginTop: "10px",
            }}
          ></span>
        </>
      )}
      {reference !== "" && (
        <>
          <div
            className={`menu-user-info menu-user-info `}
            style={{
              marginTop: "24px",

              flexDirection: "column",
            }}
          >
            <div className={`menu-user-info-avatar progress-${7}-8 `}>
              <img
                src={CandidateBlack}
                alt="candidate"
                style={{
                  marginTop: "-10px",
                  marginLeft: "-12px",
                  width: "129px",
                }}
              />
            </div>
            <br />
            <div className="menu-user-info-title">
              <h4 className={`heading center`}>{reference.name}</h4>
              <span>Validador(a)</span>
            </div>
          </div>
          <div className="validator-info__form">
            <span className="title">Correo de validación</span>
            <TextField
              name="email"
              placeholder="Ingresa aquí su correo electrónico"
              leftIcon={{ icon: "icon-mail" }}
              value={email}
              pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {renderStatus()}
            <Paragraph size="xs" align="center">
              Enviaremos la solicitud de validación al correo personal de tu
              referencia.
            </Paragraph>
            <Button
              size="small"
              disabled={reference.status || tempStatus}
              onClick={sendEmail}
            >
              Enviar
            </Button>
          </div>
          <span
            className="title with-image"
            style={{
              display: "flex",
              /* justify-content: space-between; */
              /* background: red; */
              borderRadius: "15px",
              width: "211px",
            }}
          >
            <img
              src={MessageIcon}
              alt="message-icon"
              className="message-icon"
            />
            <span
              className="what-title"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              ¿Qué dijo de mí?
            </span>
          </span>
          <div className="validator-info__what-think-about-me">
            Aún no te han validado
          </div>
        </>
      )}
    </div>
  );
};

export default MyDirectorValidatorsTab;
