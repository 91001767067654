import React, { useState, useRef } from "react";

const DragSelect = ({ children, callback }) => {
    const [items, setItems] = useState(
        React.Children.toArray(children).map((child, index) => ({
            id: index + 1,
            content: child,
        }))
    );

    const draggedItemRef = useRef(null);

    const handleDragStart = (id) => {
        draggedItemRef.current = id;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (droppedId) => {
        if (draggedItemRef.current === null || draggedItemRef.current === droppedId) return;

        const updatedItems = [...items];
        const draggedIndex = updatedItems.findIndex((item) => item.id === draggedItemRef.current);
        const droppedIndex = updatedItems.findIndex((item) => item.id === droppedId);

        if (draggedIndex === -1 || droppedIndex === -1) return;

        // Intercambiar elementos
        const [draggedItem] = updatedItems.splice(draggedIndex, 1);
        updatedItems.splice(droppedIndex, 0, draggedItem);

        setItems(updatedItems);
        callback(updatedItems);
        draggedItemRef.current = null;
    };

    return (
        <div className="drag-select" onDragOver={handleDragOver}>
            {items.map((item) =>
                item?.content ? (
                    React.cloneElement(item.content, {
                        key: item.id,
                        id: item.id,
                        draggable: true,
                        onDragStart: () => handleDragStart(item.id),
                        onDragEnd: () => (draggedItemRef.current = null),
                        onDrop: () => handleDrop(item.id),
                        onTouchStart: () => handleDragStart(item.id),
                        onTouchEnd: () => handleDrop(item.id),
                    })
                ) : null
            )}
        </div>
    );
};

export default DragSelect;
