import Paragraph from "../../atoms/paragraph";
import TextField from "../../atoms/text-field";
import Button from "../../atoms/button";
import Form from "../form";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { useContext } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useNavigate, useParams } from "react-router-dom";

const RegisterReference = () => {
    //setNewTitleReference('Agregar Referencia');
    const { user,setNewTitleReference } = useContext(UserContext);
    setNewTitleReference('Agregar Referenciass');
    const { idChallenge } = useParams();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (e.target.name.validity.valid && e.target.lastName.validity.valid) {
            const data = {
                references: [
                    {
                        name: `${e.target.name.value} ${e.target.lastName.value}`,
                    },
                ]
            };


            axios.put(`${API.SAVE_PERSONAL_REFERENCES.url}/${user?._id}/${idChallenge}`, data).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    navigate('/missions', { state: { update: true } });
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    return (
        <div className="register-reference">
            <Paragraph size="sx" align="center">
                Piensa en personas con quienes has compartido<br/>
                en diferentes momentos de tu vida. Ya sea <br/>
                durante el colegio, la universidad o en algún <br/>
                trabajo. También con quienes llevaste a cabo <br/>
                proyectos o emprendimientos. Incluso tus <br/> compañeros en el deporte.<br/>
                ¿Quienes te ayudaron con el descubrimiento de <br/>tu propósito?<br/>
                ¿Con quienes te gustaría volver a trabajar?<br/> ¿Con quiénes sabes que lograrías grandes cosas?
            </Paragraph>
            <Form onSubmit={handleSubmit}>
                <TextField placeholder="Nombres" name="name" required />
                <TextField placeholder="Apellidos" name="lastName" required />
                <Paragraph size="sx" align="center">
                    Recuerda:<br/>
                    Obtienes Medallas de Reconocimiento, por cada dos Nuevas Referencias.
                </Paragraph>
                <Button >Agregar</Button>
            </Form>
        </div>
    );
};

export default RegisterReference;