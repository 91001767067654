import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import WarningIcon from "../../../resources/images/warning.png";
import Medalla from "../../../resources/images/Medalla-Cap.png";
import Info from "../../../resources/images/Info.png";
import DestinoCaptain from "../../../resources/images/legacy.png";
import Helpe from "../../../resources/images/Help.png";
import DirectorMediun from "../../../resources/images/Medals-Cap-2.png";
import Genial from "../../../resources/images/clap-captain.png";
import Captain from "../../../resources/images/MaskgroupCapTree.png";
import GoodHandCaptain from "../../../resources/images/good-hand-captain.png";
import KeyH2Cap from "../../../resources/images/KeyH2Cap.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import { CardCopyKey } from "../../molecules/card-copy-key";
import { Quote } from "../../molecules/quote";
import "./circle.scss";
import Card from "../../atoms/card";
import TextArea from "../../atoms/text-area";

const CapitanThirdDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "39px",
              }}
            >
              <b>¡Hey!</b>
            </Paragraph>
            <img
              src={WarningIcon}
              alt="sync-aspirante-director"
              style={{
                marginTop: "10px",
              }}
            />
            <Card
              type="secondary"
              style={{
                width: "300px",
                maxWidth: "300px",
              }}
            >
              <Paragraph align="center" size="sx">
                <b>
                  Este desafío se realiza por fuera de la
                  <br />
                  Plataforma ACDC.{" "}
                </b>
                <br />
                <br />
                Cuando tu Director(a) reciba y confirme<br/>
                que le enviaste la nota de voz<br/>
                correspondiente al <b>Desafío A</b> podrás<br/>
                continuar con tus desafíos diarios.
              </Paragraph>
            </Card>

            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setStep(step + 1)}
            >
              ¡Vale!
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Medalla} alt="medalla" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b>Justo a tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={DirectorMediun} alt="DirectorMediun" />
              <>
                <Paragraph align="center" size="s">
                  ¡Has ganado una Medalla de
                  <br />
                  <b> Manejo del tiempo</b>!
                </Paragraph>
                <Paragraph align="center" size="s">
                  Estas te servirán para medir tu desempeño
                  <br />y terminar el nivel satisfactoriamente.
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="182"
              height="182"
            />
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <Paragraph size="s" align="center">
              Ya puedes continuar con el <b>Desafío B</b> del
              <br /> Día 3.
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              ¡Vale!
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>
                    HONOR Formación de líderes que
                    <br />
                    forman líderes..
                  </b>
                  <br />
                  <br />
                  “Los líderes de quinto nivel deben<br/>
                   permitir a los líderes de cuarto nivel<br/>
                  realmente dirigir a sus líderes y vivir y<br/>
                   aprender de sus propios resultados. Un<br/>
                  líder que se entromete nunca podrá<br/>
                   atraer o retener el liderazgo más<br/>
                  talentoso porque los líderes verdaderos<br/>
                  se rehúsan a ser “gobernados,” a lo<br/>
                   mucho accederán a ser “guiados”.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />
            <img src={Captain} alt="captain" width="133" height={133} />
            <Paragraph align="center" size="sx">
              <b>¡Capitán(a)!</b>
              <br />
              Cuando iniciaste este camino con ACDC,
              <br />
              escribiste tu <b> Legado.</b>.
              <br />
              <br />
              ¿Lo recuerdas?
            </Paragraph>

            <Card type="secondary">
              <Paragraph
                align="left"
                size="sx"
                style={{
                  marginTop: "1px",
                }}
              >
              Mi Legado es construir un mundo donde las<br/>
              personas tengan acceso al desarrollo<br/>
              individual y colectivo de sus habilidades,<br/>
              despertando sus pasiones y explotando sus<br/>
              talentos, algo totalmente ajeno al sistema que<br/>
              nos obliga a producir lo que necesita una parte<br/>
              de la sociedad.
              </Paragraph>
            </Card>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 3}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <img src={Info} alt="info" />
              <>
                <Paragraph align="center" size="s">
                  <b>Ejemplos de Legado:</b>
                </Paragraph>
                <Paragraph align="center" size="s">
                  👉🏻 Fue una persona que ayudó a muchas<br/>
                   personas a creer en sí mismas y lograr<br/>
                  grandes cosas.
                  <br />
                  👉🏻Toda su familia salió adelante y progresó<br/>
                   por él(la) ¡Les cambio la vida! Les brindó<br/>
                  educación y trabajo y hoy en día muchos<br/>
                   ayudan a mucha gente.
                  <br />
                </Paragraph>
                <Button size="small" onClick={() => setStep(step + 1)}>
                  ¡Vale!
                </Button>
              </>
            </div>
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <br/>
            <img
              src={Helpe}
              alt="helpe"
              width={32}
              height={32}
              style={{
                marginLeft: " 341px",
                marginTop: "19px",
                position: "absolute",
              }}
            />
            <img
              src={DestinoCaptain}
              alt="PropósitoCaptain"
              width={138}
              height={138}
            />

            <Paragraph align="center" size="sx">
            Ahora que has aprendido sobre....<br/>
            <br/>

es momento de editar y reescribir el <b>Legado</b><br/>
 de vida que escribiste cuando eras un<br/> Aspirante.
            </Paragraph>

            <TextArea
              placeholder="A lo largo de mi vida he descubierto mi destino en el mundo, el cual está encaminado hacia la construcción de un sistema sostenible basada en la explotación de las habilidades humanas en pro del desarrollo individual y colectivo de las sociedades |"
              id="other-discarded"
              style={{
                width: "360px",
                maxWidth: "292px",
                height: "156px",
              }}
            />

            <div
              className="radiu-btn-text"
              style={{
                borderRadius: "60px",
                background: "#0d233b",
                marginTop: "20px",
                padding: "3px",
              }}
            >
              <Paragraph align="center" size="sx">
                Caracteres: 277
              </Paragraph>
            </div>
            <Paragraph align="center" size="xs">
              Entre 180 y 280 caracteres para obtener <br />
              Medalla de Reconocimiento.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
              style={{
                marginTop: "19px",
              }}
            >
              ¡Genial!
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={KeyH2Cap}
              alt="KeyH2Cap"
              style={{
                marginTop: "10px",
              }}
              width="246"
              height="246"
            />
            <Card type="secondary">
              <Paragraph align="center" size="s">
                ¡Conseguiste la Llave de hoy!
              </Paragraph>
              <Paragraph align="center" size="s">
                Esta te da el paso al <b>Día de Filtro.</b>
              </Paragraph>
              <Paragraph align="center" size="s">
                Solo te falta completar el <b>Desafío C</b> que
                <br />
                ya se encuentra habilitado.
              </Paragraph>
            </Card>
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx" style={{}}>
                  <b>
                  “¿Cómo se puede lograr atraer líderes<br/>
                   de alto calibre?”
                  </b>
                  <br />
                  <br />
                  El liderazgo del quinto nivel se trata de<br/>
                  hacer una diferencia en el statu quo que<br/>
                  sobrevive por más tiempo que el líder.<br/>
                   Los líderes del quinto nivel comienzan<br/>
                  por atraer, inspirar y permitir a los<br/>
                  líderes que son formadores de otros<br/>
                   líderes. Los resultados de dicha<br/>
                  habilidad son astronómicos; un ejemplo<br/>
                  de ello son las palabras que puso en su<br/>
                  propio epitafio Andrew Carnegie, el<br/>
                  padre de la industria de acero de<br/>
                  Estados Unidos: “Aquí yace un hombre<br/>
                  que sabía como traer a su servicio<br/>
                   hombres mejores que él”.
                </Paragraph>
              </div>
            </div>
            <Quote>
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img
              src={GoodHandCaptain}
              alt="good-hand-captain"
              widh="343"
              height="209"
            />
            <CardCopyKey
              phrase="Desafío C Día 3 Capitán"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "6px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CapitanThirdDay;
