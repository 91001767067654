import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Vector from "../../../resources/images/Vector.png";
import CheckoutList from "../../../resources/images/checkout_list.png";
import Button from "../../atoms/button";
import Candidate from "../../../resources/images/Candidate.png";
import Twobot from "../../../resources/images/Twobot.png";
import Treesdicons from "../../../resources/images/3dicons.png";
//director
import youtubeImage from "../../../resources/images/youtube-image.png";

import Genial from "../../../resources/images/Genial.png";
import ProfileTwobo from "../../../resources/images/profile-twobo.png";

import MonyDirector from "../../../resources/images/MonyDirector.png";
import ContenedorTutorialAlignmentOne from "../../../resources/images/Contenedor-Tutorial-alignment-one.png";
import ContenedorTutorialAlignmentTwo from "../../../resources/images/Contenedor-Tutorial-alignment-two.png";
import ContenedorTutorialAlignmentThree from "../../../resources/images/Contenedor-Tutorial-alignmentThree.png";
import BaseDirectorEpa from "../../../resources/images/base-director-epa.png";
import Pause from "../../../resources/images/pause.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";

import axios from "axios";
import { API, ROLES } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";

import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

import { CardCopyKey } from "../../molecules/card-copy-key";
import SincAspiranteValidador from "../../../resources/images/Sinc-Aspirante-Validador.png";
import ZonaAprendizaje from "../../../resources/images/Zona_aprendizaje.png";

import "./options.scss";

import MedalSwitch from "../../molecules/medal-switch";
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import NewDirector from "../../../resources/images/director-avatar.png";
import NewHost from "../../../resources/images/new-host.png";
import { ReactComponent as RecognitionMedalJustTime } from "../../../resources/images/just-time-director.svg";
import screenTools from "../../../resources/images/screen-tools.png";
import CandidateBlack from "../../../resources/images/candidate_black.png";
import { assignMedal } from "../../../middleware/services/common";
import { Toggle } from "../../atoms/switch";
let titleRoleCoordinatorDiretor = [
  "Lista de Espera Coordinador(a)  🔴 (Tú)",
  "Lista de Espera  Director(a) 🟡",
  "Lista de Espera  Capitan(a) 🔵",
];
const CapitanAlignmentDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [medals, setMedals] = useState([]);
  const [hostData, setHostData] = useState(null);

  const [open, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleNavigation = (plus) => {
    const navigation = currentSlide + plus;
    if (navigation < 3 && navigation >= 0) {
      setCurrentSlide(navigation);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>¡Prepárate para la Sincronización del Alineamiento!</b>
            </Paragraph>
            <img
              src={BaseDirectorEpa}
              alt="base-director-epa"
              width="222"
              height="222"
            />
            <Paragraph align="center" size="sx">
              ¡Director(a)!
              <br />
              <br />
              A continuación te mostraremos las principales
              <br />
              funciones que tendrás cuando te encuentres
              <br />
              dirigiendo el EPA.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <img
              src={Pause}
              alt="pause"
              style={{
                marginTop: "20px",
              }}
            />
            <Paragraph withBackground={true} align="center" size="s">
              Una de tus tareas más importantes
              <br />
              cuando te encuentres en el EPA será
              <br />
              dirigir y sincronizar las pausas del video.
              <br />
              Para esto hemos preparado un PDF
              <br />
              donde encontrarás detalladamente cómo
              <br />
              realizar correctamente esta actividad.
              <br />
              <br />
              ¡Aprovecha el tiempo y prepárate desde
              <br />
              hoy!
              <br />
              <br />
            </Paragraph>
            <Button
              size="small"
              styles={{
                position: "relative",
                marginTop: "5px",
              }}
            >
              Descargar PDF
            </Button>
            <Paragraph align="center" size="sx">
              Recuerda que puedes descargar siempre el PDF
              <br />
              desde la información del Desafío A del Día de
              <br />
              Alineamiento en Mi Progreso.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            >
              Todos los roles verán en el cronograma el Botón
              <br />
              para inciar el EPA inhabilitado, pues este solo
              <br />
              estará activo para todo el equipo una vez tú lo
              <br />
              presiones e inicies el EPA.
            </Paragraph>
            <img
              src={ContenedorTutorialAlignmentOne}
              alt="Contenedor Tutorial-alignment-one"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            >
              Así mismo para cada desafío del EPA, ningún rol
              <br />
              podrá inicar hasta que lo hagas tú como
              <br />
              Director(a).
            </Paragraph>
            <img
              src={ContenedorTutorialAlignmentTwo}
              alt="Contenedor-Tutorial-alignment-two"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            >
              Cada vez que te encuentres con el botón
              <br />
              ‘’Continuar EPA’’ diferenciado por su color
              <br />
              amarillo (color del rol) ten en cuenta que este
              <br />
              habilita el avance del resto del equipo que se
              <br />
              encuentre en la misma actividad.
            </Paragraph>
            <img
              src={ContenedorTutorialAlignmentThree}
              alt="Contenedor-Tutorial-alignmentThree"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="coordinator-epa-container"
          >
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            ></Paragraph>

            <Paragraph
              size="s"
              align="center"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            >
              <b>Simulador Actividad PDL</b>
              <br />
              <br />
              Cuando llegue el momento de compartir tu PDL
              <br /> deberás entregar la medalla de Manejo del
              <br />
              Tiempo 🥇 a tu Coordinador(a) y visualizar cómo
              <br />
              se entregan las medellas el resto del equipo.
              <br />
              <br />
              Recuerda que solo debes continuar hasta que
              <br />
              verifiques si se otorgaron o no, las medallas a<br />
              todos los miembros del equipo.
              <br />
              <br />A continuación puedes ponerlo en práctica:
            </Paragraph>
            <br />
            <div className="tutorial-time-management">
              <div
                className="tutorial-time-management__info"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: " flex-start",
                  alignItems: "center",
                }}
              >
                <RecognitionMedalJustTime
                  className="director"
                  width="50.75"
                  height="59.9"
                />
                <Paragraph
                  size="s"
                  align="center"
                  style={{
                    marginBottom: "17px",
                    marginTop: "-7px",
                  }}
                >
                  Presiona Sí, en el participante que haya ganado medalla de
                  Manejo del Tiempo y No, en el caso contrario.
                </Paragraph>
              </div>
              <div className="diveder"></div>
              <div className="medal-switch-director">
                <MedalSwitch
                  roleName={"director"}
                  name="Flabio"
                  circle={ROLES["director"]?.circle}
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                  disabled={true}
                />
                <div className="diveder"></div>
                <MedalSwitch
                  roleName={"coordinator"}
                  name="Flabio"
                  circle={ROLES["coordinator"]?.circle}
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                />
                <div className="diveder"></div>
                <MedalSwitch
                  roleName={"host"}
                  name="Flabio"
                  circle={ROLES["host"]?.circle}
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                />{" "}
                <div className="diveder"></div>
                <MedalSwitch
                  roleName={"candidate"}
                  name="Flabio"
                  circle={ROLES["candidate"]?.circle}
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                />
              </div>
            </div>

            <Button
              className={"director"}
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar EPA
            </Button>
          </Step>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="coordinator-epa-container"
          >
            <Paragraph
              size="s"
              align="center"
              style={{
                maxWidth: "364px",
                width: "100%",
                marginTop: "36px",
              }}
            >
              <b>
                También deberás verificar si tu Coordinador(a)
                <br />
                otorgó o no, la medalla en equipo de Justo a<br />
                Tiempo de la Sala Épica.
              </b>
              <br />
              <br />
              Para ganar esta medalla de equipo, tú como
              <br />
              Director(a) deberías haber completado la pausa <br />
              #4 de la Sala Épica máximo 30 minutos después
              <br />
              de la hora de inicio del EPA.
            </Paragraph>
            <br />
            <div className="tutorial-time-management">
              <div
                className="tutorial-time-management__info"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: " flex-start",
                  alignItems: "center",
                }}
              >
                <RecognitionMedalJustTime
                  className="director"
                  width="50.75"
                  height="59.9"
                />
                <Paragraph
                  size="s"
                  align="center"
                  style={{
                    marginBottom: "17px",
                    marginTop: "-7px",
                  }}
                >
                  En la siguiente tabla podrás ver las Salas en
                  <br />
                  las que has ganado Medallas en equipo de
                  <br />
                  <b> Justo a Tiempo.</b>
                </Paragraph>
              </div>
              <div className="diveder"></div>
              <div className="medal-switch-director">
                <MedalSwitch
                  name="Sala Épica."
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                  disabled={true}
                />
                <div className="diveder"></div>
                <MedalSwitch
                  name="Sala Plataforma."
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                  disabled={true}
                />
                <div className="diveder"></div>
                <MedalSwitch
                  name="Sala Admisiones."
                  onClick={(value) => {
                    setMedals([...medals, { value, name: "Camilo" }]);
                    assignMedal("timeManagement", value, 1, idChallenge);
                  }}
                  disabled={true}
                />
              </div>
            </div>

            <Button
              className={"director"}
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar EPA
            </Button>
          </Step>
          <Step order={8} visibility={step === 8 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío A.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="s" align="center" className="card-host-captain">
              Ahora que ya hiciste la simulación de
              <br />
              medallas del EPA, continúa con el siguiente <br />
              ▶️ VIDEO, para seguir con tu Alineamiento.
              <br />
              <br />
              <b> 👋🏻 Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Ver video
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Zona de Aprendizaje EPA.</b>
            </Paragraph>
            <img
              src={ZonaAprendizaje}
              alt="Zona_aprendizaje"
              width="246"
              height="246"
            />
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "364px",
              }}
            >
              Ahora experimentarás una simulación que
              <br />
              refleja lo que vivirás una vez estés en el Final del
              <br /> EPA.
            </Paragraph>

            <Paragraph
              align="center"
              size="s"
              style={{
                width: "364px",
                maxWidth: "364px",
              }}
            >
              A partir de este momento
              <br /> IMAGINA QUE ESTÁS AL FINAL DEL EPA...
            </Paragraph>
            <Paragraph
              align="center"
              size="s"
              style={{
                width: "364px",
                maxWidth: "364px",
              }}
            >
              <b>¡Tú serás el(la) Nuevo(a) Capitán(a)!</b>
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "56px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <Paragraph align="center" size="s">
                <b>¡Director(a)!</b>
              </Paragraph>

              <Paragraph align="center" size="s">
                Primero vas a entrenarte en las actividades
                <br />
                correspondientes al <b> Desafío Honor</b> que
                <br />
                vivirás al Final del EPA.
              </Paragraph>
              <Button
                styles={{
                  position: "relative",
                  marginTop: "56px",
                }}
                onClick={() => setStep(step + 1)}
                size="small"
              >
                ¡Vale!
              </Button>
            </div>
          </Modal>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Confirmación de fecha para los EPAs.</b>
              <br />
              <br />
              A continuación podrás ver la fecha y hora del
              <br />
              EPA de cada uno de los(as) Nuevos(as)
              <br />
              Coordinadores(as) de tu Nuevo(a) Director(a):
            </Paragraph>
            <div
              className="card-host card-coordinator-new"
              style={{
                margin: "0",
                marginBottom: "14px",

                width: "323px",
                borderRadius: "8px",
              }}
            >
              <div className="card-host-header">
                <img
                  src={NewDirector}
                  alt="director-avatar"
                  width={80}
                  height={80}
                />
                <div className="coordinator-info">
                  <p className="coordinator-name text-lower-and-capitalize">
                    <b> {toLowercaseAndCapitalize("Luciana Sandoval Paz")}</b>
                  </p>
                  <span
                    className="status nuevo"
                    style={{
                      paddingLeft: "7px",
                    }}
                  >
                    Nuevo(a) Director(a)
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card-host card-coordinator-new"
              style={{
                margin: "0",
                marginBottom: "14px",
                height: " 133px",
                width: "323px",
                borderRadius: "8px",
              }}
            >
              <div className="card-host-header">
                <img
                  src={NewCoordinator}
                  alt="new-host"
                  width={80}
                  height={80}
                />
                <div className="coordinator-info">
                  <p className="coordinator-name text-lower-and-capitalize">
                    {toLowercaseAndCapitalize("Paula Inés Panesso Umbarila")}
                  </p>
                  <span className="status nuevo">
                    🔴 Nuevo(a) Coordinador(a)
                  </span>
                </div>
              </div>
              <div className="card-body">
                <input
                  type="gridDatePicker"
                  className="epa-hungry-date "
                  value={"16 de Nov. del 2023"}
                />
                <input
                  type="text"
                  className="epa-hungry-hour"
                  value={"8:00 AM"}
                />
              </div>
            </div>
            <div
              className="card-host card-coordinator-new"
              style={{
                margin: "0",
                marginBottom: "14px",
                height: " 133px",
                width: "323px",
                borderRadius: "8px",
              }}
            >
              <div className="card-host-header">
                <img
                  src={NewCoordinator}
                  alt="new-host"
                  width={80}
                  height={80}
                />
                <div className="coordinator-info">
                  <p className="coordinator-name text-lower-and-capitalize">
                    {toLowercaseAndCapitalize("Paula Inés Panesso Umbarila")}
                  </p>
                  <span className="status nuevo">
                    🔴 Nuevo(a) Coordinador(a)
                  </span>
                </div>
              </div>
              <div className="card-body">
                <input
                  type="gridDatePicker"
                  className="epa-hungry-date "
                  value={"16 de Nov. del 2023"}
                />
                <input
                  type="text"
                  className="epa-hungry-hour"
                  value={"8:00 AM"}
                />
              </div>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={step === 4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <Paragraph align="center" size="s">
                <b>¡Director(a)!</b>
              </Paragraph>

              <Paragraph align="center" size="s">
                Ahora vas a entrenarte en las actividades
                <br />
                correspondientes al <b>Desafío Humildad</b> que
                <br />
                vivirás al Final del EPA.
              </Paragraph>
              <Button
                styles={{
                  position: "relative",
                  marginTop: "56px",
                }}
                onClick={() => setStep(step + 1)}
                size="small"
              >
                ¡Vale!
              </Button>
            </div>
          </Modal>

          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              Confirmación Canal de Youtube
            </Paragraph>
            <img
              src={youtubeImage}
              alt="youtube-image"
              width="104"
              height="126"
            />
            <Paragraph align="center" size="xs">
              ¡Nuevo(a) Capitán(a)!
              <br />
              <br />
              En este momento los dos Nuevos Anfitriones se
              <br />
              deben unir al canal de YouTube de ACDC con el
              <br />
              obsequio 🎁 que recibirán por parte de los
              <br />
              Nuevos(as) Coordinadores(as).
              <br />
              <br />
              En la siguiente pantalla podrás visualizar que se
              <br />
              dieron los obsequios y quienes ya se unieron al
              <br />
              canal de Youtube.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
            <br />
            <br />
            <div
              className="user-list-checkbox"
              styles={{
                backgroundColor: "#0f1b2b2!important",
              }}
            >
              <>
                <div className="user-card-checkbox">
                  <div className="card-host-header">
                    <img
                      src={NewDirector}
                      alt="new-host"
                      width={80}
                      height={80}
                    />
                    <div className="coordinator-info">
                      <p className="coordinator-name">
                        <b>Luciana Sandoval Paz</b>
                      </p>
                      Nuevo(a) Director(a)
                    </div>
                  </div>
                </div>

                <>
                  <>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewCoordinator}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Paula Inés Panesso Umbarila
                          </span>
                          <p className="user-action-checkbox">
                            Dió los obsequios{" "}
                            <img
                              src={Vector}
                              alt=""
                              className="action-icon-checkbox"
                            />
                            <img
                              src={Vector}
                              alt=""
                              className="action-icon-checkbox"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="arrow-hungry"
                      style={{
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "-24px",
                        "margin-bottom": "22px",
                      }}
                    >
                      <img src={ArrowSmall} alt="" className="arrow-small" />
                    </div>
                  </>

                  <>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewHost}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Luis David Varela
                          </span>
                          <p className="user-action-checkbox-2">
                            Se unió al Canal de Youtube
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewHost}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Luis David Varela
                          </span>
                          <p className="user-action-checkbox-2">
                            Se unió al Canal de Youtube
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </>
                <>
                  <>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewCoordinator}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Paula Inés Panesso Umbarila
                          </span>
                          <p className="user-action-checkbox">
                            Dió los obsequios{" "}
                            <img
                              src={Vector}
                              alt=""
                              className="action-icon-checkbox"
                            />
                            <img
                              src={Vector}
                              alt=""
                              className="action-icon-checkbox"
                            />
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="arrow-hungry"
                      style={{
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "-24px",
                        "margin-bottom": "22px",
                      }}
                    >
                      <img src={ArrowSmall} alt="" className="arrow-small" />
                    </div>
                  </>

                  <>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewHost}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Luis David Varela
                          </span>
                          <p className="user-action-checkbox-2">
                            Se unió al Canal de Youtube
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-card-checkbox">
                      <div className="user-info-checkbox">
                        <img
                          src={NewHost}
                          alt="new-host"
                          width={80}
                          height={80}
                          className="user-avatar-checkbox"
                        />
                        <div className="user-details-checkbox">
                          <span className="user-name-checkbox">
                            Luis David Varela
                          </span>
                          <p className="user-action-checkbox-2">
                            Se unió al Canal de Youtube
                          </p>
                        </div>
                      </div>
                      <div className="user-status-checkbox-2">
                        <div className="status-check-checkbox-3">
                          <img
                            src={CheckoutList}
                            alt=""
                            className="check-mark-checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </>
              </>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar EPA
            </Button>
          </Step>

          <Modal
            isOpen={step === 7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="confirmation-register-modal small"
          >
            <div className="confirmation-register-container">
              <Paragraph align="center" size="s">
                <b>¡Director(a)!</b>
              </Paragraph>

              <Paragraph align="center" size="s">
                Para finalizar vas a entrenarte en las
                <br />
                actividades correspondientes al{" "}
                <b>
                  Desafío
                  <br /> Hambre
                </b>{" "}
                que vivirás al Final del EPA.
              </Paragraph>
              <Button
                styles={{
                  position: "relative",
                  marginTop: "56px",
                }}
                onClick={() => setStep(step + 1)}
                size="small"
              >
                ¡Vale!
              </Button>
            </div>
          </Modal>

          <Step order={8} visibility={step === 8 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              ¡Momento de los agradecimientos!
            </Paragraph>
            <img
              src={screenTools}
              alt="screen-tools"
              width="104"
              height="126"
            />
            <Paragraph align="center" size="xs">
              💡 Nuevo(a) Capitán(a), es momento de que los
              <br />
              Nuevos Anfitriones comiencen a agrecer a sus
              <br />
              Validadores. Mañana en el Día de Inducción,
              <br />
              iniciarán la Convocatoria. Requerirán el link de
              <br />
              registro al EPA, tu Director(a) será el encargado <br />
              de generarlo.
            </Paragraph>
            <Button size="small">Ver video</Button>
            <Paragraph></Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={9} visibility={step === 9 ? "visible" : "hidden"}>
            <br />
            <br />
            <div
              className="user-list-checkbox"
              style={{
                marginBottom: "78px",
              }}
            >
              <>
                <div className="user-card-checkbox">
                  <div className="card-host-header">
                    <img
                      src={NewDirector}
                      alt="new-host"
                      width={80}
                      height={80}
                    />
                    <div className="coordinator-info">
                      <p className="coordinator-name">
                        <b>Luciana Sandoval Paz</b>
                      </p>
                      Nuevo(a) Director(a)
                    </div>
                  </div>
                </div>

                <>
                  <>
                    <div className="user-card-hungry">
                      <div className="user-header-hungry">
                        <img
                          src={NewCoordinator}
                          alt="Paula Inés"
                          width={80}
                          height={80}
                          className="avatar-hambre"
                        />
                        <div className="user-details-hungry">
                          <span className="user-name-hungry">
                            <b>Paula Inés Panesso Umbarila</b>
                          </span>
                          <p className="user-role-hungry">
                            Nuevo(a) Coordinador(a)
                          </p>
                        </div>
                      </div>
                      <div className="arrow-hungry">
                        <img src={ArrowSmall} alt="" className="arrow-small" />
                      </div>
                    </div>
                    <div className="user-card-hungry">
                      EPA:
                      <div className="card-body">
                        <input
                          type="text"
                          className="epa-hungry-date"
                          value={"10 de Nov. del 2023"}
                          disabled
                        />
                        <input
                          type="text"
                          className="epa-hungry-hour"
                          value={"8:00 AM"}
                        />
                      </div>
                    </div>
                  </>

                  <>
                    <div className="card-cap-new">
                      <div className="card-header-cap-new">
                        <img
                          src={NewHost}
                          alt="new-host"
                          width={80}
                          height={80}
                        />

                        <div className="user-info-cap-new">
                          <h3 className="name-cap-new">
                            <b>Luis David Varela</b>
                          </h3>
                          <p className="role-cap-new">Nuevo(a) Anfitrión(a)</p>
                        </div>
                      </div>
                      <div className="card-body-cap-new">
                        <div className="info-row-cap-new">
                          <span className="span-cap-new">
                            Entrenamiento completado
                          </span>
                          <span className="check-cap-new">
                            <img src={CheckoutList} alt="" />
                          </span>
                        </div>
                        <div className="info-row-cap-new">
                          <span className="span-cap-new">
                            Agradecimientos Realizados:
                          </span>
                          <span className="check-cap-new">4</span>
                        </div>
                      </div>
                      <Button size="small">Ver validadores</Button>
                    </div>
                    <br />
                    <br />
                    <br />
                  </>
                </>
              </>
            </div>

            <Button onClick={() => setStep(step + 1)}>Siguiente</Button>
          </Step>

          <Step order={10} visibility={step === 10 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              Ahora dirígete a tu WhatsApp y cambia la foto de perfil por la de
              TWOBOT.
              <br />
              <br />
            </Paragraph>
            <div
              className="profile-card-cap"
              style={{
                marginBottom: "78px",
              }}
            >
              <div className="profile-header-cap">
                <img
                  src={ProfileTwobo}
                  alt="TWOBOT"
                  className="profile-pic-cap"
                />
              </div>

              <div className="confirmation-section-cap">
                <p>Ya tengo la foto de perfil del TWOBOT.</p>
                {/* <label className="switch-cap">
                                <input type="checkbox-cap" />
                                <span className="slider-cap round-cap"></span>
                            </label> */}
                <div className="toggle-switch">
                  <Toggle
                    value={true}
                    className="switch-input"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>

            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            <br />
          </Step>

          <Step order={11} visibility={step === 11 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <CardCopyKey
              phrase="Sync Alineamiento Director"
              nameChallenge="Sync Alineamiento Director"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph
              align="center"
              size="s"
              style={{
                maxWidth: "367px",
              }}
            >
              A continuación, encontrarás la hora y el link de
              <b />
              la meet a la que deberás conectarte para
              <br />
              realizar una sincronización de 15 minutos con tu
              <br />
              Coordinador(a) que está en día de Alineamiento.
            </Paragraph>
            <Paragraph
              withBackground={true}
              align="center"
              size="s"
              style={{
                width: "340px",
                maxWidth: "350px",
              }}
            >
              <img
                src={SincAspiranteValidador}
                alt="Sinc-Aspirante-Validador"
                width="171"
                height="171"
                style={{
                  marginTop: "39px",
                }}
              />
              <br />
              <b>Hora: 9:45 pm</b>
            </Paragraph>
            <Paragraph
              size="xs"
              align="center"
              style={{
                width: "340px",
                maxWidth: "350px",
                position: "relative",
                marginTop: "47px",
              }}
            >
              Este botón se habilitará en medio de la reunión para
              <br /> que puedas realizar el resto del desafío.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Excelente</b>
              <br />
              <br />
              Estos son los preseleccionados
              <br />
              de cada Anfitrión:
            </Paragraph>

            <>
              <div className="user-card user-card-host-by-coordinator">
                <div className="user-info">
                  <img
                    src={NewHost}
                    alt="new-host"
                    width={80}
                    height={80}
                    className="user-avatar"
                  />

                  <div className="user-details">
                    <span className="user-name">
                      Paula Ines Panesso Umbarila
                    </span>
                    <div className="user-status">
                      <span className="status-dot"></span>
                      <span className="status-text">Anfitrión(a)</span>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>{" "}
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>

            <>
              <div className="user-card user-card-host-by-coordinator">
                <div className="user-info">
                  <img
                    src={NewHost}
                    alt="new-host"
                    width={80}
                    height={80}
                    className="user-avatar"
                  />

                  <div className="user-details">
                    <span className="user-name">
                      Paula Ines Panesso Umbarila
                    </span>
                    <div className="user-status">
                      <span className="status-dot"></span>
                      <span className="status-text">Anfitrión(a)</span>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>{" "}
              <>
                <div className="user-card candidate-card">
                  <div className="user-info">
                    <img
                      src={Candidate}
                      alt="candidate"
                      width={80}
                      height={80}
                      className="user-avatar"
                    />

                    <div className="user-details">
                      <span className="user-name">Nombre del Aspirante</span>
                      <div className="user-status">
                        <span className="status-text">
                          {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>
            <div className="user-card candidate-card">
              <div className="user-info">
                <img
                  src={CandidateBlack}
                  alt="candidate-black"
                  width={80}
                  height={80}
                  className="user-avatar"
                />

                <div className="user-details">
                  <span className="user-name">¡Ups! falta un Aspirante </span>
                  <div className="user-status">
                    <span
                      className="status-text add-candidate-text"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Agregar de lista de espera
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              styles={{
                position: "relative",
                marginTop: "75px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            className="list-modal"
          >
            <span
              className="close icon-close"
              onClick={() => setOpen(false)}
              onKeyDown={handleKeyDown}
            ></span>
            {currentSlide === 0 && (
              <>
                <span className="title">{titleRoleCoordinatorDiretor[0]}</span>
                <ul className="list-modal--list">
                  <li className={`list-modal--list--item`}>
                    Martin Olivas Sempere.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Julián Verde Bustos.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Alejandro Contreras Sandoval.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Martin Olivas Sempere.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Gisella Romero Bolaños.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Luciana Patricia Gomez Gomez.
                  </li>
                </ul>
              </>
            )}
            {currentSlide === 1 && (
              <>
                <span className="title">{titleRoleCoordinatorDiretor[1]}</span>
                <ul className="list-modal--list">
                  <li className={`list-modal--list--item`}>
                    Amparo Puertas Grisalez.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Sara Cabezas Martinez.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Jose Feliz Caballero Oliveira.
                  </li>
                </ul>
              </>
            )}
            {currentSlide === 2 && (
              <>
                <span className="title">{titleRoleCoordinatorDiretor[2]}</span>
                <ul className="list-modal--list">
                  <li className={`list-modal--list--item`}>
                    Amparo Puertas Grisalez.
                  </li>
                  <li className={`list-modal--list--item`}>
                    Sara Cabezas Martinez.
                  </li>
                </ul>
              </>
            )}
            <div className="carousel-controls">
              <div
                className={`carousel-control carousel-control--prev ${
                  currentSlide === 0 ? "disabled" : ""
                }`}
                onClick={() => handleNavigation(-1)}
                onKeyDown={() => {}}
              >
                <span className="icon icon-previous"></span>
              </div>
              <ul className="carousel-control carousel-control--pagination">
                <li
                  key={0}
                  className={`item ${0 === currentSlide ? "active" : ""}`}
                ></li>
                <li
                  key={1}
                  className={`item ${1 === currentSlide ? "active" : ""}`}
                ></li>
                <li
                  key={2}
                  className={`item ${2 === currentSlide ? "active" : ""}`}
                ></li>
              </ul>
              <div
                className={`carousel-control carousel-control--next ${
                  currentSlide === 3 - 1 ? "disabled" : ""
                }`}
                onClick={() => handleNavigation(1)}
                onKeyDown={() => {}}
              >
                <span className="icon icon-next"></span>
              </div>
            </div>
          </Modal>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Compra TWOBOT!</b>
            </Paragraph>
            <img src={Twobot} alt="" widh="273" height="240" />

            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Para que estés listo(a) el día de mañana en el
              <br />
              Cambio de rol cuando seas el nuevo Capitán(a)
              <br />
              solo te falta realizar la compra de TWOBOT
              <br />
              para que durante la Convocatoria los nuevos
              <br />
              Anfitriones te pongan a ti como contacto de
              <br />
              TWOBOT para enviárselo a los Validadores en el
              <br />
              ABC Convocatoria.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              align={"center"}
              size={"s"}
              style={{
                maxWidth: "364px",
                width: "364px",
                height: "217px",
                background: "#202D47",
                borderRadius: "13x",
                marginTop: "17px",
              }}
            >
              <br />
              <img
                src={MonyDirector}
                alt="MonyDirector"
                width={100}
                height={100}
              />
              <br />
              Costo de la aplicación de TWOBOT.
              <br />
              <span
                className="cash-container"
                style={{
                  display: "flex",
                  justifyContent: " space-around",
                  background: "#0D233B",
                  height: " 35px",
                  borderRadius: "30px",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <span
                  className="cash"
                  style={{
                    marginLeft: "138px",
                  }}
                >
                  $ 100.000
                </span>
                <span
                  className="cop"
                  style={{
                    marginLeft: "81px",
                  }}
                >
                  COP
                </span>
              </span>
            </Paragraph>
            <br />
            <Paragraph
              size="sx"
              align="center"
              style={{
                border: "solid 2px blue",
                height: "78px",
                borderRadius: "6px",
                paddingTop: "15px",
                width: "368px",
                maxWidth: "361px",
                marginBottom: "19px",
              }}
            >
              Manual de paso a paso para la compra del
              <br /> bot y métodos de pago.
            </Paragraph>
            <br />

            <br />
            <a
              className="button button--small rules-day-container-button "
              download="Manual_de_registro_Gano_Excel.pdf"
              href="/Manual de registro Gano Excel.pdf"
              style={{
                margin: " -42px 0px 11px 20px",
                height: "21px",
                textDecoration: "none",
                display: " flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              Ver Manual
            </a>

            <Paragraph
              size="sx"
              align="center"
              style={{
                border: "solid 2px blue",
                height: "78px",
                borderRadius: "6px",

                width: "368px",
                maxWidth: "361px",
                marginBottom: "19px",
              }}
            >
              <br />
              Manual de instalación de la aplicación.
            </Paragraph>
            <br />

            <br />
            <a
              className="button button--small rules-day-container-button "
              download="Manual_de_registro_Gano_Excel.pdf"
              href="/Manual de registro Gano Excel.pdf"
              style={{
                margin: " -42px 0px 11px 20px",
                height: "21px",
                textDecoration: "none",
                display: " flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              Ver Manual
            </a>

            <Button
              styles={{
                position: "relative",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
         
           <Paragraph align={"cenetr"} size={"s"}>
           <img src={NewDirector} alt="director" width={133} height={133}/>
           <br/>
           <b>Luciana Sandoval Paz</b>
           <br/>
           <span style={{
            color:"yellow",
            paddingLeft:"32px"
           }}>Director(a)</span>
           </Paragraph>
            <div
              className="user-card"
              style={{
                backgroundColor: "#0D233B",
                borderRadius: "15px",
                height: "43px",
              }}
            >
              <div className="user-info">
                <div className="user-details">
                  <span className="user-name"></span>
                  <div className="user-status">
                    <span
                      className="status-text"
                      style={{
                        color: "#ffffff",
                        fontSize: "15px",
                      }}
                    >
                      Realicé la compra de TWOBOT.
                    </span>
                  </div>
                </div>
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  onClick={() => {}}
                />
              </div>
            </div>
            <Paragraph align={"center"} size={"xs"}>
              Este switch debes activarlo manualmente cuando
              <br />
              hayas realizado la correctamente la compra de
              <br /> TWOBOT.
            </Paragraph>
            <div
              className="user-card"
              style={{
                backgroundColor: "#0D233B",
                borderRadius: "15px",
                height: "43px",
              }}
            >
              <div className="user-info">
                <div className="user-details">
                  <span className="user-name"></span>
                  <div className="user-status">
                    <span
                      className="status-text"
                      style={{
                        color: "#ffffff",
                        fontSize: "15px",
                      }}
                    >
                      El(la) Capitán(a) verificó el
                      <br /> funcionamiento del TWOBOT.
                    </span>
                  </div>
                </div>
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  onClick={() => {}}
                />
              </div>
            </div>
            <Paragraph align={"center"} size={"xs"} style={{
              maxWidth: "364px"
            }}>
              Este switch se activa cuando tu Capitán(a) haga una
              <br />
              prueba y confirme que el TWOBOT que compraste
              <br />
              funciona correctamente.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
         

            <Paragraph size="sx" align="center">
            <img src={Treesdicons} alt=" whatsapp" width="194" height="160" />
            <br/>
            En este momento debes sincronizarte con tu<br/>
            Coordinador(a) para efectuar la compra del<br/>
             paquete de inicio.
            </Paragraph>
          
            <Paragraph
              size="sx"
              align="center"
              style={{
                border: "solid 1px blue",
                height: "78px",
                borderRadius: "6px",
                paddingTop: "15px",
              }}
            >
              Manual de paso a paso para la compra del Paquete de Inicio.
            </Paragraph>
            <br />

            <br />
            <a
              className="button button--small rules-day-container-button "
              download="Manual_de_registro_Gano_Excel.pdf"
              href="/Manual de registro Gano Excel.pdf"
              style={{
                margin: " -42px 0px 11px 20px",
                height: "21px",
                textDecoration: "none",
                display: " flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              Ver Manual
            </a>
            <Paragraph size="sx" align="center" style={{
              paddingBottom: "83px"
            }}>
            
            Por favor presiona continuar solo hasta que<br/>
            hayas confirmado que el(la) Coordinador(a)<br/>
             efectuó la compra.
            
           
            </Paragraph>
            <Button
              className="rules-day-director-button director"
              onClick={() => setStep(step + 1)}
              styles={{
                position:"relative"
              }}
            >
              Continuar
            </Button>
            
            <Paragraph size="sx" align="center" >
            
            Presiona Game Over si el(la) Coordinador(a) no<br/> efectuó la compra.
            </Paragraph>
            <Button
             
              onClick={() => {}}
              style="ghost"
              styles={{
                
              }}
            >
             Game over Coordinador
            </Button>
          </Step>
         
       
       
          <Step order={7} visibility={step === 7 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío C.</b>
            </Paragraph>

            <img src={Genial} alt="genial" widh="226" height="226" />
            <CardCopyKey
              phrase="Cierre Alineamiento Director"
              nameChallenge="Cierre Alineamiento Director"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "36px",
              }}
              onClick={() => finishChallenge("")}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CapitanAlignmentDay;
