import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { ReactComponent as PdlBadge } from '../../../resources/images/pdl-badge.svg';
import ValidatorItem from "../../molecules/validator-item";
import { API, ROLES } from "../../../middleware/utils/constants";
import axios from "axios";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarActive from "../../../resources/images/star-active.png";
import StarInactive from "../../../resources/images/star-inactive.png";
import { ReactComponent as EmptyBadge } from "../../../resources/images/empty-badge.svg";

const MyValidatorsTab = ({ idChallenge, activeChallenge, children }) => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { update } = useParams();
    const [validatorsWithMedals, setValidatorsWithMedals] = useState([]);
    const [aspirantsWithTraining, setAspirantsWithTraining] = useState([]);
    const [counterValidations, setCounterValidations] = useState(0);
    const [personalReferences, setPersonalReferences] = useState([]);
    const [auxActiveChallenge, setAuxActiveChallenge] = useState({});

    const location = useLocation();

    const handleSelectValidator = (index, idUser, token) => {
        const idUserValue = idUser || token;
        navigate(`/validator-info/${index + 1}/${idChallenge}/${idUserValue}`);
    };

    const renderStarBadge = (personalReference, index, personalReferences) => {
        let counter = 0;
        personalReferences?.filter((p) => {
            if (p.status) {
                counter++;
            }
        });
        if (index < 5)
            return counter === 6 && personalReference.status ? <img src={StarActive} alt="star active" /> : <img src={StarInactive} alt="star inactive" />;
    };


    const renderScheduled = (personalReference, index) => {
        if (index < 6)
            return personalReference.processStatus === 'scheduled' ?
                <span className="number-badge"><EmptyBadge opacity={1} /><span>{index + 1}</span></span> :
                <span className="number-badge" style={{ opacity: 0.5 }}><EmptyBadge /><span>{index + 1}</span></span>;
    };

    useEffect(() => {
        setCounterValidations(user?.personalReferences?.filter((personalReference) => personalReference.status && personalReference.role !== 'host').length);
        axios.get(`${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`).then((response) => {
            if (response.status === 200) {
                setAspirantsWithTraining(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [update, location.state]);


    useEffect(() => {
        axios.get(`${API.GET_INFORMATION.url}`).then((response) => {
            if (response?.status === 200) {
                    const personalReferences = response.data.data?.personalReferences.sort((a, b) => {
                        if (a.processStatus === 'registered' && b.processStatus !== 'registered') {
                            return -1;
                        }
                        if (a.processStatus !== 'registered' && b.processStatus === 'registered') {
                            return 1;
                        }
                        if(a.processStatus === 'scheduled' && b.processStatus !== 'scheduled') {
                            return -1;
                        }
                        if(a.processStatus !== 'scheduled' && b.processStatus === 'scheduled') {
                            return 1;
                        }
                        return 0;
                    }).map((personalReference, index) => {
                        return personalReference.training === 'candidate' ? {
                            ...personalReference,
                            newIndex: index,
                        } : personalReference;
                    });
                    setPersonalReferences(personalReferences);
                    const updateUser = { ...user, personalReferences: personalReferences };
                    setUser(updateUser);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [update, location.state]);

    return (
        <div className="my-validators-tab">
            {/* {user?.role === 'candidate' && <>
                <PdlBadge className="pdl-badge" />
                <Paragraph size="xs" align="center" >
                    Validaciones obtenidas: {counterValidations}/6
                </Paragraph>
                <Button onClick={(() => navigate(`/register-reference/new/${idChallenge}`))} disabled={!['filter day', 'alignment day'].find((day) => activeChallenge?.day === day)}>Agregar Referencia</Button>
            </>} */}
            {user?.role.indexOf('host') > -1 && !['induction day', 'rules day', 'day one', 'day two'].find((day) => activeChallenge?.day === day) && <>
                <Paragraph size="s" align="center" >
                    Aspirantes validados x 6:
                </Paragraph>
                <div className="host-validators__stars">
                    {user?.personalReferences?.map((personalReference, index) => {
                        const aspirant = aspirantsWithTraining?.find((aspirant) => aspirant.tokenValidator === personalReference.token);
                        return renderStarBadge(personalReference, index, aspirant?.personalReferences);
                    })}
                </div>
            </>}
            {user?.role === 'host' && ['induction day', 'rules day', 'day one', 'day two'].find((day) => activeChallenge?.day === day) && <>
                <Paragraph size="s" align="center" >
                    Agendados al EPA:
                </Paragraph>
                <div className="host-validators__stars">
                    {personalReferences.map((renderScheduled))}
                </div>
                <Button onClick={(() => navigate(`/register-reference/new/${idChallenge}`))}>Agregar Referencia</Button>
            </>}
            {activeChallenge?.day === 'filter day' && <Button onClick={() => navigate(`/progress`)}>Ver tabla de progreso</Button>}
            {personalReferences?.map((personalReference, index) => {

                const aspirant = aspirantsWithTraining?.find((aspirant) => aspirant.tokenValidator === personalReference.token);
                const hostValidation = personalReferences?.find((personalReference) => personalReference.role === 'host' || personalReference.role === 'training'  || personalReference.role === 'new host');
                const otherRole = personalReference?.role === 'host' && personalReference?.training === 'candidate' ? 'candidate' : personalReference?.role;

                return <ValidatorItem
                    name={`${personalReference.name} `}
                    role={personalReference.training}
                    otherRole={otherRole}
                    nameRole={ROLES[personalReference.training]?.roleName ||  'Validador(a)'}
                    range={7}
                    index={index}
                    newIndex={personalReference.newIndex}
                    epaParticipationRequirements={aspirant?.epaParticipationRequirements}
                    personalReferences={aspirant?.personalReferences}
                    keys={aspirant?.Keys}
                    challenge={activeChallenge}
                    idUser={aspirant?._id}
                    token={personalReference?.token}
                    disabled={user?.role === 'candidate' && personalReference.role !== 'host' && !hostValidation?.status && user?._id !== '66286ef522198fc73da7ee79'}
                    onSelect={handleSelectValidator}
                />
            })}
            {children}
        </div>
    );
};

export default MyValidatorsTab;