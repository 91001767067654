import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RedirectIf = ({condition}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.hostname.startsWith(condition)) {
            navigate("/landing");
        }
    }, [navigate]);

    return null;
}