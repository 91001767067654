import { useContext } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Button from "../../atoms/button";
import MenuUserInfo from "../../molecules/menu-user-info";
import { useNavigate } from 'react-router-dom';
import { ROLES } from "../../../middleware/utils/constants";

const Menu = ({ role, isShow = false, onMouseMove, onBlur, onTouchMove, onClick }) => {

    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const handleLogout = () => {
        sessionStorage.removeItem('session-id');
        document.cookie = 'session-id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/';
    };

    return (
        <div className={`menu ${isShow ? 'open' : 'close'}`} onMouseMove={onMouseMove} onBlur={onBlur} onTouchMove={onTouchMove} onClick={onClick} onKeyDown={() => { }}>
            <MenuUserInfo 
            name={user?.name} 
            role={role} 
            nameRole={ROLES[role]?.roleName==="Captain"?"Capitán":ROLES[role]?.roleName} 
            range={1} />
            <Button icon={{
                icon: 'icon-avatar',
                position: 'left'
            }}
                onClick={() => navigate('/profile')}
                style="primary"
            >Mi perfil</Button>
            <Button icon={{
                icon: 'icon-controller',
                position: 'left'
            }}
                style="primary"
                onClick={() => navigate('/missions')}
            >Misiones Diarias</Button>
            <Button icon={{
                icon: 'icon-config',
                position: 'left'
            }}
                style="primary"
            >Cuenta</Button>
            <Button icon={{
                icon: 'icon-help',
                position: 'left'
            }}
                style="primary"
            >FQA</Button>
            <Button icon={{
                icon: 'icon-book',
                position: 'left'
            }}
                style="primary"
            >Reglas</Button>
            <Button icon={{
                icon: 'icon-link',
                position: 'left'
            }}
                style="primary"
            >Links para Meets</Button>
            {role?.indexOf('host') > -1 && <Button icon={{
                icon: 'icon-calendar',
                position: 'left'
            }}
                onClick={() => navigate('/epa-link')}
                style="primary"
            >Link Convocatoria EPA</Button>}
            <Button icon={{
                position: 'left'
            }}
                onClick={handleLogout}
                style="ghost"
            >Cerrar Sesión</Button>
        </div>
    );
};

export default Menu;